import { useSelector } from 'react-redux';
import { Footer, Header } from 'components/common';
import { RootState } from 'redux/rootReducer';
import { useState } from 'react';
import { UpcomingActivityServices } from 'services/upcomingActivities';
import { useToast } from './Toast/ToastProvider';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function ContactUs() {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  const [ formData, setFormData ] = useState({})
  const toast = useToast()
  const navigate = useNavigate()

  const sendContact = async (e:any) => {
    e.preventDefault()
    const res = await UpcomingActivityServices.contautus(formData)
    toast?.success('Your message has been successfully sent.');
    setTimeout(()=> navigate('/'), 1000)
  }

  useEffect(() => {
    window.scrollTo(0,0)
}, []);

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />

      <section className="flex flex-col space-y-0 md:space-y-0 md:flex-row">
        <div className="hidden md:block w-full min:h-screen md:w-1/2 admin-bg-onboard md:-mt-16 fit">
          <div className="container w-full py-8">
            <div className="">
              <img src="/assets/img/logo.svg" className="w-16" alt="" />
            </div>
            <div className="pt-16 md:absolute">
              <img
                src="/assets/img/admin-login.png"
                className="xl:h-[80vh]  lg:ml-16 lg:h-[40vh] h-[40vh]"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="md:w-1/2 h-full fit">
          <div className="container relative">
            <div className="lg:w-11/12">
              <div className="flex items-center content-center py-6 lg:min-h-screen">
                <div className="w-full">
                  <h2 className="text-3xl font-semibold mt-4">Contact Us</h2>
                  <p className="py-2 text-base text-neutral">
                    Please fill in the form to get in touch with us.
                  </p>
                  <div className="mt-5">
                    <form className="pb-12">
                      <div className="flex w-full space-x-4">
                        <div className="mb-5 w-full">
                          <label htmlFor="name" className="block">
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder=""
                            autoComplete="given-name"
                            className="mt-1 form-control"
                            onChange={(e) => setFormData({
                              ...formData,
                              name: e.target.value
                            })}
                          />
                        </div>
                        <div className="mb-5 w-full">
                          <label htmlFor="phoneNumber" className="block">
                            Phone Number
                          </label>
                          <input
                            type="number"
                            name="phoneNumber"
                            placeholder=""
                            id="phoneNumber"
                            autoComplete="given-name"
                            className="mt-1 form-control placeholder:text-gray-300"
                            onChange={(e) => setFormData({
                              ...formData,
                              phoneNumber: e.target.value
                            })}
                          />
                        </div>
                      </div>
                      <div className="flex w-full space-x-4">
                        <div className="mb-5 w-full">
                          <label htmlFor="email" className="block">
                            Email
                          </label>
                          <input
                            type="email"
                            name="full-name"
                            placeholder=""
                            id="email"
                            autoComplete="email"
                            className="mt-1 form-control"
                            onChange={(e) => setFormData({
                              ...formData,
                              email: e.target.value
                            })}
                          />
                        </div>
                        <div className="mb-5 w-full">
                          <label htmlFor="subject" className="block">
                            Subject
                          </label>
                          <input
                            type="text"
                            name="subject"
                            placeholder=""
                            autoComplete="subject"
                            id="subject"
                            className="mt-1 form-control"
                            onChange={(e) => setFormData({
                              ...formData,
                              subject: e.target.value
                            })}
                          />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="message" className="block pb-1">
                          Message
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          className="form-control w-full"
                          placeholder="Enter Message"
                          onChange={(e) => setFormData({
                            ...formData,
                            message: e.target.value
                          })}
                        ></textarea>
                      </div>

                      <div className="flex items-center mt-10">
                        <button onClick={sendContact} className="w-full px-4 py-2 text-base text-center text-white btn bg-auraPrimary hover:bg-orange-600">
                          <span>Send Messsage</span>
                        </button>
                      </div>
                    </form>
                    <div className=''>
                      <p className="text-base text-neutral">You can Email us at</p>
                      <p>Auracs@transcorphotelsplc.com</p>
                      <br />
                      <p className="text-base text-neutral">Or call us on</p>
                      <p>+234 (1) 343 4499</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
