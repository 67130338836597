import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getSession, storage } from 'utils';
import configEnv from 'utils/config';

const service: AxiosInstance = axios.create({
    baseURL: configEnv.API_BASE_URL,
    timeout: 60000,
});

const ENTRY_ROUTE = '/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request Interceptor
service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const jwtToken = storage.getToken();
        (config as any).headers['X-Api-Key'] = `${process.env.REACT_APP_X_Api_Key}`;

        if (jwtToken && getSession(jwtToken)) {
            (config as any).headers[TOKEN_PAYLOAD_KEY] = `bearer ${jwtToken}`;
        }

        if (!getSession(jwtToken) && !(config as any).headers[PUBLIC_REQUEST_KEY]) {
            storage.clearCache();
        }

        if (!jwtToken && !(config as any).headers[PUBLIC_REQUEST_KEY]) {
            storage.clearCache();
        }

        return config;
    },
    (error: any) => {
        Promise.reject(error);
    }
);

// API respone interceptor
service.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    (error: any) => Promise.reject(error)
);

export default service;
