import { Button, Pagination, PaginationProps, Popover } from 'antd';
import { Dots, Spinner } from 'components/common/Loader';
import { useToast } from 'components/common/Toast/ToastProvider';
import { FC, ReactInstance, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelReservation,
  cancelUserBooking,
  saveSelectedBooking,
} from 'redux/reservation/actions';
// import dataService from 'services/appData';
import bookingService from 'services/bookingService';
import formatDate from 'utils/date/dateFormat';
import BookingDetailModal from './BookingDetailModal';
import { useReactToPrint } from 'react-to-print';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
import { initializePayment } from 'redux/payment/actions';
import ConfirmCancellationModal from './ConfirmCancellationModal';
import ComplaintModal from './ComplaintModal';
import CheckinModal from './CheckinModal';
import ReviewBookingModal from './ReviewBookingModal';
import ReviewSuccessModal from 'components/common/Modal/ReviewSuccessModal';
import EmergencyContactModal from './EmergencyContact';
import CancelModalSucess from './CancelModalSucess';
import { bookingRouteFrom, getBookingByBookingId } from 'redux/booking/action';
import {
  getIDOfProduct,
  getNameOfProduct,
  simpleProductTypes,
} from 'utils/products';
import moment from 'moment';

export const Bookings: FC = () => {
  const [isLoading, setisLoading] = useState(true);
  const [activeTab, setactiveTab] = useState(29);
  const [bookingsData, setbookingsData] = useState<any>({});
  const [emergencyContacts, setEmergencyContacts] = useState<any>([]);
  const toast = useToast();
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);
  const [openConfirmCancelModal, setopenConfirmCancelModal] = useState(false);
  const [openCancelModal, setopenCancelModal] = useState(false);
  const { filteredProduct } = useSelector((state: RootState) => state.filter);
  const [pageNumber, setpageNumber] = useState(1);
  const [searchKey, setsearchKey] = useState('');
  const [sortBy, setsortBy] = useState('');
  const pageSize = 10;
  const [selectedData, setSelectedData] = useState(null);

  const dispatch: any = useDispatch();
  const fetchBookings = async () => {
    setisLoading(true);
    const response = await bookingService.getBookingsByProductID(
      activeTab,
      pageNumber,
      pageSize,
      searchKey,
      sortBy
    );
    setbookingsData(response);
    setisLoading(false);
  };

  const fetchEmergencyContacts = async () => {
    const response = await bookingService.getEmergencyContacts();
    setEmergencyContacts(response.data);
  };

  const createSafetyIncidence = async (bookingID : any) => {
    const response = await bookingService.createSafetyIncidence(bookingID);
  };

  const { user } = useSelector((state: RootState) => state.user);
  // const { payment } = useSelector((state: RootState) => state.payment);
  const { selectedBooking } = useSelector(
    (state: RootState) => state.reservation
  );


  const messageHost=(data:any)=>{
    navigate(`/messages?bookingId=${data?.bookingID}&name=${data?.hostName}&startMsg=true`)
  }

  const approvedPaidActions = [
    {
      name: 'Complaints',
      action: (data:any) => {
        setSelectedData(data)
        setOpenModal1(true)
      },
    },
    {
      name: "Message Host",
      action: messageHost,
    },
    {
      name: 'Emergency contacts',
      action: (data:any) => {
        setSelectedData(data)
        setOpenModal4(true);
        createSafetyIncidence(data?.bookingID)
      },
    },
  ];

  const concludedActions = [
    // {
    //   name: 'Rebook',
    //   action: () => {},
    // },
    {
      name: 'Review and Ratings',
      action: (data:any) => {
        setSelectedData(data)
        setOpenModal3(true)
      },
    },
  ];

  const getProductName = () => {
    const product = simpleProductTypes.find((s) => s.productID === activeTab);
    return product?.productName;
  };


  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      dispatch(
        initializePayment({
          email: user?.emailAddress,
          amount: selectedBooking.payableAmount,
        })
      );
    }
    return () => {
      isCancelled = true;
    };
  }, [selectedBooking, user?.emailAddress]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      fetchBookings();
    }

    return () => {
      isCancelled = true;
    };
  }, [activeTab, pageNumber, pageSize, searchKey, sortBy]);

  useEffect(() => {
    fetchEmergencyContacts();
  }, []);


  // console.log('bookingsData', bookingsData);

  const componentRef: React.MutableRefObject<HTMLTableElement | null> =
    useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();

  const onChange: PaginationProps['onChange'] = (pageNumber) => {
    setpageNumber(pageNumber);
  };

  const handleSortChange = (e: any) => {
    setsortBy(e.target.value);
  };

  return (
    <div className="w-full px-4 md:py-0 py-8 xl:px-0">
      <div className="flex items-center md:hidden mb-6 text-xl font-medium ">
        My Bookings
      </div>
      <BookingDetailModal 
        openModal={openModal} 
        setOpenModal={setOpenModal} 
        activeTab={activeTab}
      />
      <ConfirmCancellationModal
        openModal={openConfirmCancelModal}
        setOpenModal={setopenConfirmCancelModal}
        isLoading={isLoading}
        setisLoading={setisLoading}
        fetchBookings={fetchBookings}
        setopenCancelModal={setopenCancelModal}
      />
      <CancelModalSucess
        openModal={openCancelModal}
        setOpenModal={setopenCancelModal}
      />
      <ComplaintModal
        openModal={openModal1}
        setOpenModal={setOpenModal1}
        isLoading={isLoading}
        setisLoading={setisLoading}
        selectedData={selectedData}
      />
      <CheckinModal
        openModal={openModal2}
        setOpenModal={setOpenModal2}
        isLoading={isLoading}
        setisLoading={setisLoading}
        selectedData={selectedData}
      />
      <ReviewBookingModal
        openModal={openModal3}
        setOpenModal={setOpenModal3}
        isLoading={isLoading}
        setisLoading={setisLoading}
        selectedData={selectedData}
      />
      <EmergencyContactModal
        openModal={openModal4}
        setOpenModal={setOpenModal4}
        selectedData={emergencyContacts || []}

      />

      <ReviewSuccessModal
        openModal={openModal5}
        setOpenModal={setOpenModal5}
      />

      <div className="flex w-full flex-col items-center gap-3 mb-8 justify-between overflow-x-auto 2xl:gap-4 md:flex-row fit">
              <div className="flex md:w-[60%] w-full items-center gap-2 mt-2">
                <form className='w-full'>
                  <label
                    htmlFor="default-search"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only "
                  >
                    Search
                  </label>
                  <input
                    type="search"
                    id="default-search"
                    className="form-control w-full py-1 h-8 mb-1 placeholder:text-sm border-auraPrimary bg-[#7777794D] focus:border-auraPrimary100"
                    placeholder="Search"
                    value={searchKey}
                    onChange={(e: any) => setsearchKey(e.target.value)}
                  />
                </form>
              </div>

              <div className="flex flex-wrap items-center gap-4 mt-2 2xl:gap-4 md:w-[40%] w-full justify-center">

                <form action="" className="flex items-center space-x-2">
                  <label
                    htmlFor="countries"
                    className="block text-sm font-light text-gray-900 mr-4"
                  >
                    Sort By:
                  </label>
                  <select
                    id="countries"
                    className="px-3 py-1.5 text-sm font-light bg-[#7777794D] text-gray-900 border border-gray-300 rounded"
                    onChange={handleSortChange}
                  >
                    <option selected value="mostRecent">
                      Most Recent
                    </option>
                    <option value="checkIn">Check In</option>
                    <option value="checkOut">Check Out</option>
                    <option value="oldest">Oldest</option>
                  </select>
                </form>

                <button
                  className="flex justify-center rounded-md space-x-2 p-2 xl:w-[200px] w-[100px] text-white text-center cursor-pointer bg-[#F58320]"
                  onClick={handlePrint}
                >
                  <p className='text-center'>Print</p>
                </button>
              </div>
      </div>

      <div className="flex w-full">
        <div className="mb-4 overflow-x-auto md:w-[25%] w-[28%] bg-[#F58320] md:h-[350px] h-[300px] md:mr-10 mr-2 rounded-tr-lg rounded-br-lg">
          <ul
            className="flex flex-wrap flex-col text-sm font-medium mt-4"
            role="tablist"
          >
            {simpleProductTypes.map((data: any, index: number) => (
              <li className="" role="presentation" key={index}>
                <button
                  className={`inline-block md:p-4 p-3 !text-[11px] md:!text-[14px] capitalize w-full font-normal text-white text-left hover:bg-[#F0AD80] ${
                    activeTab === data.productID ? 'border-auraPrimary bg-[#F0AD80]' : ''
                  } `}
                  onClick={() => setactiveTab(data.productID)}
                  type="button"
                >
                  {data.productName}
                </button>
              </li>
            ))}

          </ul>
        </div>
        <div id="transactionsContent" className="overflow-x-auto w-[70%]">
          {/* <!-- TABLE CONTENT --> */}
          <div
            className=""
            id="hotel"
            role="tabpanel"
            aria-labelledby="hotel-tab"
          >

            <div className="">
              <table
                className="w-full text-sm text-left text-gray-500 "
                ref={componentRef}
              >
                <thead className="text-[16px] text-gray-700 capitalize fit mb-10">
                  <tr>
                    <th scope="col">
                     <div className="px-2 w-[95%] py-4 capitalize bg-[#7777794D] text-black rounded-md font-bold">Service name</div> 
                    </th>
                    <th
                      scope="col">
                      <div className="px-2 py-4 w-[95%] capitalize whitespace-nowrap bg-[#7777794D] text-black rounded-md">Status</div>
                    </th>
                    <th scope="col">
                      <div className="px-2 py-4 w-[95%] capitalize whitespace-nowrap bg-[#7777794D] text-black rounded-md">Amount</div>
                    </th>
                    {/* <th
                      scope="col"
                      className="px-2 py-4 capitalize whitespace-nowrap"
                    >
                      Payment
                    </th> */}
                    <th scope="col" className="">
                    <div className="px-2 py-4 w-[95%] capitalize whitespace-nowrap bg-[#7777794D] text-black rounded-md">
                      {( activeTab.toString() === "31" 
                      || activeTab.toString() === "32") 
                      ? "Date"  :
                      activeTab.toString() === "33" ? "Duration-From" : "Check-in"}
                    </div>
                    </th>

                    {( activeTab.toString() === "31" 
                      || activeTab.toString() === "32") ? ''
                      :  
                      <th scope="col" className="">
                      <div className="px-2 py-4 w-[95%] capitalize whitespace-nowrap bg-[#7777794D] text-black rounded-md">
                        {activeTab.toString() === "33" ? "Duration-To" : "Check-out"}
                        </div>
                      </th>
                    }
                    
                    <th scope="col" className="px-2 py-4 capitalize"></th>
                  </tr>
                </thead>

                {isLoading ? (
                  <div className="bg-auraPrimary rounded-full">
                    <Dots />
                  </div>
                ) : (
                  <tbody>
                    {(bookingsData.data || []).length > 0 ? (
                      bookingsData.data.map((data: any, index: number) => (
                        <tr
                          className="bg-white border-b hover:bg-gray-50"
                          key={index}
                        >
                          <td
                            scope="row"
                            className="tableColfit whitespace-nowrap"
                          >
                           <div className='p-2 w-[95%] md:px-4 2xl:pl-6 md:py-3 bg-[#7777794D] text-black rounded-md'>{data.productName}</div> 
                          </td>
                          <td className=" tableColfit">
                            <div
                              className={`p-2 md:px-2 w-[95%] md:py-3 bg-[#7777794D] rounded-md ${
                                data.bookingStatus
                                  .toLowerCase()
                                  .includes('pending') ||
                                data.bookingStatus
                                  .toLowerCase()
                                  .includes('reject') ||
                                data.bookingStatus
                                  .toLowerCase()
                                  .includes('cancel')
                                  ? 'text-auraPrimary bg-[#7777794D] '
                                  : 'text-green-400 bg-[#7777794D]'
                              }  rounded-lg whitespace-nowrap`}
                            >
                              {
                                evaluateStatus(data, false, activeTab.toString())
                                // data.hostResponse
                              }
                            </div>
                          </td>
                          <td>
                           <div className="p-2 w-[95%] md:px-2 2xl:px-4 md:py-3 tableColfit  whitespace-nowrap bg-[#7777794D] text-black rounded-md">
                            ₦{' '}
                            {data.totalPayable
                              ? data.totalPayable?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                              </div>
                          </td>
                          {/* <td
                            className={`p-2 md:px-2 2xl:px-4 md:py-4 whitespace-nowrap ${
                              data.paymentStatus
                                ? 'text-green-500'
                                : 'text-red-500'
                            }`}
                          >
                            {data.paymentStatus
                              ? data.paymentStatus
                              : 'Not Paid'}
                          </td> */}
                          <td>
                            <div className="p-2 w-[95%] md:px-2 2xl:px-4 md:py-3 tableColfit bg-[#7777794D] text-black rounded-md">
                            {data?.checkInDate
                              ? formatDate(new Date(data?.checkInDate))
                              : 'N/A'}
                              </div>
                          </td>
                          {( activeTab.toString() === "31" 
                            || activeTab.toString() === "32") ? ''
                            :  
                            <td className="">
                             <div className="p-2 w-[95%] md:px-2 2xl:px-4 md:py-3 tableColfit bg-[#7777794D] text-black rounded-md">
                              {data?.checkOutDate
                                ? formatDate(new Date(data?.checkOutDate))
                                : 'N/A'}
                                </div>
                            </td>
                          }
                          
                          <td className="p-2 md:px-2 2xl:px-4 md:py-4 text-black rounded-md">
                            <div
                              style={{
                                // marginLeft: 70,
                                clear: 'both',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              <Popover
                                placement="bottomLeft"
                                content={
                                  <div className="flex flex-col space-y-2 items-start justify-start">
                                    <button
                                      className="hover:text-auraPrimary"
                                      onClick={() => {
                                        setOpenModal(true);
                                        dispatch(saveSelectedBooking(data));
                                      }}
                                    >
                                      View Details
                                    </button>

                                    {(evaluateStatus(data, true, '')
                                      .toLowerCase()
                                      .includes('ongoing') ||
                                      evaluateStatus(data, true, '')
                                        .toLowerCase()
                                        .includes('approved') ||
                                      evaluateStatus(data, true, '')
                                        .toLowerCase()
                                        .includes('awaiting payment')) &&
                                      data?.paymentMethodMode != 'PAYSMALLSMALL' &&
                                    (data.paymentStatus === null ||
                                      data.paymentStatus.toLowerCase() ===
                                        'not paid') ? (
                                      <>
                                        <button
                                          className="proceed-to-pay hover:text-auraPrimary"
                                          onClick={() => {
                                            navigate(
                                              `/confirm-pay/${data.bookingID}`
                                            );
                                          }}
                                        >
                                          Make Payment
                                        </button>
                                        <button
                                          className="Cancelled_Reservation hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            setopenConfirmCancelModal(true);
                                          }}
                                        >
                                          Cancel Reservation
                                        </button>
                                      </>
                                    ) : data.bookingStatus
                                        .toLowerCase()
                                        .includes('cancelled') ||
                                      data.bookingStatus
                                        .toLowerCase()
                                        .includes('rejected') ? (
                                      ''
                                    ) : (
                                      ''
                                    )}

                                    {(
                                      evaluateStatus(data, true)
                                        .toLowerCase()
                                        .includes('concluded') ||
                                      evaluateStatus(data, true)
                                        .toLowerCase()
                                        .includes('cancelled by user')) && (
                                        <button
                                        className="hover:text-auraPrimary whitespace-nowrap"
                                        onClick={() => {
                                          navigate(
                                            `/${getProductName()}/${
                                              selectedBooking?.productDetailsID
                                            }`
                                          );
                                        }}
                                      >
                                        Rebook
                                      </button>
                                    )}

                                    {(evaluateStatus(data, true)
                                      .toLowerCase() === "paid") && <button
                                          className="hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            messageHost(data)
                                          }}
                                        >
                                          Message Host
                                        </button>}

                                    {(data.bookingStatus
                                      .toLowerCase()
                                      .includes('created') ||
                                      evaluateStatus(data, true)
                                      .toLowerCase() === "paid" ||
                                      evaluateStatus(data, true)
                                      .toLowerCase() === "pending host approval") && (
                                        <>

                                          <button
                                            className="hover:text-auraPrimary whitespace-nowrap"
                                            onClick={() => {
                                              setopenConfirmCancelModal(true);
                                            }}
                                          >
                                            Cancel Reservation
                                          </button>
                                          {/* <button
                                          className="hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            messageHost(data)
                                          }}
                                        >
                                          Message Host
                                        </button> */}
                                      </>
                                    )}

                                    { (!data.productName.includes('restaurant') && !data.productName.includes('photo')) &&
                                    (evaluateStatus(data, true)
                                      .toLowerCase() === "paid") && (
                                        <button
                                          className="hover:text-auraPrimary whitespace-nowrap"
                                          onClick={() => {
                                            setSelectedData(data)
                                            setOpenModal2(true)
                                          }}
                                        >
                                          CheckIn
                                        </button>
                                    )}

                                    {/* for bookings approved and paid for */}
                                    {(
                                      (evaluateStatus(data, true).toLowerCase().includes('ongoing') || 
                                      evaluateStatus(data, true).toLowerCase().includes('approved'))
                                       &&
                                      data.paymentStatus
                                        .toLowerCase() === 'paid') && (
                                      <>
                                        {approvedPaidActions.map(actions => {
                                          return <button
                                            className="hover:text-auraPrimary whitespace-nowrap"
                                            onClick={()=> actions.action(data)}
                                          >
                                            {actions.name}
                                          </button>
                                      })}
                                      </>)
                                    }

                                    {/* for bookings concluded */}
                                    {evaluateStatus(data, true)
                                      .toLowerCase()
                                      .includes('concluded') && (
                                      <>
                                        {concludedActions.map((actions) => {
                                          return (
                                            <button
                                              className="hover:text-auraPrimary whitespace-nowrap"
                                              onClick={() => actions.action(data)}
                                            >
                                              {actions.name}
                                            </button>
                                          );
                                        })}
                                      </>
                                    )}
                                  </div>
                                }
                                trigger="click"
                              >
                                <Button
                                  className="text-[#404040] !text-[20px] hover:text-auraPrimary"
                                  style={{ border: 0 }}
                                  onClick={() => {
                                    dispatch(saveSelectedBooking(data));
                                    dispatch(
                                      getBookingByBookingId(data.bookingID)
                                    );
                                    dispatch(bookingRouteFrom('profilePage'));
                                  }}
                                >
                                  ...
                                </Button>
                              </Popover>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                      <td colSpan={8} className="text-center">
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              marginTop: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            You have no record yet!
                          </p>
                        </p>
                      </td>
                    </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-end pt-6 pb-12">
        <Pagination
          defaultCurrent={pageNumber || 1}
          total={bookingsData.totalRecords}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const evalDate = (date: any) =>{
  const givenDate = new Date(date);
  const today = new Date();

  // Set the time to 00:00:00 to compare only dates
  today.setHours(0, 0, 0, 0);
  givenDate.setHours(0, 0, 0, 0);

  // Get the time difference in milliseconds
  const timeDiff = givenDate.getTime() - today.getTime();
  return timeDiff === 86400000;

  // if (today > givenDate) {
  //   return true; 
  // } else {
  //   return false; 
  // }
}

export const evaluateStatus = (data: any, noReact = false, activeTab = '') => {
  var extra = moment().format('YYYY-MM-DD') + ' ';
  var start_time = moment(extra + '14:00');
  var end_time = moment(extra + '24:00');

  const status =
    //check if chout date/time is past 2pm
    (
      ( activeTab.toString() === "31" 
        || activeTab.toString() === "32"
      ) 
        && data?.paymentStatus?.toLowerCase() === 'paid'
        // &&  evalDate(data?.checkInDate)
        && moment().isAfter(moment(data?.checkInDate), 'days')
        
      )
    ? 'Concluded'
    : (
      ( activeTab.toString() === "31" 
        || activeTab.toString() === "32"
      ) 
        && data?.paymentStatus?.toLowerCase() === 'paid'
      )
    
    ? 'Ongoing'
    : data?.bookingStatus == 'Concluded'
    ? data?.bookingStatus 
    : data?.paymentMethodMode == 'PAYSMALLSMALL'
    ? data?.paymentStatus
    : data?.bookingStatus == 'Approved' &&
    data?.paymentStatus?.toLowerCase() === 'paid' &&
    data?.checkOutDate && 
    (moment().isAfter(moment(data?.checkOutDate).format('YYYY-MM-DD')) ||
      (moment().isSame(moment(data?.checkOutDate).format('YYYY-MM-DD')) &&
        moment().isBetween(start_time, end_time)))
      ? 'Concluded'
      : //check if checkin date/time has past 2pm
      data?.bookingStatus == 'Approved' &&
        data?.paymentStatus?.toLowerCase() == 'paid' &&
        data?.checkInDate &&
        (moment().isAfter(moment(data?.checkInDate).format('YYYY-MM-DD')) ||
          (moment().isSame(moment(data?.checkInDate).format('YYYY-MM-DD')) &&
            moment().isBetween(start_time, end_time)))
      ? 'Ongoing'
      : //check if checkin data have not reached but paid
      data?.bookingStatus == 'Approved' &&
        data?.paymentStatus?.toLowerCase() == 'paid' &&
        data?.checkInDate &&
        moment().isBefore(moment(data?.checkInDate).format('YYYY-MM-DD'))
      ? 'Paid'
      : data?.hostResponse == 'Rejected by Host'
      ? 'Declined by Host'
      : data?.bookingType == 'Experience'
      ? data?.bookingStatus
      : data?.hostResponse ;

  return noReact ? status : <>{status}</>;
};