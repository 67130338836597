import { useToast } from 'components/common/Toast/ToastProvider';
import React, { useEffect, useState } from 'react';
import bookingService from 'services/bookingService';
import { paysmallsmall } from 'redux/reservation/actions';
import { useDispatch } from 'react-redux';

export default function PaySmallSmall({ bookingId, isExperience, paymentPlan, discountCode, isLoyaltyCodeApplied, isDiscountCodeApplied }: any ) {

    const [ installmental, setinstallmental ] = useState(
        paymentPlan?.filter((p:any)=> p?.planType == 'Weekly')[0]?.numberOfInstallment
        )
    const [planType, setplanType ] = useState('Weekly')

    const [ cost, setCost ] = useState<any>(null)

    const toast = useToast();
    const dispatch: any = useDispatch();

    const fetchInstallment = async () => {
        try{
            const res = await bookingService.paysmallsmall({
                installmental,
                planType,
                bookingId,
                isExperience,
                discountCode,
                isLoyaltyCodeApplied
            })

            setCost(res.data)
            dispatch(paysmallsmall(res.data));
        }
        catch(err: any){
            toast?.error('Error occurred while fetching cost breakdown. Please try again later.')
        }
    }

    const formatAmount = (data: any) => {
        if (data)
            return Number(data).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        return 0;
    };

    const formatInstallment = (name : string) =>{
        if(name == '1') return '1st'
        else if ( name == '2') return '2nd'
        else if (name == '3') return '3rd'
        else  return name + 'th'
        // else return '1st'
    }

    useEffect(()=>{
        if(planType == 'Weekly'){
            return setinstallmental(
                paymentPlan?.filter((p:any)=> p?.planType == 'Weekly')[0]?.numberOfInstallment
            )
        }
        else{
            return setinstallmental(
                paymentPlan?.filter((p:any)=> p?.planType == 'Monthly')[0]?.numberOfInstallment
            )
        }   
    }, [planType])

    useEffect(()=>{
        fetchInstallment()
    }, [ installmental, isLoyaltyCodeApplied, isDiscountCodeApplied])

    return (
        <div className="pay-small-small">
            <div className=" border-b pb-6">
                <h3 className=" text-2xl font-medium mb-8">Aura Flex</h3>
                <div className="h-[556px] bg-white checkout-box space-y-6 p-6 border rounded-3xl">
                    <div className="space-y-4">
                        <p className=" text-sm capitalize font-medium ">Payment Break Down</p>
                        <div className=" space-y-6">
                            <div className="flex items-center justify-between w-full text-sm text-neutral font-light  ">
                                <p className="">Payment Duration</p>
                                <select
                                    id="email"
                                    className="shadow-sm  border border-gray-300 text-gray-900 text-sm rounded-lg  block  px-2.5 py-1 placeholder:text-sm placeholder:font-medium"
                                    required
                                    onChange={e=>setplanType(e.target.value)}
                                >
                                    <option value={'Weekly'}>Weekly</option>
                                    <option value={'Monthly'}>Monthly</option>
                                </select>
                            </div>
                            <div className="flex items-center justify-between w-full text-sm text-neutral font-light  ">
                                <p className="">Installment</p>
                                <select
                                    id="email"
                                    className="shadow-sm  border border-gray-300 text-gray-900 text-sm rounded-lg  block  px-2.5 py-1 placeholder:text-sm placeholder:font-medium"
                                    required
                                    onChange={e=>setinstallmental(Number(e.target.value))}
                                >
                                    {
                                        planType == 'Weekly' ? 
                                        paymentPlan?.filter((p:any)=> p?.planType == 'Weekly')
                                        .sort((a:any,b:any)  => a?.numberOfInstallment < b?.numberOfInstallment )
                                        .map((k: any)  => <option value={k?.numberOfInstallment}>{k?.numberOfInstallment}</option> ) 
                                        : 
                                        paymentPlan?.filter((p:any)=> p?.planType == 'Monthly')
                                        .sort((a:any,b:any)  => a?.numberOfInstallment < b?.numberOfInstallment )
                                        .map((k: any)  => <option value={k?.numberOfInstallment}>{k?.numberOfInstallment}</option> ) 
                                    }
                                </select>
                            </div>
                            <div className="flex items-center justify-between w-full text-sm text-neutral font-light  ">
                                <p className="">Amount</p>
                                <p className="text-black">₦{formatAmount(cost?.bookingCostBreakDown?.totalPayable || 0)}</p>
                            </div>

                            {
                            cost?.installmentModels?.map((p: any)=> (
                                <div className="flex items-center justify-between w-full text-sm text-neutral font-light  ">
                                    <p className="">{formatInstallment(p?.name)}</p>
                                    <p className="text-black">₦{formatAmount(p?.amount || 0)}</p>
                                </div>
                            ))
                            }

                            <div className="flex items-center justify-between w-full text-sm text-neutral font-light  ">
                                <p className="">Interest</p>
                                <p className="text-black">{cost?.percentageInterest || 0}%</p>
                            </div>
                        </div>

                        <div className="bg-gray-100 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                            <p className="uppercase">Total</p>
                            <p className="text-end">₦{formatAmount(cost?.totalAmount || 0)}</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-3 pt-3">
                        <img src="../../img/icon/free.svg" alt="" />
                        {/* <img src="assets/img/icon/free.svg" alt="" /> */}
                        <p className="text-sm text-neutral108  font-medium ">Minimum payable amount is ₦50,000</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
