import { FC, useEffect, useState } from 'react';
import { Header, Footer, Modal, PlacesCard } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
import productService from 'services/productService';
import { Spinner } from 'components/common/Loader';
import homeIcon from 'assets/icon/home.svg';
import reserveModalIcon from 'assets/icon/reserve-modal.svg';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import bookingService from 'services/bookingService';
import formatDate, {
  dashDateFormat,
  today,
  tommorrow,
} from 'utils/date/dateFormat';
import RightReservationSection from 'pages/ReusableProduct/RightReservationSection';
import Coupon from './Coupon';
import BankTransfer from './BankTransfer';
import CreditDebitCard from './CreditDebitCard';
import PaySmallSmall from './PaySmallSmall';
import KFC from 'assets/img/KFC.png';
import RoadChef from 'assets/img/roadchef.png';
import BeachBed from 'assets/img/beachbed.png';
import RestaurantUnderground from 'assets/img/restaurantunderground.png';
import { confirmPayment, verifyPayment } from 'redux/payment/actions';
import rate from 'assets/icon/rate.svg';
import moment from 'moment';
import calendarIcon from 'assets/icon/calendar.svg';
import person_drawIcon from 'assets/icon/calendar.svg';
import useeer from 'assets/icon/useer.svg';
import { UpcomingActivityServices } from 'services/upcomingActivities';
import dataService from 'services/appData';
import ProductItem from '../Homepage/product-item';
import emptyImage from 'assets/img/emptyImage.png';
import { AuxService } from 'services/auxService';
import { IOrderDetails } from 'services/auxService/interface';
import GuestBookingDetailModal from 'pages/UserProfile/GuestBookingDetailModal';
import { saveSelectedBooking } from 'redux/reservation/actions';

const CheckoutComplete: FC = () => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.user
  );
  const { selectedProduct, selectedProductDetail, totalPrice } = useSelector(
    (state: RootState) => state.product
  );
  const { payment, paymentVerification } = useSelector(
    (state: RootState) => state.payment
  );
  const { createdQuote, selectedBooking } = useSelector(
    (state: RootState) => state.reservation
  );

  const [productDetails, setproductDetails] = useState<Record<string, any>>({});
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { detailsID } = useParams<{ detailsID: string }>();
  const { detailID } = useParams<{ detailID: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId: any = queryParams.get('orderId');
  const { filter } = useSelector((state: RootState) => state.filter);
  const [quotes, setQuotes] = useState();
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [orderDetails, setorderDetails] = useState<IOrderDetails>();
  const [openModal, setOpenModal] = useState(false);

  const [activityDetails, setactivity] = useState({
    title: '',
    pictures: [],
    activityCalendar: {
      date: null,
      time: null,
      title: ''
    }
  })

  async function getCompleteProductDetails(id: any) {
    // setisLoading(true)
    try {
      const res = await productService.getProductDetails(id);
      // console.log(res);

      setproductDetails(res.data['productDetails'])
      // setisLoading(false)
    } catch (error: any) {
      setisLoading(false)
      console.log(error);
    }
  }

  async function getOrderByID() {
    setisLoading(true)
    try {
      const res = await AuxService.GetOrderById(orderId);
      setorderDetails(res.data)
      await getCompleteProductDetails(detailsID)
      setisLoading(false)
    } catch (error: any) {
      setisLoading(false)
      console.log(error);
    }
  }

  const getBookingByBookingId = async (id: number | string) => {
    setisLoading(true);
    try {
      const res = await bookingService.getBookingByBookingID(id);
      setproductDetails(res.data);
      setPaymentDetails(
        {
          ...paymentDetails,
          bookingID: res.data.bookingID
        }
      )
      createBookingQuote(res.data.bookingDetails)
      // console.log("bookingidddd", res.data)
      setisLoading(false);
    } catch (err: any) {
      setisLoading(false);
      console.log("err", err.response.data.responseMessage)
    }
  };

  const getActivityById = async (id: number | string) => {
    setisLoading(true);
    try {
      const res = await UpcomingActivityServices.getActivityCalendar(id);
      setactivity(res.data);
      console.log('res.data', res.data)
      setisLoading(false);
    } catch (err: any) {
      setisLoading(false);
      console.log("err", err.response.data.responseMessage)
    }
  };

  const createBookingQuote = async (data: any) => {
    try {
      const res = await bookingService.createQuote({ quoteRequests: data });
      setQuotes(res.data);
      setPaymentDetails(
        {
          ...paymentDetails,
          totalPayable: formatNumber(res.data.totalPayable)
        }
      )
      setisLoading(false);
      // console.log("quotessss", res.data)
    } catch (err: any) {
      console.log("err", err.response.data.responseMessage)
    }
  };

  const formatInstallment = (name : string) =>{
    if(name == '1') return '1st'
    else if ( name == '2') return '2nd'
    else if (name == '3') return '3rd'
    else  return  name + 'th'
    // else return '1st'
}

  useEffect(() => {
    window.scrollTo(0, 0)
    let isCancelled = false;
    if (!isCancelled) {
      setisLoading(true);
      if (!path.includes('activity')) {
        dispatch(verifyPayment(payment?.data?.reference));
        dispatch(
          confirmPayment({
            transactionID: payment?.data?.reference,
            bookingID: selectedBooking?.bookingID,
          })
        );
      }
      setisLoading(false);
    }
    return () => {
      isCancelled = true;
    };
  }, [payment?.data?.reference]);

  const formatNumber = (param: any) => {
    const value = Number(param).toFixed(2);
    return Number(value)
  }

  useEffect(() => {
    if (!detailsID) return;
    if (orderId) {
      getOrderByID()
    } else {
      getBookingByBookingId(detailsID)
    }
  }, [detailsID])

  useEffect(() => {
    if (!detailID) return;
    getActivityById(detailID)
  }, [detailID])


  const path = window.location.pathname.toLowerCase();

  const [searchParams] = useSearchParams();
  // const detailID = searchParams.get('detailID');
  const paramGuest = searchParams.get('guest');
  const paramQty = searchParams.get('qty');
  const mode = searchParams.get('mode')

  const [activeRecommended, setactiveRecommended] = useState<any[]>([]);
  const [recommendedToShow, setrecommendedToShow] = useState(4);
  const recommendedSlice = activeRecommended.slice(0, recommendedToShow);

  const loadRecom = async () => {
    const recom_response = await dataService.getFeaturedProducts({
      productId: productDetails?.productID || 29
    });
    setactiveRecommended(recom_response.data);
  }

  const isAuxservice = [31, 32].includes(productDetails.productID);

  useEffect(() => {
    loadRecom()
  }, [productDetails?.productID])

  const str = productDetails?.productName
    const productDetailsName = str?.toLocaleLowerCase()?.replace(/ /g, '-')

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      {isLoading ? (
        <Spinner />
      ) : (
        <section className="bg-gray-50 py-1">
          <div className="container mb-36">
            <div className="flex flex-wrap gap-8 items-center justify-between my-10">
              <div>
              </div>

              <div className="flex items-center flex-wrap  gap-3">
                <a
                  href={`/hotels/${productDetailsName}/${productDetails.productDetailsID}`}
                  className="text-sm text-neutral108 font-semibold capitalize"
                >
                  {productDetails?.productName || activityDetails?.activityCalendar?.title}
                </a>
                <span className="text-sm font-bold text-neutral108">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <a
                  href="#"
                  className="text-sm text-neutral font-semibold capitalize"
                >
                  Checkout completed
                </a>
              </div>
            </div>

            <div className="grid lg:grid-cols-2 md:gap-x-16 gap-y-8">
              <div className="w-full">
                <img
                  src={
                    productDetails?.productBanner || productDetails?.productPicture || productDetails?.servicePicture
                    || activityDetails?.pictures?.[0]
                  }
                  className="w-full rounded-2xl h-[50%] object-cover"
                  alt=""
                />
              </div>
              <div className="">
                <div className="space-y-5">
                  <div className=" space-y-3">
                    <h1 className=" md:text-5xl text-3xl font-medium ">
                      Congratulations!
                    </h1>
                    <p className=" text-neutral108 font-medium ">
                      {orderId ? " Your order has been confirmed! 🎉" : " Your reservation has been confirmed! 🎉"}
                    </p>
                  </div>

                  {path.includes('activity') ? (
                    <div className="bg-gray-100 rounded-2xl p-4 space-y-8">
                      <div className="flex items-center justify-between">
                        {paramQty &&
                          <div className="flex items-center space-x-3 ">
                            <div>
                              <img src={useeer} alt="" />
                            </div>
                            <div>
                              <p className="text-xs font-light text-neutral ">Quantity</p>
                              <p className="text-black text-base font-normal text-center pt-2">{
                                paramQty
                                  ?.replaceAll('?installmentNo=1', '')
                                  ?.replaceAll('?code=00', '')
                              }</p>
                            </div>
                          </div>
                        }
                        <div className="flex items-center space-x-3 ">
                          <div>
                            <img src={calendarIcon} alt="" />
                          </div>
                          <div>
                            <p className="text-xs font-light text-neutral ">Date</p>
                            <p className="text-black text-base font-normal text-center pt-2">
                              {activityDetails?.activityCalendar?.date
                                ? moment(activityDetails?.activityCalendar?.date).format('DD-MM-YYYY')
                                : ''}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3 ">
                          <div>
                            <img src={calendarIcon} alt="" />
                          </div>
                          <div>
                            <p className="text-xs font-light text-neutral ">Time</p>
                            <p className="text-black text-base font-normal text-center pt-2">
                              {activityDetails?.activityCalendar?.time ? activityDetails?.activityCalendar?.time : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                    : <>
                      <div className="pb-8 border-b space-y-3">
                        <h6 className=" text-base font-medium">
                          {productDetails?.productName}
                        </h6>
                        <p className=" text-neutral108 font-light text-base">
                          Hosted by{' '}
                          <span className="text-black font-normal">
                            {productDetails?.parentCompany}
                          </span>
                        </p>
                      </div>
                      <div className="flex items-center">

                        <div className="flex items-center gap-3">
                          <div className="ml-1 font-semibold tx-sm flex items-center space-x-3">
                            {selectedProductDetail?.productDetails?.rating > 0 ? (
                              <span className='flex items-center space-x-2'>
                                {`${selectedProductDetail?.productDetails?.rating} `}
                                <img src={rate} alt="" />
                                <span>ratings</span>
                              </span>

                            ) : (
                              'No rating'
                            )}
                            <span className="ml-2 font-light text-neutral">
                              (
                              {selectedProductDetail?.productDetails?.noOfReviews >
                                0
                                ? `${selectedProductDetail?.productDetails?.noOfReviews} reviews`
                                : 'No review'}
                              )
                            </span>
                          </div>
                          <p className="text-xs text-neutral108">
                            {productDetails?.productName}
                          </p>
                        </div>
                      </div>
                      <div className="bg-neutral107 rounded-2xl p-4 space-y-8 lg:w-[90%]">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3 ">
                            <div>
                              <img src="../../img/icon/useer.svg" alt="" />
                            </div>
                            <div>
                              <p className="text-xs font-light text-neutral ">
                                Guest
                              </p>
                              <p className="text-black text-base font-normal">
                                {
                                  orderId ? orderDetails?.firstName + " " + orderDetails?.lastName : `${filter?.guest} guests`
                                }
                              </p>
                            </div>
                          </div>

                          {/* <div className="flex items-center  space-x-3 ">
                        <div>
                          <img src="../../img/Combined-Shape.svg" alt="" />
                        </div>
                        <div>
                          <p className="text-xs font-light text-neutral ">
                            Call Host
                          </p>
                          <p className="text-black text-base font-normal">
                            {productDetails?.phoneNumber}{' '}
                          </p>
                        </div>
                      </div> */}
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3 ">
                            <div>
                              <img src="../../img/icon/calendar.svg" alt="" />
                            </div>
                            <div>
                              <p className="text-xs font-light text-neutral ">
                                {isAuxservice ? "Date" : "Check-in"}
                              </p>
                              <p className="text-black text-base font-normal">

                                {orderId ? moment(orderDetails?.createdAt).format('DD-MM-YYYY') : moment(productDetails?.checkInDate).format('DD-MM-YYYY')}
                              </p>
                            </div>
                          </div>

                          {(!orderId && !isAuxservice ) ? <div className="flex items-center space-x-3 ">
                            <div>
                              <img src="../../img/icon/calendar.svg" alt="" />
                            </div>
                            <div>
                              <p className="text-xs font-light text-neutral ">
                                Check-out
                              </p>
                              <p className="text-black text-base font-normal">
                                {moment(productDetails?.checkOutDate).format('DD-MM-YYYY')}
                              </p>
                            </div>
                          </div> : ''}
                        </div>
                      </div>
                    </>
                  }
                  {
                    path.includes('activity') ? ''
                      : orderId ? <div className=" space-y-6 lg:w-[90%]">
                        <h3 className="text-2xl font-medium">
                          Reservation details
                        </h3>
                        <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                          <div className="flex items-center gap-3">
                            <img src="../../img/hand cart.svg" alt="" />
                            <p className="">Order code:</p>
                          </div>
                          <p className="text-black text-left">
                            {orderDetails?.orderId}
                          </p>
                        </div>

                        <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                          <div className="flex items-center gap-3">
                            <img src="../../img/reciept.svg" alt="" />
                            <p className="">Date:</p>
                          </div>
                          <p className="text-black text-left">
                          {moment(orderDetails?.createdAt).format()}
                          </p>
                        </div>

                        <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                          <div className="flex items-center gap-3">
                            <img src="../../img/reciept.svg" alt="" />
                            <p className="">Total:</p>
                          </div>
                          <p className="text-black text-left">
                            ₦ {orderDetails?.total}
                          </p>
                        </div>
                        <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                          <div className="flex items-center gap-3">
                            <img src="../../img/wallet.svg" alt="" />
                            <p className="">Payment status:</p>
                          </div>
                          <p
                            className={`text-left capitalize rounded-lg ${productDetails?.paymentStatus?.toLowerCase() === "paid" || orderDetails?.status === "paid"
                              ? 'text-green-500 p-2 bg-green-100'
                              : 'text-red-500 p-2 bg-red-50'
                              }`}
                          >
                            {/* {paymentVerification?.data?.status} */}
                            {
                              orderId ? orderDetails?.status : productDetails?.paymentMethodMode == 'PAYSMALLSMALL'
                                ? 'Pay small small'
                                : productDetails?.paymentStatus
                            }
                          </p>
                        </div>
                        <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                          <div className="flex items-center gap-3">
                            <img src="../../img/reciept.svg" alt="" />
                            <p className="">Type of Delivery:</p>
                          </div>
                          <p className="text-black text-left">
                             {orderDetails?.deliveryType}
                          </p>
                        </div>
                      </div> : (
                        <div className=" space-y-6 lg:w-[90%]">
                          <h3 className="text-2xl font-medium">
                            Reservation details
                          </h3>
                          <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                            <div className="flex items-center gap-3">
                              <img src="../../img/hand cart.svg" alt="" />
                              <p className="">Booking code:</p>
                            </div>
                            <p className="text-black text-left">
                              {productDetails?.bookingID}
                            </p>
                          </div>
                          {
                            productDetails?.paymentMethodMode == 'PAYSMALLSMALL'
                              ? ''
                              : (
                                <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                                  <div className="flex items-center gap-3">
                                    <img src="../../img/calenderly.svg" alt="" />
                                    <p className="">Date:</p>
                                  </div>
                                  <p className="text-black text-left">
                                    {moment(productDetails?.transactionDate).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                              )
                          }

                          <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                            <div className="flex items-center gap-3">
                              <img src="../../img/reciept.svg" alt="" />
                              <p className="">Total:</p>
                            </div>
                            <p className="text-black text-left">
                              {' '}
                              ₦
                              {(
                                mode ||
                                productDetails?.costBreakDowns?.filter((p: any) => p.name == 'Total Amount Payable')[0]?.value ||
                                productDetails?.bookingCostBreakDown?.totalPayable || ' '
                              )
                                ?.toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                            </p>
                          </div>
                          <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                            <div className="flex items-center gap-3">
                              <img src="../../img/wallet.svg" alt="" />
                              <p className="">Payment status:</p>
                            </div>
                            <p
                              className={`text-left capitalize rounded-lg ${productDetails?.paymentStatus?.toLowerCase() === "paid"
                                ? 'text-green-500 p-2 bg-green-100'
                                : 'text-red-500 p-2 bg-red-50'
                                }`}
                            >
                              {/* {paymentVerification?.data?.status} */}
                              {
                                productDetails?.paymentMethodMode == 'PAYSMALLSMALL'
                                  ? 'Pay small small'
                                  : productDetails?.paymentStatus
                              }
                            </p>
                          </div>
                          {productDetails?.paymentMethodMode == 'PAYSMALLSMALL' && 
                            <div className="">
                              <p className="mb-1 text-lg text-auraPrimary font-semibold text-neutral06">Payment Installments</p>     
                                        {
                                            productDetails?.bookingPaymentSmallSmalls?.map((obj: any)=>
                                                <>
                                                    <div className="flex flex-row items-center justify-between my-3">
                                                        <div>
                                                            <p>{formatInstallment(obj?.installment)} Installment:</p>
                                                        </div>
                                                        
                                                        <div>
                                                            <p className="font-medium text-neutral06">
                                                                ₦
                                                                {obj?.amountDue
                                                                    ? obj?.amountDue.toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })
                                                                    : 0 }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p
                                                                className={`font-medium ${
                                                                    obj?.paymentStatus?.toLowerCase() === 'pending'
                                                                        ? 'text-red-500'
                                                                        : 'text-green-500'
                                                                }`}
                                                            >
                                                                {obj?.paymentStatus}
                                                            </p>
                                                            {/* <br /> */}
                                                            { obj?.paymentStatus?.toLowerCase() !== 'paid' 
                                                                && <span style={{ fontSize: 9, fontWeight:'bold', }} >{moment(obj?.expectedPaymentDate).format('DD/MM/YYYY')}</span> }
                                                        </div>
                                                        {
                                                            obj?.paymentStatus?.toLowerCase() !== 'paid'
                                                            ? (
                                                                <Link
                                                                    to={{
                                                                        pathname: `/confirm-pay/${obj?.bookingId}`,
                                                                    }}
                                                                    className="hover:text-auraPrimary whitespace-nowrap text-auraPrimary"
                                                                    state={{
                                                                        paymentInfo: obj
                                                                    }}
                                                                > Pay now
                                                                </Link>
                                                            )
                                                            : <div style={{ fontSize: 12 }} className="font-medium text-neutral06">{moment(obj?.datetime).format('DD/MM/YYYY')}</div>
                                                        }
                                                        
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>}
                          {/* <div className="flex items-center justify-between w-full text-sm text-neutral font-medium  ">
                      <div className="flex items-center gap-3">
                        <img src="../../img/wallet.svg" alt="" />
                        <p className="">Payment Type:</p>
                      </div>
                      <p className="text-black text-left capitalize">
                        {' '}
                        {paymentVerification?.data?.channel}
                      </p>
                    </div> */}
                        </div>
                      )
                  }
                  <div className="flex items-center gap-6">
                    {
                      (path.includes('activity') ? ''
                         :
                         orderId ?<Link
                         to="/user-profile/my-orders"
                         className="py-2 px-3 border rounded-md text-black capitalize text-base font-medium"
                       >
                         view order
                       </Link> :  
                       !user.userID ? 
                        <span
                        onClick={() => {
                          setOpenModal(true);
                          dispatch(saveSelectedBooking(productDetails));
                        }}
                          className="py-2 px-3 border cursor-pointer rounded-md text-black capitalize text-base font-medium"
                        >
                          view order
                        </span> :
                        <Link
                          to="/user-profile/bookings"
                          className="py-2 px-3 border rounded-md text-black capitalize text-base font-medium"
                        >
                          view order
                        </Link>
                      )
                    }
                    <Link
                      to="/"
                      className="py-2 px-5 bg-auraPrimary rounded-md capitalize text-base font-medium text-white hover:text-white"
                    >
                      back home
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <GuestBookingDetailModal 
                openModal={openModal} 
                setOpenModal={setOpenModal}
            />

            <div className=" space-y-10 my-36">
              <div className="space-y-3">
                <h3 className="text-3xl font-medium">You might also like</h3>
                <p>Based on our choices on trending places</p>
              </div>

              <div className="grid grid-cols-1 justify-center items-stretch gap-8 mt-10 sm:grid-cols-2 xl:grid-cols-4">
                {recommendedSlice.map((item: any, key: number) => (
                  <ProductItem
                    key={key}
                    name={item.productName}
                    item={item}
                    bedrooms={15}
                    amount={item.amount}
                    location={`${item.city ? item.city : 'Ikeja'}, ${item.stateName ? item.stateName : 'Lagos'}`}
                    image={item.productPicture ? item.productPicture : emptyImage}
                    productDetailsID={item?.productDetailsID ? item?.productDetailsID : ''}
                    productType={item.product ? item.product : 'hotel'}
                    corporatePrice={item?.corporatePrice || 0}
                  />
                ))}
              </div>

              {/* <div className="grid grid-cols-2 lg:grid-cols-4 gap-14">
                <PlacesCard image={KFC} title="KFC" />
                <PlacesCard image={RoadChef} title="Road Chef" />
                <PlacesCard image={BeachBed} title="Landmark Beach" />
                <PlacesCard image={RestaurantUnderground} title="Kapadoccia" />
              </div> */}
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default CheckoutComplete;