import service from 'services/fetchInterceptor';
import {
  CreateProductData,
  CreateProductReviewData,
  ServiceType,
  UpdateProductDetailsData,
  EditProductDetailsData,
} from './types';

const productService: ServiceType | any = {};

productService.createProduct = (data: CreateProductData): Promise<any> => {
  return service({
    url: '/ProductDetails/product/details',
    method: 'post',
    data,
  });
};

productService.getProduct = (id: String): Promise<any> => {
  return service({
    url: `/ProductDetails/product/details/${id}`,
    method: 'get',
  });
};

productService.getProducts = (): Promise<any> => {
  return service({
    url: '/ProductDetails/product/details',
    method: 'get',
  });
};

productService.editProduct = ( data: EditProductDetailsData): Promise<any> => {
  return service({
    url: `/ProductDetails/product/details/update`,
    method: 'put',
    data,
  });
};

productService.submitProductAmenities = (data: any): Promise<any> =>
  service({
    url: '/v1/ProductDetailsAmenities/product-details/amenities',
    method: 'post',
    data,
  });

  productService.submitExperienceProductAmenities = (data: any): Promise<any> =>
  service({
    url: '/v1/ProductDetailsAmenities/product-details/experience/amenities',
    method: 'post',
    data,
  });

productService.createProductAmenities = (data: any): Promise<any> =>
service({
  url: '/v1/ProductDetailsAmenities/product/amenities',
  method: 'post',
  data,
});

productService.createProductService = (data: any): Promise<any> =>
  service({
    url: '/Service/service/bulk',
    method: 'post',
    data,
  });

productService.updateProductService = (data: any): Promise<any> =>
  service({
    url: '/Service/services/update/single/new',
    method: 'put',
    data,
  });

productService.updateProductDetails = (
  data: UpdateProductDetailsData
): Promise<any> =>
  service({
    url: '/ProductDetails/product/details/update',
    method: 'put',
    data,
  });

productService.updateProductPictures = (file: any): Promise<any> => {
  const url = '/ProductDetailPictures/product/pictures';
  return service.post(url, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

productService.updateServicePictures = (file: any): Promise<any> => {
  const url = '/ServicePictures/service/pictures';
  return service.post(url, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

productService.getServicePicturesByID = (id: any): Promise<any> => {
  const url = `/ServicePictures/service/${id}/pictures`;
  return service.get(url, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

productService.getProductDetails = (detailId: string): Promise<any> =>
  service({
    url: `/ProductDetails/product/details/complete/${detailId}`,
    method: 'get',
  });

productService.getProductCategories = (): Promise<any> =>
  service({
    url: `/Products/products`,
    method: 'get',
  });

productService.createProductReview = (data:CreateProductReviewData): Promise<any> =>
  service({
    url: 'v1/Review/create-review',
    method: 'post',
    data,
    headers: {
      'public-request': 'true',
    },
  });

productService.getProductReviewsByServiceID = (
  serviceID: number | string
): Promise<any> =>
  service({
    url: `v1/Review/get-review-service-by-id/${serviceID}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  productService.getProductTotalReviewCount = (): Promise<any> =>
  service({
    url: `/v1/Review/total-review-count`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  productService.getAllProductReviewByID = (id: any, search: any, pageNumber: number, pageSize: number): Promise<any> =>
  service({
    url: `/v1/Review/get-aggregate-review-by-HostVendorId?ProductID=${id}&PageNumber=${pageNumber}&PageSize=${pageSize}&searchVal=${search}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });
  
  productService.deleteServicePictureByID = (id: any): Promise<any> =>
  service({
    url: `/ServicePictures/service/pictures/${id}`,
    method: 'delete',
    headers: { 'public-request': 'true' },
  });

  productService.getHostCommissionFee = (): Promise<any> =>
  service({
    url: `/v1/Commission/get-host-commission-fee`,
    method: 'get',
  });

  productService.getExperienceQuote = (productDetailsID: String, totalGuest: String): Promise<any> => {
    return service({
      url: `/v1/Booking/create-experience-quote`,
      data: {
        totalGuest,
        productDetailsID
      },
      method: 'post',
    });
  };

export default productService;
