import { Modal } from 'components/common';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setreloadCount } from 'redux/authSlice/actions';
import { saveSelectedBooking } from 'redux/reservation/actions';
// import Modal from '.';
import lovedimage from '../../../assets/img/success.svg';
import Rating from '@mui/material/Rating';
import { Dots } from 'components/common/Loader';
import { Rate } from 'antd';

interface ReviewModalProp {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  comment:string;
  isLoading:boolean;
  setcomment: React.Dispatch<React.SetStateAction<string>>;
  ratingReasonID: number | string;
  setratingReasonID: React.Dispatch<React.SetStateAction<number | string>>;
  singleBookingData?: Record<string, any>;
  rating: number | null;
  setRating: React.Dispatch<React.SetStateAction<number | null>>;
  handleAddReview:()=>void;
}
export default function ReviewModal(props: ReviewModalProp) {
  const dispatch = useDispatch();

  const reasons = [
    {
      ratingReasonID: 6,
      reason: 'Poor service',
    },
    {
      ratingReasonID: 7,
      reason: 'Unclean environment',
    },
    {
      ratingReasonID: 8,
      reason: 'Non responsive hosts',
    },
    {
      ratingReasonID: 9,
      reason: 'Incomplete Experience',
    },
    {
      ratingReasonID: 10,
      reason: 'Not to expectations',
    },
  ];

  function getPageName() {
    const {pathname} = window.location

    if (pathname.toLowerCase().includes("hotel")){
      return "hotel";
    } else if  (pathname.toLowerCase().includes("experience")){
      return "location";
    } else if  (pathname.toLowerCase().includes("workspace")){
      return "workspace";
    } else if  (pathname.toLowerCase().includes("apartment")){
      return "apartment";
    }
  }

  return (
    <>
      <Modal
        children={
          <div className="relative w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl fit">
            <div className="p-3 text-center md:p-10 w-full">
              <div className="px-6 py-6 text-center md:px-8  card-body w-full">
                <h2 className="mb-2 text-3xl font-bold">Ratings & Review</h2>
                <p className="mb-3">How was your experience?</p>

                <Rate 
                  value={props.rating || 0} 
                  className="text-[18px] text-[#FAAF00]" 
                  onChange={(value)=>{
                    props.setRating(value);
                  }}
                />

                <p className="mt-2 text-gray-800">Select a rating from 1-5</p>

                <form className=" w-full md:min-w-[24rem]">
                  {(!props.rating || props.rating <= 3) && <div className="p-3 my-3 border rounded">
                    {reasons.map((data, index) => (
                      <div
                        className="flex items-center mb-4 space-x-3"
                        key={index}
                      >
                        <input
                          type="radio"
                          value={data.ratingReasonID}
                          checked={data.ratingReasonID.toString() === props.ratingReasonID.toString()}
                          onChange={(e) =>
                            props.setratingReasonID(e.currentTarget.value)
                          }
                          name="default-radio"
                          className="w-4 h-4 text-auraPrimary  bg-gray-100 border-gray-300 "
                        />
                        <label
                          htmlFor="default-radio-1"
                          className="ml-2 text-sm font-medium text-gray-900 "
                        >
                          {data.reason}
                        </label>
                      </div>
                    ))}
                  </div> }

                  <div className="my-6">
                    <label htmlFor="reason" className="block text-left">
                      Additional comments (optional)
                    </label>
                    <textarea
                      name=""
                      id=""
                      value={props.comment}
                      onChange={(e)=>props.setcomment(e.target.value)}
                      rows={4}
                      className="mt-1 form-control"
                      placeholder=""
                    ></textarea>
                  </div>

                  <div className="mt-10 ">
                    <button
                      type="button"
                      disabled={!props.rating ? true : false}
                      onClick={props.handleAddReview}
                      className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-auraPrimary my-btn disabled:bg-orange-300 disabled:cursor-not-allowed"
                    >
                    {props.isLoading? <Dots/>:"Submit"}  
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        }
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
      />
    </>
  );
}
