import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import Providers from 'context/Providers';
import './main.css';
import './index.css';
// import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import AppRoutes from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Providers>
          <AppRoutes />
        </Providers>
      </PersistGate>
    </Provider>
);

reportWebVitals();
