import deal1 from 'assets/img/deal1.png';
import rate from 'assets/icon/rate.svg';
import wine from 'assets/icon/wine.svg';
import bed from 'assets/icon/bed.svg';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import corporate from 'assets/icon/corporate-icon.svg';
import { Rate } from 'antd';
import arrow from 'assets/icon/arrow.svg';
import Banner from 'assets/img/bannerF.svg';
import Blog1 from 'assets/img/blog1.jpg';
import Blog2 from 'assets/img/blog2.jpg';
import BtnSlider from './BtnSlider';
import './postSlider.css';

interface Props {
    image: string;
    className?: string;
    onClick?: any;
    name: string;
    location: string;
    bedrooms?: number;
    productDetailsID: string;
    productType: string;
    productID?: any;
    corporatePrice: number;
    amount: number;
    item: any;
}

const ProductItem: FC<Props> = ({
    bedrooms,
    image,
    className,
    onClick,
    name,
    location,
    productDetailsID,
    productType,
    productID,
    corporatePrice,
    amount,
    item,
    ...rest
}) => {
    const getProductName = (param: any) => {
        if (param === 29) return 'Hotel';
        if (param === 30) return 'Apartment';
        if (param === 33) return 'Experience';
        if (param === 34) return 'Workspaces';
        if (param === 31) return 'Restaurant';
        if (param === 32) return 'Photography';
    };

    const parameter = window.location.pathname;
    const lowerCaseProductType = productID ? getProductName(productID)?.toLowerCase() : productType.toLowerCase();
    const renderProductType =
        lowerCaseProductType === 'workspaces' ||
        lowerCaseProductType === 'photography' ||
        lowerCaseProductType === 'activities' ||
        lowerCaseProductType === 'restaurant' ||
        lowerCaseProductType === 'experience' ||
        lowerCaseProductType == 'hotel'
            ? lowerCaseProductType
            : `${lowerCaseProductType}s`;

    const [searchParams] = useSearchParams();
    const checkIn = searchParams.get('checkIn');
    const checkOut = searchParams.get('checkOut');
    const guest = searchParams.get('guest');

    const slides = item?.servicePictures !== null ? item?.servicePictures.slice(0, 3) : []

    // const slides = [
    //     {
    //         id: 1,
    //         img: Banner,
    //     },

    //     {
    //         id: 2,
    //         img: Blog1,
    //     },

    //     {
    //         id: 3,
    //         img: Blog2,
    //     },
    // ];

    const [slideIndex, setSlideIndex] = useState(1);
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const nextSlide = () => {
        if (slideIndex !== slides.length) {
            setSlideIndex(slideIndex + 1);
        } else if (slideIndex === slides.length) {
            setSlideIndex(1);
        }
    };

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1);
        } else if (slideIndex === 1) {
            setSlideIndex(slides.length);
        }
    };

    const moveDot = (index: number) => {
        setSlideIndex(index);
    };

    const autoPlayRef: any = useRef();

    useEffect(() => {
        autoPlayRef.current = nextSlide;
    });

    useEffect(() => {
        const play = () => {
            autoPlayRef.current();
        };
        const interval: any = setInterval(play, 30 * 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Link to={`#`}>
            <div className={`flex flex-col rounded-lg relative bg-white shadow-md ${parameter.includes('recommended') ? 'xl:w-[100%]' : 'xl:w-[350px]'}`}>
                <div className="p-2 heroImgTextSlider relative" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                   { slides == 0 ? 
                        <img src={image} className="!w-full rounded-tl-xl rounded-br-xl !h-80 object-cover" alt="" />
                        :
                        slides.map((obj: string, index: number) => (
                            <div key={index} className={`${slideIndex === index + 1 ? 'slide active-anim' : 'slide'}`}>
                                <img src={obj} alt="" className="!w-full rounded-tl-xl rounded-br-xl !h-[310px] object-cover" />
                            </div>
                        ))
                    }

                    {isHovered && (
                        <>
                            <BtnSlider moveSlide={nextSlide} direction={'next'} />
                            <BtnSlider moveSlide={prevSlide} direction={'prev'} />
                        </>
                    )}

                    <div className="container-dots">
                        {slides.map((item: any, index: number) => (
                            <div
                                key={index}
                                onClick={() => moveDot(index + 1)}
                                className={slideIndex === index + 1 ? 'dot active' : 'dot'}
                            ></div>
                        ))}
                    </div>
                </div>

                <div className="py-6 px-4 border-0">
                    <p className="text-base font-semibold capitalize">{name}</p>
                    <div className="flex flex-col mt-1">
                        <div className="flex justify-between">
                            <p className="text-sm text-black">{location}</p>
                            <p className="text-sm text-black hover:underline">{item.numberAvailable} Rooms Available</p>
                        </div>
                        <div>
                            <p className=" ">
                                <Rate defaultValue={item.rating} allowHalf className="text-[12px]" disabled={true} /> &nbsp;{' '}
                                <span>{item.reviewCount} reviews</span>
                            </p>
                        </div>
                        <div className="">
                            <p className="text-sm text-black">
                                Starting from <b>₦{amount || 0}</b> per night
                            </p>
                        </div>
                        <div className="">
                            <p className="text-sm text-black">Tax Included</p>
                        </div>
                        <div className={`flex ${corporatePrice > 0 ? 'justify-between' : ' justify-end'}  mt-2`}>
                            {corporatePrice > 0 && <p className="font-[600] text-[#777779]">Corporate</p>}
                            <Link
                                to={`/${renderProductType}/${productDetailsID}${
                                    checkIn && checkOut && guest ? `?checkIn=${checkIn}&checkOut=${checkOut}&guest=${guest}` : ``
                                }`}
                            >
                                <img src={arrow} alt="" className="w-[30px] cursor-pointer" />
                            </Link>
                        </div>
                        <div className="ml-3">
                            {/* <div className="flex items-center">
                                <img src={productType === 'activities' ? wine : bed} alt="" />
                                {productType !== 'activities' && <span className="tx-sm ml-1"></span>}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default ProductItem;
