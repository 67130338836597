import { FC, InputHTMLAttributes, useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import { TbEyeOff, TbEye } from 'react-icons/tb';
import { ReactComponent as SmallArrow } from 'assets/icon/small-arrow.svg';
import newsletter from 'assets/img/newsletter.png';
import { useToast } from 'components/common/Toast/ToastProvider';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import smallArrow from 'assets/icon/small-arrow.svg';
import subscribeImage from 'assets/sliders/landingSubscribe.png';

const NewsLetterSection: FC = () => {
    const validateEmail = (email: string) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const url = `https://transcorphotels.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
    const [email, setEmail] = useState('');

    const toast: any = useToast();

    const handleSubmit = (e: any, subscribe: any, status: any) => {
        e.preventDefault();
        if (!validateEmail(email)) return toast.error('Invalid email.');

        subscribe({
            EMAIL: email,
        });
        status === 'error' && toast.error('Error occurred, please try again.');

        status === 'success' && toast.success('You have successfully subscribed to our newsletter.');

        status == null && toast.info('Email already exist or an error occurred. Please try again.');
    };

    return (
        <>
            <section className="recommend md:pb-16 py-8 mt-6">
                <div className="relative">
                    <div style={{ backgroundImage: `url(${subscribeImage})` }}>
                        <div className="container mx-auto grid xl:grid-cols-2 grid-cols-1 justify-between items-center py-[120px] text-white">
                            <div className="flex flex-col flex-start  gap-5">
                                <p className="text-2xl font-medium ">Reduce costs and minimize time expenditure!</p>
                                <p>Let us deliver top-notch offer right in your inbox</p>
                            </div>

                            <MailchimpSubscribe
                                url={url}
                                render={({ subscribe, status }: any) => (
                                    <form
                                        action=""
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmit(e, subscribe, status);
                                        }}
                                        className="flex mt-8 xl:mt-0 justify-between items-center gap-10  w-full"
                                    >
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            value={email}
                                            className="h-[50px] w-full !bg-white/[20%] border-0 focus:ring-0 text-white"
                                            placeholder="Enter your email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <button
                                            type="submit"
                                            className="h-[50px] px-4 text-white !border-0 hover:bg-[#F48220] hover:text-white"
                                        >
                                            Subscribe
                                        </button>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NewsLetterSection;
