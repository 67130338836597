import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getMessaging, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId 
};

//From Aura's google account => https://console.cloud.google.com/google/maps-apis/credentials?project=aura-mobile-eb123
export const apiKey_v3 = process.env.REACT_APP_apiKey_v3;

export const linkedinClientId = process.env.REACT_APP_LINKEDIN_CLIENTID;

export const firebaseVapidKey = process.env.REACT_APP_firebaseVapidKey;

firebase.initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);

let messaging: any

// Check if analytics is supported
isSupported().then((supported) => {
  if (supported) {
    const analytics = getAnalytics(firebaseApp);
    if (typeof window !== 'undefined' && firebaseApp) {
      messaging = getMessaging(firebaseApp);
    }
    
  } else {
    console.log('Analytics is not supported in this environment.');
  }
});

export { messaging };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload: any) => {
      resolve(payload);
    });
  });

export default firebase;
