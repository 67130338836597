import service from 'services/fetchInterceptor';

class AdminService {
  
  createComplaint(data:any): Promise<any> {
    return service({
      url: `v1/Complaint/create-complaints`,
      method: 'post',
      data
    });
  }

  createMessage(data: {
    bookingId: number | string;
    messageSubject: string;
    messageBody: string;
  }): Promise<any> {
    return service({
      url: `v1/Message/CreateMessage`,
      method: 'post',
      data,
    });
  }

  getMessagesInbox(userId: number): Promise<any> {
    return service({
      url: `v1/Message/GetMessagesInbox?UserId=${userId}`,
      method: 'get',
    });
  }
  getMessagesOutbox(userId: number): Promise<any> {
    return service({
      url: `v1/Message/GetMessagesOutbox?UserId=${userId}`,
      method: 'get',
    });
  }
  readMessage(msgId: number): Promise<any> {
    return service({
      url: `v1/Message/ReadMessage?MessageId=${msgId}`,
      method: 'put',
    });
  }
  sendWaWebHook(data: any): Promise<any> {
    return service({
      url: `v1/ChatBot/WhatsAppWebHook`,
      method: 'post',
      data
    });
  }
}

export const AdminServices = new AdminService();
