import "./postSlider.css";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";

const BtnSlider = ({ direction, moveSlide }) => {
  const leftArrow = <AiFillCaretLeft /> 
  const rightArrow =  <AiFillCaretRight />  
  return (
    <button
      onClick={moveSlide}
      className={`${direction === "next" ? "btn-slide next" : "btn-slide prev"} bg-white`}
    >
        {direction === 'next' ? rightArrow : leftArrow}
    </button>
    
  );
};

export default BtnSlider;
