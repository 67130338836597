import React, { useState, useEffect } from 'react';
import { Avatar, Rating } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { NotificationServices } from 'services/notificationService';
import moment from 'moment';
import { Spinner } from '../Loader';

export default function NotificationContent({setLocalNotificationLength}: any) {
  const { user } = useSelector((state: RootState) => state.user);
  const [selected, setselected] = useState('all');
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)


  const fetchHostNotification = async() => {
    const res = await NotificationServices.getAllNotificationByHostVendorId(user?.id);
    setNotificationData(res)

  };
  let path = window.location.pathname;
  const fetchUserNotification = async() => {
    setIsLoading(true)
    const response = await NotificationServices.getAllNotificationByUserId(user?.userID);
    const res = response.data.sort((p1: any, p2: any)=>{
      return new Date (p2.dateCreated).getTime()  - new Date (p1.dateCreated).getTime()
    })
    const unRead = res.filter((item: any)=> item.status == 'unread')

    let returnedData = path.includes('notification') ? unRead : [ unRead[0], unRead[1]]
    setNotificationData(returnedData)

    localStorage.setItem('not', unRead.length)
    setLocalNotificationLength(unRead.length)
    setIsLoading(false)
  };

  useEffect(()=>{
    fetchUserNotification()
  },[])

  // useEffect(() => {
  //   let isCancelled = false;
  //   if (!isCancelled) {
  //     if (user.isHost) {
  //       fetchHostNotification();
  //     } else {
  //       fetchUserNotification();
  //     }
  //   }
  //   return () => {
  //     isCancelled = true;
  //   };
  // }, []);

  return (
    <div className="w-full mb-4">
      <div className="py-4 flex justify-between items-center">
        <div className="flex items-center justify-between  text-gray-600">
          <span
            className={`py-1 text-sm px-4 ${
              selected === 'all' && 'text-auraPrimary bg-orange-100 '
            }   rounded-md  rouned-md cursor-pointer`}
            onClick={() => {
              navigate('/notification');
              setselected('all');
            }}
          >
            All
          </span>
          {/* <span
            className={`py-1 text-sm px-4 ${
              selected === 'unread' && 'text-auraPrimary bg-orange-100 '
            }  rounded-md   rouned-md cursor-pointer`}
            onClick={() => {
              navigate('/notification');
              setselected('unread');
            }}
          >
            Unread
          </span> */}
        </div>
        {!(window.location.pathname==='/notification') && (
        <Link
          to="/notification"
          className="py-1 text-sm px-4  text-auraPrimary rounded-md underline  rouned-md"
        >
          View all
        </Link>
         )}
      </div>

      <div className="pb-4 space-y-6 divide-y ">
      {isLoading ? <Spinner />
      :
      notificationData.map((data: any, index) => (
            <div className="flex items-start pt-4" key={index}>
              {
                data?.recievedByUserName
                && <Avatar
                    src=""
                    sx={{ width: 24, height: 24 }}
                    className=""
                    alt=""
                  />
              }

              <div className="space-y-2 ml-5 w-full">
                <div className="flex items-center justify-between space-x-2  xl:justify-start  flex-wrap">
                  <p className="text-sm font-medium">{data?.recievedByUserName || ''}</p>
                  {/* <Rating size="small" name="read-only" value={5} readOnly /> */}
                </div>
                <p className="text-xs w-full">
                  {data?.messageDetails || ''}
                </p>
                <div className="flex items-center gap-2 w-full">
                  <p className=" text-xs text-gray-500 whitespace-nowrap">
                    {data?.dateCreated && moment(data?.dateCreated).fromNow()}
                  </p>
                  {/* <button className=" text-xs text-auraPrimary">Reply</button> */}
                </div>
              </div>
            </div>
          ))}

        {/* {Array(3)
          .fill('')
          .map((data, index) => (
            <div className="flex items-start pt-4" key={index}>
              <Avatar
                src=""
                sx={{ width: 24, height: 24 }}
                className=""
                alt=""
              />

              <div className="space-y-2 ml-5 w-full">
                <div className="flex items-center justify-between space-x-2  xl:justify-start  flex-wrap">
                  <p className="text-sm font-medium">Myrtie Runolfsson</p> 
                 <Rating size="small" name="read-only" value={5} readOnly /> 
                </div>
                <p className="text-xs w-full">
                  Best place I stayed in Lagos. The experience was the best.. 🙌
                  🎉 😎
                </p>
                <div className="flex items-center gap-2 w-full">
                  <p className=" text-xs text-gray-500 whitespace-nowrap">
                    about 1 hour ago
                  </p>
                   <button className=" text-xs text-auraPrimary">Reply</button> 
                </div>
              </div>
            </div>
          ))}*/}
      </div>
    </div>
  );
}
