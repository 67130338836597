import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { Footer, Header, PlacesCard } from 'components/common';
import { RootState } from 'redux/rootReducer';

export default function AboutUs(){
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  return (
    <div className='min-h-screen flex flex-col justify-between'>
    <Header isAuthenticated={isAuthenticated} />
    <div className="max-w-4xl mr-auto ml-auto text-base pr-4 pl-4 pt-5 h-full min-h-[20rem]">
      <h2 className="mb-5 text-3xl font-medium title ">About Us</h2>
      <h5 className="mb-3 text-base font-light">
      Aura is a digital platform enabling travellers book accommodation and experiences in Africa, influenced by local culture. Aura aims to be the biggest platform providing one stop shop for quality Hotels and apartment at the best prices in Africa, providing a variety of prices for short let accommodation for business and leisure travelers.
      </h5>
      
    </div>
    <Footer />

    </div>
  );
};


