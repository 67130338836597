import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {
  ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
import { loginUser,
 socialAuthentication, socialMediaAuth } from 'redux/authSlice/actions';
import { facebookProvider, googleProvider } from 'utils/config';
import firebase from 'firebase/compat';
import Dot from 'components/common/Loader/Dot';
import dataService from 'services/appData';
import { linkedinClientId } from 'services/firebase-config';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import signupsidebarImg from 'assets/img/signupsidebarImg.svg';
import googleIcon from 'assets/img/googleIcon.svg';
import facebookIcon from 'assets/img/facebookIcon.svg';
import linkedInIcon from 'assets/img/linkedInIcon.svg';
import emailIcon from 'assets/img/emailIcon.svg';
import leftLine from 'assets/img/leftLine.svg';
import rightLine from 'assets/img/rightLine.svg';
import Newlogo from 'assets/img/newlogos/1.png';

interface LoginProps {
  openModal?: boolean;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

const Login: FC<LoginProps> = ({ openModal = true, setOpenModal }) => {
  // const [openModal, setOpenModal] = useState(true);
  const [isLoading, setLoading ] = useState(false)
  const [showResend, setResend ] = useState(false);
  const [email, setEmail] = useState('')
  const toast= useToast();
  const [signUpFirstSlide, setSignUpFirstSlide] =  useState(true)

  const dispatch:any = useDispatch();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .trim()
      .required('Email is required'),
    password: Yup.string().trim().required('Password is required'),
  });

  const handleSocialAuth = async (provider: firebase.auth.AuthProvider) => {
    const res = await socialMediaAuth(provider);
    if (res?.multiFactor?.user) {
      const { displayName, email,phoneNumber,photoURL,accessToken, stsTokenManager,uid } = res.multiFactor.user;
      dispatch(socialAuthentication(
        {
          displayName,
          email,
          passportPath:photoURL,
          accessToken,
          refreshToken:stsTokenManager?.refreshToken,
          phoneNumber,
          uid
        },
        setOpenModal,
        navigate,
        toast
      ))
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: linkedinClientId || '',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => {
      console.log(code);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleResendOtp = async (e: any) =>{
    e.preventDefault();
    try {
      const response = await await dataService.resendEmailValidation(email); 
      toast?.success('Email validation successfully sent.');
      // setisLoading(false);
    } catch (err: any) {
      toast?.error(err.response.data.responseMessage, 5000);
    }
  }

  return (
    <Modal
      openModal={openModal as boolean}
      setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
      className="bg-white w-full max-w-[542px] lg:!max-w-5xl Home-sign-in !rounded-none"
      onOutsideClick={() => {}}
      onModalClose={
        setOpenModal ? () => setOpenModal(false) : () => navigate('/')
      }
    >
      <div className='lg:flex w-full lg:justify-between h-full'>
          <div className='lg:w-[55%] w-[100%] px-4 sm:px-10'>
          <div className='lg:w-[90%] w-[100%]'>
          <p className={`lg:pt-28 pt-16 pb-6 ${signUpFirstSlide && 'mt-1'}`}>
              <img src={Newlogo} className="w-[15%]" />
          </p>
      <h3 className="lg:text-4xl text-2xl font-semibold leading-10 text-left ">
        Login
      </h3>
      <p className="text-left font-normal text-sm text-gray-4 mt-2">
           Welcome back, login to continue
       </p>
      {signUpFirstSlide ?
       <>
         <div className='mt-10'>
              <div className='flex item-center py-2 px-4 cursor-pointer'
                   onClick={() => {
                       handleSocialAuth(googleProvider)
                   }}
               >
               <p className='w-[30%]'>
                   <img src={googleIcon} alt=''/> 
               </p>
               <p>Login with Google </p>
              </div>

              <div className='flex item-center mt-4 py-2 px-4 cursor-pointer'
                  onClick={() => {
                      handleSocialAuth(facebookProvider)
                  }}
              >
                <p className='w-[30%]'>
                                    <img src={facebookIcon} alt=''/> 
                </p>
                <p>Login with Facebook </p>
              </div>

              <div className='flex item-center mt-4 py-2 px-4 cursor-pointer'
                   onClick={linkedInLogin}
               >
                   <p className='w-[30%]'>
                       <img src={linkedInIcon} alt=''/> 
                   </p>
                   <p>Login with LinkedIn </p>
              </div>

              <div className='flex item-center mt-4 border py-2 px-4 border-black rounded-md bg-[#E8EAEE] cursor-pointer'
                 onClick={()=>setSignUpFirstSlide(false)}
              >
                 <p className='w-[30%]'>
                     <img src={emailIcon} alt=''/> 
                 </p>
                 <p>Continue with Email </p>
              </div>
         </div>
         <div className='flex !w-[90%] justify-evenly items-center mt-10 mb-14 lg:mb-0'>
             <div>
                 <img src={leftLine} alt='sideLine' />
             </div>
             <div className="text-xs space-x-1 text-center col-span-2 ">
             <span> Don't have an account?</span>

            <Link to="/signup" className="text-auraPrimary hover:underline">
              Create account
            </Link>
            </div>
             <div>
             <img src={rightLine} alt='sideLine' />
             </div>
         </div>
        </>
        :
      <>
        <div className="flex w-full justify-evenly gap-2 mt-10 border border-black py-3 rounded-md">
              <img src={googleIcon} alt='googleIcon' className='cursor-pointer'
                  onClick={() => {
                      handleSocialAuth(googleProvider)
                  }}
              />
              <img src={facebookIcon} alt='facebookIcon' className='cursor-pointer'
                  onClick={() => {
                      handleSocialAuth(facebookProvider)
                  }}
              />
              <img src={linkedInIcon} alt='linkedInIcon' className='cursor-pointer'
                  onClick={linkedInLogin}
              />
          </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const { email, password } = values;
            setEmail(email)

            setLoading(true)
            await dispatch(
              loginUser(
                { email, password },
                navigate,
                toast as ToastContextType,
                setOpenModal as Dispatch<SetStateAction<boolean>>,
                setResend
              )
            );
            setLoading(false)
          }}
        >
          {({ values, errors, touched }) => (
            <Form className="w-full" autoComplete='off' >
              <Input
                className="w-full mt-4 bg-[#E8EAEE] !rounded-md"
                name="email"
                label="Email Address"
                error={errors.email}
                touched={touched.email}
                autoComplete='off'
                modalUseCse={'signin'}
              />
              <Input
                className="w-full mt-4 bg-[#E8EAEE] !rounded-md"
                name="password"
                type="password"
                label="Password"
                error={errors.password}
                touched={touched.password}
                autoComplete="new-password"
                modalUseCse={'signin'}
              />
              <div className="w-full flex mt-2">
              <Link
                to="/forgot-password"
                className="w-max  text-end block text-auraPrimary text-sm font-normal"
              >
                Forgot password?
              </Link>
              </div>
          
              <div className="w-full mt-6">
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full h-14 text-base"
                >
                  {isLoading ? <Dot /> : <span>Log in</span>}
                </Button>
              </div>

                <div className='flex !w-[90%] justify-evenly items-center mt-10 mb-10 lg:mb-0'>
                    <div>
                        <img src={leftLine} alt='sideLine' />
                    </div>
                    <div className="text-xs space-x-1 text-center col-span-2 ">
                      <span> Don't have an account?</span>

                      <Link to="/signup" className="text-auraPrimary hover:underline">
                        Create account
                      </Link>
                      {
                        showResend && (
                          <>
                            <p>or</p>
                            <button onClick={handleResendOtp} className="text-auraPrimary text-sm hover:underline">
                              Resend Email Validation
                            </button>
                          </>
                        )
                      }
                    </div>
                    <div>
                    <img src={rightLine} alt='sideLine' />
                    </div>
                </div>
            </Form>
          )}
        </Formik>
      </>
      }
      </div>
        </div>
          <div className='w-[45%] hidden lg:block !h-full'>
              <img src={signupsidebarImg} alt="signup img" className={`w-full ${signUpFirstSlide ? '!h-[650px]' : '!h-[750px]'} object-cover`}/>
          </div>
        </div>
    </Modal>
  );
};

export default Login;
