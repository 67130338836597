import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { IoLogoGoogle } from 'react-icons/io';
// import { CgFacebook } from 'react-icons/cg';
// import { TiSocialLinkedin } from 'react-icons/ti';
// import { Formik, Form } from 'formik';
// import * as Yup from 'yup';
// import { Link } from 'react-router-dom';
import {
  //   ToastContextType,
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
// import { loginUser, socialMediaAuth } from 'redux/authSlice/actions';
// import { facebookProvider, googleProvider } from 'utils/config';
// import firebase from 'firebase/compat';
import { RootState } from 'redux/rootReducer';
// import Dot from 'components/common/Loader/Dot';
// import formatDate from 'utils/date/dateFormat';
import { ReactComponent as CancelReason } from 'assets/icon/cancellation.svg';
import { cancelUserBooking, clearCancelBookingResponse } from 'redux/reservation/actions';
import { Dots } from 'components/common/Loader';
import { openInNewTab } from 'utils/openInNewTab';
import bookingService from 'services/bookingService';

interface BookingDetailModalProps {
  openModal?: boolean;
  isLoading: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setopenCancelModal: Dispatch<SetStateAction<boolean>>;
  setisLoading: Dispatch<SetStateAction<boolean>>;
  fetchBookings: () => void;
}

const ConfirmCancellationModal: FC<BookingDetailModalProps> = ({
  openModal = true,
  setOpenModal,
  isLoading,
  setisLoading,
  setopenCancelModal,
  fetchBookings,
}) => {
  // const [openModal, setOpenModal] = useState(true);
  const { user } = useSelector((state: RootState) => state.user);
  const [check, setcheck] = useState(false);
  const { selectedBooking, cancelledBookingResponse } = useSelector(
    (state: RootState) => state.reservation
  );
  const toast = useToast();
  const [reason, setreason] = useState('');

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const handleCancelBooking = async () => {
    if(!reason || !check) return;
    setisLoading(true);
    await dispatch(cancelUserBooking(selectedBooking.bookingID, reason, toast));
    if(selectedBooking?.paymentStatus?.toLowerCase() == 'paid' ){
      await createUserRefund({
        "reasonForRefund": reason,
        "userId": user?.userID,
        "bookingID": selectedBooking.bookingID,
        "amount": 0
      })
    }

    await fetchBookings();
    setisLoading(false);
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (cancelledBookingResponse?.data?.includes('success')) {
        setOpenModal(false);
        setopenCancelModal(true);
        dispatch(clearCancelBookingResponse());
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [cancelledBookingResponse?.data]);

  // console.log('selectedBooking', selectedBooking)

  const createUserRefund = async (data: any)=> {
    try {
      const res = await bookingService.createUserRefund(data);
    } catch (err: any) {
      toast?.error(err.response.data.responseMessage)
      console.log("err", err.response.data.responseMessage)
    }
  };

  return (
    <Modal
      openModal={openModal as boolean}
      setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
      className="pt-2 pb-12 px-4 sm:px-10 w-full max-w-[542px] bg-white shadow border border-auraPrimary rounded-xl"
      onOutsideClick={() => {}}
      onModalClose={
        setOpenModal
          ? () => setOpenModal(false)
          : () => navigate('/user-profile/bookings')
      }
    >
      <div className="flex items-center justify-center w-full">
        <div className="w-full px-3 pt-8">
          <div className="w-full">
            {/* <!-- Modal body --> */}
            <div className="p-3 text-center w-full">
              <div className="flex justify-center">
                <CancelReason />
              </div>
              <h2 className="mt-4 mb-5 text-3xl font-bold">
                Reservation Cancellation
              </h2>
              <form className="space-y-8 pt-4">
                <div className="mb-2">
                  <label htmlFor="reason" className="block text-left">
                    Reasons for cancellation{' '}
                    <span className="text-auraPrimary">*</span>
                  </label>
                  <textarea
                    name=""
                    id=""
                    rows={4}
                    value={reason}
                    onChange={(e: any) => setreason(e.target.value)}
                    className="mt-1 form-control"
                    placeholder=""
                    required
                  ></textarea>
                </div>
                {
                  selectedBooking?.paymentStatus?.toLowerCase() == 'paid' 
                  ? (

                    <div className="mt-2 mb-5">
                    <div className="items-start">
                      <label
                        className="text-auraPrimary"
                      >
                        Cancellation Notice
                      </label>
                    </div>
                    <div className="text-sm" style={{ marginLeft: -10 }}>
                        <label
                          htmlFor="privacy"
                          className=" text-neutral input-form-label"
                        >
                          
                          Guests are entitled to free cancellation and a full refund less transfer charges incurred by Aura, if the booking is cancelled not more than 48 hours after booking, and at least 14 days before check-in (time shown on the confirmation email). 
                          {' '}
                        </label>
                      </div>
                    </div>
                  ) : ''
                }

                {/* <!-- checkbox  --> */}
                <div className="mt-2 mb-5">
                  <div className="flex items-start">
                    <div className="flex items-center checkbox">
                      <input
                        id="privacy"
                        name="privacy"
                        type="checkbox"
                        className="input-form"
                        checked={check}
                        onChange={() => setcheck((prev) => !prev)}
                      />
                    </div>
                    

                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="privacy"
                        className=" text-neutral input-form-label"
                      >
                        By accepting you agree to our{' '}
                        <span className="underline text-auraPrimary underline-offset-3 cursor-pointer" onClick={()=>openInNewTab(`${window.location.origin}/cancellation-policy`)}>
                          refund policy conditions 
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-10 ">
                  <button
                    type="button"
                    onClick={handleCancelBooking}
                    disabled={!check}
                    className={`block w-full px-4 py-3 text-base text-white rounded-md  ${
                      check
                        ? 'bg-auraPrimary hover:bg-auraPrimary'
                        : 'bg-gray-300'
                    }`}
                  >
                    {isLoading ? <Dots /> : 'Continue'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmCancellationModal;
