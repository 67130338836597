import { Dispatch, Action } from '@reduxjs/toolkit';
import {
  loadingStart,
  loadingStopped,
  reservationSuccess,
  selectedBooking,
  paysmallsmallCost,
  createQuoteSuccess,
  saveCancelBookingResponse,
  ClearCancelBookingResponse,
} from './reducers';
import bookingService from 'services/bookingService';
import { CreateBookingData } from 'services/bookingService/types';
import { ToastContextType } from 'components/common/Toast/ToastProvider';
import { SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';

export const createBooking =
  (
    data: CreateBookingData,
    setOpenModal: {
      (value: SetStateAction<boolean>): void;
      (arg0: boolean): void;
    }
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await bookingService.createBooking(data);
      console.log(res);
      setOpenModal(false);
      dispatch(reservationSuccess(res));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const cancelReservation =
  (bookingID: number | string, toast: ToastContextType | undefined) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await bookingService.cancelBooking(bookingID);
      toast?.success(res.responseMessage, 5000);
      console.log(res);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const cancelUserBooking =
  (
    bookingID: number | string,
    reason: string,
    toast: ToastContextType | undefined
  ) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await bookingService.cancelUserBooking({ bookingID, reason });
      // toast?.success(res.responseMessage, 5000);
      dispatch(saveCancelBookingResponse(res));
      console.log(res);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const saveSelectedBooking =
  (data: any) => (dispatch: Dispatch<Action>) => {
    try {
      dispatch(selectedBooking(data));
    } catch (err: any) {
      console.log(err);
    }
  };

export const createBookingQuote =
  (data: any) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await bookingService.createQuote(data);
      dispatch(createQuoteSuccess(res.data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

  export const createGuestBookingQuote =
  (data: any, checkinDate: any, checkoutDate: any, productName: string, detailsID: any, navigate: NavigateFunction) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {

    try {
      dispatch(loadingStart());
      const res = await bookingService.createQuote(data);
      dispatch(createQuoteSuccess(res.data));
      if (checkinDate && checkoutDate) {
         res && navigate(`/${productName}/${detailsID}/reservation`);
    }
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const clearCancelBookingResponse =
  () => (dispatch: Dispatch<Action>) => {
    dispatch(ClearCancelBookingResponse());
  };

export const paysmallsmall =
  (data: any) => (dispatch: Dispatch<Action>) => {
    try {
      dispatch(paysmallsmallCost(data));
    } catch (err: any) {
      console.log(err);
    }
  };
