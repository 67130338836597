import { FC, useEffect, useState } from 'react';
import { Header, Footer, Modal } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
import productService from 'services/productService';
import { Spinner } from 'components/common/Loader';
import homeIcon from 'assets/icon/home.svg';
import reserveModalIcon from 'assets/icon/reserve-modal.svg';
import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import bookingService from 'services/bookingService';
import formatDate, { dashDateFormat, today, tommorrow } from 'utils/date/dateFormat';
import RightReservationSection from 'pages/ReusableProduct/RightReservationSection';
import Coupon from './activiCoupon';
import BankTransfer from './BankTransfer';
import CreditDebitCard from './CreditDebitCard';
import PaySmallSmall from './PaySmallSmall';
import { initializePayment } from 'redux/payment/actions';
import { UpcomingActivityServices } from 'services/upcomingActivities';
import moment from 'moment'
import { paysmallsmall } from 'redux/reservation/actions';
import { saveActivityId } from 'redux/booking/action';
import { IArtData } from 'pages/Photography/interface';
import { AuxService } from 'services/auxService';
import { Delivery, ICartCheckout } from 'services/auxService/interface';
import RightPhotoSection from 'pages/ReusableProduct/RightPhotoSection';
import { formatPrice } from 'helpers';

const Payment: FC = () => {
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const dispatch: any = useDispatch();
    const [productDetails, setproductDetails] = useState<Record<string, any>>({});
    const [quotes, setQuotes] = useState<Record<string, any>>({});
    const [isLoading, setisLoading] = useState(true);
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const { detailsID } = useParams<{ detailsID: string }>();

    // const { payment }:any = useSelector((state: RootState) => state.payment);
    const { selectedBooking } = useSelector((state: RootState) => state.reservation);
    const { paysmallsmallCost } = useSelector((state: any) => state.reservation);

    const [installmentNo, setInstallmentNo] = useState(1)
    const [locationStates, setLocationstate] = useState<any>(null)
    const location = useLocation()

    const [artdata, setArtdata] = useState<IArtData>(location.state)
    // console.log("artdata",location.state);
    

    const [paymentPlan, setpaymentPlan] = useState([])
    const [deliveryInfo, setdeliveryInfo] = useState<Delivery[] | []>([])
    const [checkoutdetails, setcheckoutdetails] = useState<ICartCheckout | any>()


    const [searchParams] = useSearchParams();
    const detailID = searchParams.get('detailID');
    const paramGuest = searchParams.get('guest');
    const paramQty = searchParams.get('qty');
    const paramBookingId = searchParams.get('bookingID');
    const paramBookingRef = searchParams.get('bookingRef');
    
    const [paymentForm, setPaymentForm] = useState({
        bookingID: 0,
        paymentType: 'full',
        paymentPlatform: '',
        couponCode: '',
        totalPayable: 0,
        insuranceCode: '',
        insuranceId: 0,
        userData: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.emailAddress || localStorage.getItem('activityEmail'),
            phone: user.phoneNumber,
        },
        isActivityCalender: false,
    });
    // console.log('paymentForm', paymentForm)

    const [deliveryData, setdeliveryData] = useState<any>({
        type: 'pickup',
        id: 0,
        address: productDetails.address || artdata?.address || "",
        deliveryFee: 0
    })

    async function getCompleteProductDetails(id: any) {
        setisLoading(true)
        try {
            const res = await productService.getProductDetails(id);
            // console.log(res);
            setproductDetails(res.data['productDetails'])
            setdeliveryData({
                ...deliveryData,
                address: res.data['productDetails']?.address
            })
            setisLoading(false)
        } catch (error: any) {
            setisLoading(false)
            console.log(error);
        }
    }

    const getBookingByBookingId = async (id: number | string) => {
        setisLoading(true);
        try {
            const res = await bookingService.getBookingByBookingID(paramBookingId);
            setproductDetails(res.data);
            let nextInstallment: any = res.data?.bookingPaymentSmallSmalls.filter((p: any) => p.paymentStatus != 'Paid')[0]
            let breakDownCost: any = [];
            if (locationStates?.bookingId) {
                breakDownCost = [
                    {
                        name: 'Total Amount Payable',
                        value: nextInstallment?.amountDue,
                    }
                ]

                setPaymentForm({
                    ...paymentForm,
                    totalPayable: nextInstallment?.amountDue,
                    bookingID: res.data.bookingID,
                });

                setQuotes({
                    costBreakDowns: breakDownCost || []
                })
            }
            else {
                setPaymentForm({
                    ...paymentForm,
                    bookingID: res.data.bookingID,
                });
                setQuotes({
                    costBreakDowns: res?.data?.costBreakDowns || []
                })
            }

            setisLoading(false);

            // createBookingQuote(res.data.bookingDetails);
        } catch (err: any) {
            setisLoading(false);
            // console.log('err', err.response.data.responseMessage);
        }
    };

    const getActivityBookingByBookingId = async (id: any) => {
        setisLoading(true);
        try {
            const res = await bookingService.getActivityBookingById(Number(id));
            // setproductDetails(res.data);

            setPaymentForm({
                ...paymentForm,
                isActivityCalender: true,
                bookingID: res.data.bookingID,
            });
            setQuotes(res?.data);

            dispatch(
                getBookingByBookingId(res?.data)
            );

            setisLoading(false);
            ;
        } catch (err: any) {
            setisLoading(false);
            // console.log('err', err.response.data.responseMessage);
        }
    };


    const getActivityById = async (id: number | string) => {
        setisLoading(true);
        try {
            const res = await UpcomingActivityServices.getActivityCalendar(id);

            setproductDetails(res.data);
            setisLoading(false);

            // setPaymentForm({
            //     ...paymentForm,
            //     bookingID: paramBookingId ? parseInt(paramBookingId) : 0,
            //     totalPayable: selectedBooking?.payableAmount,
            // });
        } catch (err: any) {
            setisLoading(false);
            console.log('err', err.response.data.responseMessage);
        }
    };

    const createBookingQuote = async (data: any) => {
        try {
            const res = await bookingService.createQuote({ quoteRequests: data });
            setQuotes(res.data);
            setPaymentForm({
                ...paymentForm,
                totalPayable: formatNumber(res.data.totalPayable),
            });
            setisLoading(false);
        } catch (err: any) {
            console.log('err', err.response.data.responseMessage);
        }
    };

    async function getCheckoutDetails() {

        try {
            const res = await AuxService.CartCheckout({
                cartIds: artdata.cartIds,
                deliveryId: deliveryData.id,
                deliveryOption: deliveryData.type.toUpperCase(),
                userId: artdata.userId
            })
            const data = res.data as ICartCheckout;
            setcheckoutdetails(data)
            // console.log("cak", data);
            
            setPaymentForm({
                ...paymentForm,
                totalPayable: data.totalAmount,
                userData: {
                    firstName: artdata.details.firstName,
                    lastName: artdata.details.lastName,
                    email: artdata.details.emailAddress,
                    phone: artdata.details.phoneNumber,
                }
            })
        } catch (error) {
            console.log('error');
        }
    }

    async function getDeliveryDetails() {

        try {
            const res = await AuxService.DeliveryDetails(artdata.vendorId || productDetails.vendorId)
            const data = res.data as Delivery[];
            setdeliveryInfo(data);
        } catch (error) {
            console.log('error');
        }
    }


    const formatNumber = (param: any) => {
        const value = Number(param).toFixed(2);
        return Number(value);
    };

    const getPaymentPlan = async () => {
        try {
            const res = await bookingService.getpaymentPlan();
            setpaymentPlan(res.data);
            console.log('getpayment plamn', res.data)
        } catch (err: any) {
            // console.log('err', err.response.data.responseMessage);
        }
    };

    function computeTotalAmount() {
        const value = artdata.cart.reduce((acc, item) => acc + (item.amount), 0);
        // setTotalamount(value);
        return value;
    }

    const path = window.location.pathname.toLowerCase();

    const dayDiff = moment(productDetails?.checkInDate).diff(moment(), 'days');

    useEffect(() => {
        window.scrollTo(0, 0)
        let isCancelled = false;
        console.log('isCancelled', isCancelled)
        if (!isCancelled) {
            getPaymentPlan()
            if (detailsID) {
                getBookingByBookingId(detailsID);
            }
            if (detailID) {
                if (path.includes('activity')) {
                    getActivityBookingByBookingId(paramBookingId)
                    getActivityById(detailID);
                }

                setPaymentForm({
                    ...paymentForm,
                    isActivityCalender: true
                });
                // else {
                //     getExperienceByDetailsId(detailID);
                // }
            }
        }
        return () => {
            isCancelled = true;
            dispatch(paysmallsmall(null))
        };
    }, [detailsID, detailID, locationStates]);

    useEffect(() => {
        // console.log("paymentForm", paymentForm);
        if (paysmallsmallCost?.installmentModels?.length > 0
            && paymentForm?.paymentType == 'installment'
        ) {
            setPaymentForm({
                ...paymentForm,
                totalPayable: formatNumber(paysmallsmallCost?.installmentModels[installmentNo - 1]?.amount)
            })
        }
        else if (paymentForm?.paymentType == 'full' && (detailsID || detailID)) {
            setPaymentForm({
                ...paymentForm,
                totalPayable: formatNumber(quotes?.costBreakDowns?.filter(
                    (p: any) => p?.name?.includes('Total Amount Payable'))[0]?.value
                )
            })
        }
        else if (artdata && artdata.isAuxservice) return;
        else if (locationStates?.bookingId) return
        else {
            setPaymentForm({
                ...paymentForm,
                totalPayable: selectedBooking?.payableAmount,
            })
        }

    }, [paysmallsmallCost, paymentForm?.paymentType, paymentForm.paymentPlatform])


    useEffect(() => {
        const locationState = location?.state?.paymentInfo;
        if (locationState?.bookingId) {
            setLocationstate(locationState)
            setInstallmentNo(locationState?.installment || 1)
            setPaymentForm({
                ...paymentForm,
                paymentType: 'installment',
                bookingID: locationState?.bookingId,
                // totalPayable: locationState?.amountDue || 0
            })
        }
        console.log("artdata", artdata);

    }, [location])

    useEffect(() => {
        const { address, id, type } = deliveryData

        // console.log(deliveryData);
        

        if (type === "pickup") {
            getCheckoutDetails();
            return;
        }

        if (type === "shipping" && id && address.length > 3) {
            getCheckoutDetails()
            return;
        }

        return;
    }, [deliveryData])

    useEffect(() => {
        if (artdata && artdata.isAuxservice) {
            getCompleteProductDetails(detailsID)
            getDeliveryDetails()
        }
    }, [])



    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <section className="bg-gray-50 py-1 fit">
                        <div className="container mb-6">
                            
                        </div>
                    </section>
                    <div className="bg-gray-50 px-4 sm:px-20 lg:px-40 pb-20 md:pb-40 grid lg:grid-cols-2 gap-x-16 gap-y-8 pt-8 fit">
                        <div className="space-y-10 text-left  ">
                            <div className="space-y-8">
                                {
                                    locationStates
                                        ? ''
                                        : (

                                            <div className="space-y-5">

                                                {artdata && artdata.isAuxservice && (
                                                    <div className="space-y-5">
                                                        <h3 className=" text-2xl font-medium mb-4">Delivery Type</h3>

                                                        <Radio.Group
                                                            onChange={(e) =>
                                                                setdeliveryData({
                                                                    ...deliveryData,
                                                                    type: e.target.value,
                                                                    address: e.target.value === 'pickup' ? artdata.address || productDetails.address : ""
                                                                })
                                                            }
                                                            value={deliveryData.type}
                                                        >
                                                            <Radio value="pickup">Pickup</Radio>
                                                            <Radio value="shipping">Shipping</Radio>
                                                        </Radio.Group>

                                                        

                                                        {deliveryData.type === 'shipping' && <div className="border-2 mt-4 rounded-lg p-5">
                                                            <h2 className='flex items-center mb-6 ml-2 text-base font-light text-gray-500'>
                                                                SHIPPING ADDRESS
                                                                <svg width="16" className='ml-2' height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.4133 5.66261C3.72664 -0.110728 12.28 -0.104061 13.5866 5.66927C14.3533 9.05594 12.2466 11.9226 10.4 13.6959C9.75461 14.3181 8.89309 14.6658 7.99664 14.6658C7.10018 14.6658 6.23867 14.3181 5.5933 13.6959C3.7533 11.9226 1.64664 9.04927 2.4133 5.66261V5.66261Z" stroke="#23262F" stroke-width="1.5" />
                                                                    <path d="M6.1665 7.66667L7.1665 8.66667L9.83317 6" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                            </h2>
                                                            <div className="flex flex-wrap items-center gap-4" id="payment-methods-Tab">
                                                                <select id="password" className="block w-full bg-transparent  placeholder:text-sm placeholder:font-medium"
                                                                     required
                                                                        onChange={
                                                                            (e) =>{
                                                                                setdeliveryData({
                                                                                    ...deliveryData,
                                                                                    id: e.target.value,
                                                                                    deliveryFee: deliveryInfo?.filter((p: any) => p?.deliveryDetailsID == e.target.value )[0]?.deliveryPrice || 0
                                                                                })
                                                                            }
                                                                        }
                                                                        value={deliveryData.id}
                                                                    >
                                                                        <option className="text-sm font-medium"
                                                                                    value=""
                                                                                >
                                                                                    - select location -
                                                                                </option>

                                                                    {
                                                                        deliveryInfo.map(d => {
                                                                            return <>
                                                                                <option className="text-sm font-medium"
                                                                                    value={d.deliveryDetailsID}
                                                                                >
                                                                                    {`${d.deliveryLocation} - N${formatPrice(d.deliveryPrice)}`}
                                                                                </option>

                                                                            </>
                                                                        })
                                                                    }
                                                                </select>

                                                            </div>
                                                        </div>}

                                                        {deliveryData.type === 'shipping' && <div>
                                                            <label
                                                                htmlFor=""
                                                                className="block mb-2 text-xs font-bold uppercase text-neutral108"
                                                            >
                                                                Address
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id=""
                                                                className="block w-full border border-gray-300 rounded-lg shadow-sm placeholder:text-sm placeholder:font-medium"
                                                                placeholder='Enter your adddress'
                                                                required
                                                                value={deliveryData.address}
                                                                onChange={(e: any) => setdeliveryData(
                                                                    { ...deliveryData, address: e.target.value }
                                                                )}
                                                            />
                                                        </div>}
                                                    </div>
                                                )}

                                                <h3 className=" text-2xl font-medium mb-4">Payment Method</h3>

                                                <Radio.Group
                                                    onChange={(e) =>
                                                        setPaymentForm({
                                                            ...paymentForm,
                                                            paymentType: e.target.value,
                                                        })
                                                    }
                                                    value={paymentForm.paymentType}
                                                >
                                                    <Radio value="full" >Full payment</Radio>
                                                    {path.includes('activity')
                                                        ? ''
                                                        : <Radio
                                                            disabled={
                                                                (quotes?.totalPayable < 50000)
                                                                || (quotes?.costBreakDowns
                                                                    ?.filter((p: any) =>
                                                                        p?.name == "Total Amount Payable")[0]?.value < 50000
                                                                )
                                                                || (dayDiff < 14)
                                                            }
                                                            value="installment">Pay small small</Radio>
                                                    }
                                                </Radio.Group>
                                            </div>
                                        )
                                }

                                {
                                    paymentForm.paymentType == 'installment' && !locationStates
                                    && (
                                        <PaySmallSmall
                                            bookingId={detailsID || 0}
                                            isExperience={
                                                productDetails?.serviceID == 0
                                                && productDetails?.productDetailsID >= 0
                                                // && productDetails?.bookingType?.toLowerCase()?.includes('experience')
                                            }
                                            paymentPlan={paymentPlan}
                                        />
                                    )
                                }

                                {paymentForm.paymentType && (
                                    <div className="space-y-5">
                                        <h3 className=" text-xl font-medium mb-4">Pay with</h3>

                                        <Radio.Group
                                            onChange={(e) =>
                                                setPaymentForm({
                                                    ...paymentForm,
                                                    paymentPlatform: e.target.value,
                                                })
                                            }
                                            value={paymentForm.paymentPlatform}
                                        >
                                            <Radio value="flutterwave" className='border border-[#F58320] py-2 px-4 mr-2 rounded-md text-black text-[14px]'>Flutterwave</Radio>
                                            <Radio value="seerbit" className='border border-[#F58320] py-2 px-4 mr-2 rounded-md text-black text-[14px]'>Seerbit</Radio>
                                        </Radio.Group>
                                    </div>
                                )}

                                <div id="payment-methods-tab-content">
                                    {/* {isPayWith === 'BankTransfer' && <BankTransfer />}
                                        {isPayWith === 'Credit/DebitCard' && <CreditDebitCard />}
                                        {isPayWith === 'Paysmallsmall' && <PaySmallSmall />} */}
                                    {!isLoading && (
                                        <Coupon
                                            payment={paymentForm}
                                            setPaymentForm={setPaymentForm}
                                            isLoading={isLoading}
                                            paramBookingId={paramBookingId}
                                            paramBookingRef={paramBookingRef}
                                            detailID={detailID}
                                            paramQty={paramQty}
                                            getBookingByBookingId={getBookingByBookingId}
                                            setQuotes={setQuotes}
                                            installmentNo={installmentNo}
                                            locationStates={locationStates}
                                            isExperience={
                                                productDetails?.serviceID == 0
                                                && productDetails?.productDetailsID >= 0
                                                && productDetails?.productID == 33
                                                // && productDetails?.bookingType?.toLowerCase()?.includes('experience')
                                            }
                                            artdata={artdata}
                                            checkoutdetails={checkoutdetails}
                                            deliveryData={deliveryData}
                                            productDetails={productDetails}
                                            setproductDetails={setproductDetails}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {!artdata && 
                        <RightReservationSection 
                            productDetails={productDetails} 
                            setproductDetails={setproductDetails}
                            quotes={quotes} 
                            locationStates={locationStates} 
                        />}

                        {artdata && artdata.isAuxservice && <RightPhotoSection
                            productDetails={productDetails}
                            artdata={artdata}
                            checkoutdetails={checkoutdetails}
                            deliveryData={deliveryData}
                        />}
   
                    </div>
                </>
            )}
            <Footer />
        </>
    );
};

export default Payment;
