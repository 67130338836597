import React, { useState, useEffect } from 'react';
import Rating from '@mui/material/Rating';
import ReviewModal from './ReviewModal';
import { createReview } from 'redux/review/action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useNavigate, useParams } from 'react-router-dom';
import reviewService from 'services/reviewService';
import timeSince from 'utils/date/timeSince';
import ReviewSuccessModal from 'components/common/Modal/ReviewSuccessModal';
import moment from 'moment';
import { Rate } from 'antd';
import { Dots } from 'components/common/Loader';
import emptyImage from 'assets/img/emptyImage.png';

export default function ProductReviews({ productName, detailid, productDetails }: any) {
    // const [count, setcount] = useState<number>(5);
    // const [value, setValue] = useState<number | null>(5);
    const [show, setShow] = useState(false);
    const { user } = useSelector((state: RootState) => state.user);
    const dispatch: any = useDispatch();
    const [comment, setcomment] = useState('');
    const [commentSlice, setcommentSlice] = useState(2);
    const [ratingReasonID, setratingReasonID] = useState<number | string>('0');
    const { detailsID } = useParams();
    const [rating, setRating] = React.useState<number | null>(null);
    const [sortComment, setsortComment] = useState('Newest');
    const [apiComments, setapiComments] = useState([]);
    const [replies, setreplies] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [openModal, setopenModal] = useState(false);
    const [replyloading, setreplyloading] = useState(false);
    const [selectedReply, setselectedReply] = useState('');
    const [selectedReply2, setselectedReply2] = useState('');
    const [replyValue, setreplyValue] = useState('');

    const navigate = useNavigate();
    const pathname = window.location.pathname;

    const reasons = [
        {
            ratingReasonID: 6,
            reason: 'Poor service',
        },
        {
            ratingReasonID: 7,
            reason: 'Unclean environment',
        },
        {
            ratingReasonID: 8,
            reason: 'Non responsive hosts',
        },
        {
            ratingReasonID: 9,
            reason: 'Incomplete Experience',
        },
        {
            ratingReasonID: 10,
            reason: 'Not to expectations',
        },
    ];

    const handleAddReview = async () => {
        if (!rating) return;
        setisLoading(true);
        await dispatch(
            createReview({
                userID: user.userID,
                comment,
                ratingReasonID,
                productDetailsID: detailsID,
                rating: rating ? (rating as number) : 0,
            })
        );

        let date = new Date().toJSON();
        let selectedReason = reasons.find((r) => r.ratingReasonID.toString() === ratingReasonID.toString());
        let reasonData = {
            ratingReasonID: ratingReasonID,
            reason: selectedReason?.reason,
            isActive: true,
            dateCreated: date,
            dateDeactivated: date,
        };

        if (rating !== 0 && rating <= 3 && reasonData.reason) {
            await reviewService.createRatingReason(reasonData);
        }
        setisLoading(false);
        setShow(false);
        setopenModal(true);
        fetchApiComments();

        setRating(0);
        setcomment('');
        setratingReasonID('');
    };

    const fetchApiComments = async () => {
        try {
            const res = await reviewService.getReviewsByProductDetailsID(detailsID || detailid);
            setapiComments(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleReply = async (e: any) => {
        e.preventDefault();
        try {
            if (!replyValue) return;
            const res = await reviewService.replyReview({
                reviewID: selectedReply,
                message: replyValue,
            });
            // setapiComments(res.data);
            handleReviewReply(selectedReply);
            setreplyValue('');
            setselectedReply('');
        } catch (error) {
            // console.log(error);
        }
    };

    const handleReviewReply = async (id: any) => {
        // window.scrollTo(0,0)
        setselectedReply2(id);
        setreplyloading(true);
        try {
            const res = await reviewService.getReviewReply(id);
            setreplies(res.data?.reviewReplyModels || []);
            setreplyloading(false);
        } catch (err: any) {
            setreplyloading(false);
            console.log(err);
        }
    };

    const convertDate = (params: Date) => {
        const x = new Date(params);
        return x.toLocaleString();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        let isCancelled = false;
        if (!isCancelled) {
            fetchApiComments();
            // alert(pathname)
        }
        return () => {
            isCancelled = true;
        };
    }, []);

    return (
        <>
            <ReviewModal
                rating={rating}
                setRating={setRating}
                openModal={show}
                setOpenModal={setShow}
                comment={comment}
                setcomment={setcomment}
                ratingReasonID={ratingReasonID}
                setratingReasonID={setratingReasonID}
                handleAddReview={handleAddReview}
                isLoading={isLoading}
            />
            <ReviewSuccessModal openModal={openModal} setOpenModal={setopenModal} />
            <div className="md:px-32 px-4 md:py-20 py-6">
                <div className="flex flex-col sm:items-center justify-between gap-3 mt-4 md:flex-row w-full">
                    <div className="hidden">
                        <div className="flex space-x-1">
                            <button
                                className="bg-auraPrimary text-white px-3 py-2 rounded"
                                onClick={() => {
                                    if (!user.firstName) return navigate(`/login?redirect=${pathname}`);
                                    setShow(true);
                                }}
                            >
                                Add a review
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-8 w-full">
                    <div className="md:w-[55%] w-full">
                        <div className="flex flex-wrap items-center justify-between mt-6">
                            <div>
                                <p className="text-3xl font-semibold text-black">{(apiComments || []).length} Reviews</p>
                                <p className="flex items-center">
                                    Avg. {productDetails?.productDetails?.rating}.0 &nbsp; &nbsp;{' '}
                                    <Rating
                                        name="read-only"
                                        className="!text-[20px]"
                                        value={productDetails?.productDetails?.rating}
                                        readOnly
                                    />
                                </p>
                            </div>
                            {/* <div>
                            <form action="" className="bg-gray-100 rounded-md ">
                                <select
                                    id=""
                                    className="flex items-center gap-2 px-3 py-2 text-sm font-normal capitalize border-none "
                                    value={sortComment}
                                    onChange={(e) => setsortComment(e.target.value)}
                                >
                                    <option>Newest </option>
                                    <option>Oldest</option>
                                </select>
                            </form>
                        </div> */}
                        </div>
                        <div className="py-6">
                            {(apiComments || [])
                                .sort((a: any, b: any) => {
                                    return sortComment === 'Newest'
                                        ? (new Date(b.dateCreated) as any) - (new Date(a.dateCreated) as any)
                                        : (new Date(a.dateCreated) as any) - (new Date(b.dateCreated) as any);
                                })
                                .slice(0, commentSlice)
                                .map((data: any, index) => (
                                    <div className="flex flex-row items-start mb-2">
                                        <div className="flex ">
                                            <img src={data.picture || emptyImage} className="rounded-full w-8 h-8" alt="" />
                                        </div>

                                        <div className="w-full px-3" key={index}>
                                            <div className="flex flex-col">
                                                <h5 className="mb-1 font-medium text-neutral06">
                                                    {data.firstName + ' ' + data.lastName}
                                                </h5>

                                                <Rating name="read-only" className="!text-[14px] mb-2" value={data.rating} readOnly />
                                            </div>
                                            <p className="text-neutral06">{data.comment}</p>
                                            <div className="flex items-center mt-2 space-x-4">
                                                <p className="text-xs text-neutral">
                                                    {convertDate(data.dateCreated)}
                                                    {/* about {timeSince(new Date(data.dateCreated))} ago */}
                                                </p>
                                                {/* <button type="button" className="text-xs font-semibold">
                                                Like
                                            </button> */}
                                                <button
                                                    type="button"
                                                    onClick={() => setselectedReply(data.reviewID)}
                                                    className="text-xs font-semibold"
                                                >
                                                    Reply
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => handleReviewReply(data.reviewID)}
                                                    className="text-xs font-semibold text-auraPrimary"
                                                >
                                                    View Replies
                                                </button>
                                            </div>

                                            {selectedReply2 === data.reviewID && (
                                                <div className="bg-white my-1">
                                                    {replyloading ? (
                                                        <div className="bg-white p-2 text-center ">fetching replies...</div>
                                                    ) : (
                                                        <>
                                                            {replies && replies.length > 0 ? (
                                                                <>
                                                                    {replies.map((reply: any) => {
                                                                        return (
                                                                            <>
                                                                                <div className="p-2 ">
                                                                                    <h5 className="font-medium text-neutral06">
                                                                                        {reply.createdBy}
                                                                                    </h5>
                                                                                    <p className="text-neutral06">
                                                                                        {reply.message}
                                                                                    </p>
                                                                                    <p className="text-xs text-neutral">
                                                                                        {convertDate(reply.dateCreated)}
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <div className="bg-white p-2 text-center ">
                                                                    No replies available yet
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}

                                            {selectedReply === data.reviewID && (
                                                <div className="mt-4 replyBox" id="">
                                                    <form onSubmit={handleReply}>
                                                        <div className="relative flex gap-2">
                                                            <input
                                                                type="text"
                                                                id="input-group-1"
                                                                className=" border outline-0 shadow-sm py-1 h-8 mb-1 placeholder:text-sm border-orange-300 focus:border-auraPrimary100 w-[400px]"
                                                                value={replyValue}
                                                                onChange={(e) => setreplyValue(e.target.value)}
                                                                placeholder="Enter response here"
                                                            />
                                                            <div className="">
                                                                <button
                                                                    type="submit"
                                                                    className="h-8 px-3 bg-auraPrimary text-white"
                                                                >
                                                                    Send
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            <div className="flex items-center gap-4 my-6">
                                <button
                                    className="flex items-center gap-3 px-3 py-2 font-semibold shadow-sm capitalize border rounded-3xl"
                                    onClick={() => setcommentSlice(commentSlice + 2)}
                                >
                                    Load more
                                </button>
                                <button
                                    className="flex items-center gap-3 px-3 py-2 font-semibold bg-gray-200 capitalize rounded-3xl"
                                    onClick={() => setcommentSlice(2)}
                                >
                                    Show less
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="md:w-[45%] w-full">
                        <div className='flex flex-col items-center'>
                        <p className="text-xl font-medium text-black mt-6">Leave a review</p>

                        <Rate
                            value={rating || 0}
                            className="text-[18px] text-[#FAAF00]"
                            onChange={(value) => {
                                setRating(value);
                            }}
                        />
                        </div>

                        <form className=" w-full md:min-w-[24rem]">
                            {(!rating || rating <= 3) && (
                                <div className="p-3 my-3 border rounded">
                                    {reasons.map((data, index) => (
                                        <div className="flex items-center mb-4 space-x-3" key={index}>
                                            <input
                                                type="radio"
                                                value={data.ratingReasonID}
                                                checked={data.ratingReasonID.toString() === ratingReasonID.toString()}
                                                onChange={(e) => setratingReasonID(e.currentTarget.value)}
                                                name="default-radio"
                                                className="w-4 h-4 text-auraPrimary  bg-gray-100 border-gray-300 "
                                            />
                                            <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 ">
                                                {data.reason}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className="my-6">
                                <label htmlFor="reason" className="block text-left">
                                    Additional reviews (optional)
                                </label>
                                <textarea
                                    name=""
                                    id=""
                                    value={comment}
                                    onChange={(e) => setcomment(e.target.value)}
                                    rows={4}
                                    className="mt-1 form-control"
                                    placeholder=""
                                ></textarea>
                            </div>

                            <div className="mt-10 flex items-center justify-center">
                                <button
                                    type="button"
                                    disabled={!rating ? true : false}
                                    onClick={()=> {
                                        if (!user.firstName) return navigate(`/login?redirect=${pathname}`);
                                        handleAddReview()
                                    }}
                                    className="block w-[30%] px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-auraPrimary my-btn disabled:bg-orange-300 disabled:cursor-not-allowed"
                                >
                                    {isLoading ? <Dots /> : 'Review'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
