import service from 'services/fetchInterceptor';
import { helperFeaturedRecommended } from './helpers';
import {
    CountryData,
    StateData,
    GenderData,
    LanguagesData,
    ServiceType,
    SearchFilterData,
    CreateWishlist,
    FilterProducts,
    NewSearchFilterProducts,
} from './types';

const dataService: ServiceType | any = {};

dataService.getCountries = (data: CountryData): Promise<any> =>
    service({
        url: `/v1/AppData/GetCountries?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
        method: 'get',
        headers: {
            'public-request': 'true',
        },
    });

dataService.getStates = (data: StateData): Promise<any> =>
    service({
        url: `/v1/AppData/GetStates?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&CountryId=${data.countryId}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

// dataService.getUserProfile = (data: StateData): Promise<any> =>
// service({
//   url: `/v1/uer/profile`,
//   method: 'get',
//   headers: { 'public-request': 'true' },
// });

dataService.createBooking = (data: any): Promise<any> =>
    service({
        url: '/ProductDetails/product/details',
        method: 'post',
        data,
    });
dataService.createAmenitiesStandout = (data: any): Promise<any> =>
    service({
        url: '/ProductDetailsAmenities/product-details/amenities',
        method: 'post',
        data,
    });

dataService.createPrice = (data: any): Promise<any> =>
    service({
        url: '/Service/service/bulk',
        method: 'post',
        data,
    });

dataService.createPhotographyPrice = (data: any): Promise<any> =>
    service({
        url: '/Service/service/photography',
        method: 'post',
        data,
    });

dataService.createArtworkPrice = (data: any): Promise<any> =>
    service({
        url: '/Service/service/artwork',
        method: 'post',
        data,
    });

dataService.createPricing = (data: any): Promise<any> =>
    service({
        url: '/ProductDetails/product/details/update/complete',
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.createPicture = (data: any): Promise<any> =>
    service({
        url: '/ProductDetailPictures/product/pictures',
        method: 'post',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
dataService.UpdateDetails = (data: any): Promise<any> =>
    service({
        url: '/ProductDetails/product/details/update/complete',
        method: 'patch',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.UpdateExperienceDetails = (data: any): Promise<any> =>
    service({
        url: '/ProductDetails/product/details/update/experience/complete',
        method: 'patch',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.getVerifiedHotels = (verified: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/${verified}?ProductId=29&PageNumber=1&PageSize=10`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getVerifiedApartment = (verified: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/${verified}?ProductId=30&PageNumber=1&PageSize=10`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getVerifiedExperience = (verified: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/${verified}?ProductId=33&PageNumber=1&PageSize=10`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getVerifiedWorkspace = (verified: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/${verified}?ProductId=34&PageNumber=1&PageSize=10`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getVerifiedPhotography = (verified: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/${verified}?ProductId=32&PageNumber=1&PageSize=10`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getVerifiedRestaurant = (verified: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/${verified}?ProductId=31&PageNumber=1&PageSize=10`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getSingleDeals = (id: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/complete/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getUnverifiedHotels = (
    status: any,
    search: any,
    pageNumber: number,
    pageSize: number,
    resubmitted: any
): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=29&PageNumber=${pageNumber}&PageSize=${pageSize}&ProcessingStatus=${status}&status=${resubmitted}&searchKey=${search}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getUnverifiedExperience = (
    status: any,
    search: any,
    pageNumber: number,
    pageSize: number,
    resubmitted: any
): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=33&PageNumber=${pageNumber}&PageSize=${pageSize}&ProcessingStatus=${status}&status=${resubmitted}&searchKey=${search}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getUnverifiedWorkspace = (
    status: any,
    search: any,
    pageNumber: number,
    pageSize: number,
    resubmitted: any
): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=34&PageNumber=${pageNumber}&PageSize=${pageSize}&ProcessingStatus=${status}&status=${resubmitted}&searchKey=${search}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getUnverifiedPhotography = (
    status: any,
    search: any,
    pageNumber: number,
    pageSize: number,
    resubmitted: any
 ): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=32&PageNumber=${pageNumber}&PageSize=${pageSize}&ProcessingStatus=${status}&status=${resubmitted}&searchKey=${search}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });


dataService.getUnverifiedRestaurant = (
    status: any,
    search: any,
    pageNumber: number,
    pageSize: number,
    resubmitted: any
 ): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=31&PageNumber=${pageNumber}&PageSize=${pageSize}&ProcessingStatus=${status}&status=${resubmitted}&searchKey=${search}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });


dataService.getUnverifiedApartment = (
    status: any,
    search: any,
    pageNumber: number,
    pageSize: number,
    resubmitted: any
): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=30&PageNumber=${pageNumber}&PageSize=${pageSize}&ProcessingStatus=${status}&status=${resubmitted}&searchKey=${search}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getReservationBooking = (
    data: any,
    search: any,
    checkInOut: any,
    ProductID: any,
    pageNumber: number,
    pageSize: number
): Promise<any> =>
    service({
        url: `/v1/Booking/${data}?ProductID=${ProductID}&sortBy=${checkInOut}&searchKey=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getAllReservationRequest = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-all-reservation-request`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.ApproveReservation = (id: any): Promise<any> => {
    return service({
        url: `v1/Booking/approve-booking/${id}`,
        method: 'put',
    });
};
dataService.RejectedReservation = (data: any): Promise<any> => {
    return service({
        url: `v1/Booking/reject-booking`,
        method: 'put',
        data,
    });
};

dataService.CancelReservation = (data: any): Promise<any> => {
    return service({
        url: `v1/Booking/user-cancel-booking`,
        method: 'put',
        data,
    });
};

dataService.getLGAs = (stateID: number): Promise<any> =>
    service({
        url: `/v1/AppData/GetLGAs?StateId=${stateID}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getGender = (data: GenderData): Promise<any> =>
    service({
        url: `/v1/AppData/GetGenders?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getLanguages = (data: LanguagesData): Promise<any> =>
    service({
        url: `/v1/AppData/GetLanguages?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getActiveProducts = (): Promise<any> =>
    service({
        url: `Products/products/active`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getAllProductTypes = (): Promise<any> =>
    service({
        url: `Products/products`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getProductCategoryById = (productId: number): Promise<any> =>
    service({
        url: `ProductCategories/product/${productId}/categories`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getProductAmenities = (): Promise<any> =>
    service({
        url: `/ProductAmenities/product/amenities`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getProductAmenitiesByID = (productId: number): Promise<any> =>
    service({
        url: `/ProductAmenities/product/${productId}/amenities`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getSavedAmenitiesByID = (productId: number, productDetailsID: number): Promise<any> =>
    service({
        url: `/v1/ProductDetailsAmenities/product/${productId}/amenities?productDetailsID=${productDetailsID}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.deleteSavedProductAmenitiesByID = (amenity: number): Promise<any> =>
    service({
        url: `/v1/ProductDetailsAmenities/product/details/amenities/deleteByAmenity/${amenity}`,
        method: 'delete',
        headers: { 'public-request': 'true' },
    });

dataService.deleteSavedStandOutServiceAmenitiesByID = (standOutID: number): Promise<any> =>
    service({
        url: `/Service/services/standout/delete//${standOutID}`,
        method: 'delete',
        headers: { 'public-request': 'true' },
    });

dataService.getSavedServicesByID = (productDetailsID: number): Promise<any> =>
    service({
        url: `/Service/services/product/details/mobile/${productDetailsID}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.deleteSavedServicesByID = (serviceID: number): Promise<any> =>
    service({
        url: `/Service/services/${serviceID}`,
        method: 'delete',
        headers: { 'public-request': 'true' },
    });

dataService.deleteProductPictureByID = (ProductPictureId: number): Promise<any> =>
    service({
        url: `/ProductDetailPictures/product/pictures/${ProductPictureId}`,
        method: 'delete',
        headers: { 'public-request': 'true' },
    });

dataService.getProductPictureByID = (id: number): Promise<any> =>
    service({
        url: `/ProductDetailPictures/product/details/pictures/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getBasicAmenities = (id: number): Promise<any> =>
    service({
        url: `/ProductAmenities/product/amenities/standout/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getStandoutAmenities = (): Promise<any> =>
    service({
        url: `/ProductAmenities/product/34/amenities`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getServicesByDetailsId = (detailsId: number): Promise<any> =>
    service({
        url: `/Service/services/product/details/${detailsId}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getHotelsApartmentsAndExperiences = (data: SearchFilterData): Promise<any> =>
    service({
        url: `/Homepage/product/details/query?ProductId=${data.productId}&CheckIn=${data.checkIn}&CheckOut=${data.checkOut}&Longitude=${data.longitude}&Latitude=${data.latitude}&NumberOfGuest=${data.numberOfGuest}&Page=${data.page}&Size=${data.size}&Sort=${data.sort}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getSearchFilter = (data: SearchFilterData): Promise<any> =>
    service({
        url: `/Homepage/product/details/query?ProductId=${data.productId}&CheckIn=${data.checkIn}&CheckOut=${data.checkOut}&Longitude=${data.longitude}&Latitude=${data.latitude}&NumberOfGuest=${data.numberOfGuest}&Page=${data.page}&Size=${data.size}&Sort=${data.sort}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getApartments = (
    productId: number,
    checkIn: string,
    checkOut: string,
    longitude: number,
    latitude: number,
    numberOfGuest: number,
    page: number,
    size: number,
    sort: number
): Promise<any> =>
    service({
        url: `/Homepage/product/details/query?ProductId=${productId}&CheckIn=${checkIn}&CheckOut=${checkOut}&Longitude=${longitude}&Latitude=${latitude}&NumberOfGuest=${numberOfGuest}&Page=${page}&Size=${size}&Sort=${sort}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getDeals = (data: FilterProducts, pageNumber: number, pageSize: number): Promise<any> => {
    const { search } = data;
    return service({
        url: `Homepage/homepage/deal-of-day/active?${search ? `SearchVal=${search}&` : ''}PageNumber=${
            pageNumber || 1
        }&PageSize=${pageSize || 12}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });
};

dataService.filterProducts = (data: NewSearchFilterProducts, pageNumber: number, pageSize: number): Promise<any> =>
    service({
        url: `Homepage/product/details/query/filter?PageNumber=${pageNumber ? pageNumber : 1}&PageSize=${pageSize || 12}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.headerFilterProducts = (data: NewSearchFilterProducts, pageNumber: number, pageSize: number): Promise<any> =>
    service({
        url: `Homepage/product/details/query/list/filter?PageNumber=${pageNumber ? pageNumber : 1}&PageSize=${pageSize || 12}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getRecommendedProducts = (data: FilterProducts, pageNumber: number, pageSize: number): Promise<any> =>
    service({
        url: `Homepage/product/details/recommeded${helperFeaturedRecommended(data)}&PageNumber=${pageNumber ? pageNumber : 1}&PageSize=${pageSize || 12}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getFeaturedProducts = (data: FilterProducts, pageNumber: number, pageSize: number): Promise<any> =>
    service({
        url: `Homepage/product/details/popular${helperFeaturedRecommended(data)}&PageNumber=${pageNumber ? pageNumber : 1}&PageSize=${pageSize || 12}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });
dataService.getPartnersProducts = (data: FilterProducts, pageNumber: number, pageSize: number): Promise<any> =>
    service({
        url: `v1/PartnerProduct/GetAllPartnerProducts${helperFeaturedRecommended(data)}&PageNumber=${pageNumber ? pageNumber : 1}&PageSize=${pageSize || 12}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.verifyEmail = (email: string, code: string): Promise<any> =>
    service({
        url: `v1/Account/ValidateUserEmail?emailAddress=${email}&validationCode=${code}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.resendVerifyEmail = (email: string): Promise<any> =>
    service({
        url: `v1/Account/ResendEmailValidationCode?emailAddress=${email}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

// dataService.getAllWishlist = (): Promise<any> =>
//   service({
//     url: `Wishlist/wishlists/all`,
//     method: 'get',
//     headers: { 'public-request': 'true' },
//   });

dataService.createWishlist = (data: CreateWishlist): Promise<any> =>
    service({
        url: `Wishlist/wishlist/`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.deleteWishlistByID = (id: number | string): Promise<any> =>
    service({
        url: `Wishlist/wishlist/${id}`,
        method: 'delete',
        headers: { 'public-request': 'true' },
    });

dataService.getWishlistByUserID = (userID: number | string): Promise<any> =>
    service({
        url: `Wishlist/wishlist/product/${userID}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getReservations = (): Promise<any> =>
    service({
        url: `v1/Booking/get-bookings`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getPendingReservations = (): Promise<any> =>
    service({
        url: `v1/Booking/get-all-pending-bookings`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getMonthlyEarnings = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-host-vendor-monthly-earnings`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getWeeklyEarnings = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-host-vendor-weekly-earnings`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getDueAmount = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-due-amount`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getTotalDisbursement = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-total-disbursment`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getTotalProperties = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-total-properties`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getUserVisitTotal = (hostVendorId: number): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-user-visit-total-by-host/${hostVendorId}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getBookingCheckedInTotal = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-booking-checkIn-total-all`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getTotalReservation = (): Promise<any> =>
    service({
        url: `/HostVendorDashboard/get-total-reservation`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getConcludedReservations = (): Promise<any> =>
    service({
        url: `v1/Booking/get-all-concluded-bookings`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getOngoingReservations = (): Promise<any> =>
    service({
        url: `v1/Booking/get-all-ongoing-bookings`,
        // url: `v1/Booking/total-pending-booking`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.approveBookingRequest = (bookingId: any): Promise<any> =>
    service({
        url: `v1/Booking/approve-booking/${bookingId}`,
        method: 'put',
        headers: { 'public-request': 'true' },
    });

dataService.createDeviceToken = (data: { email: string; deviceToken: string }): Promise<any> =>
    service({
        url: '/v1/DeviceToken/create-device-token',
        method: 'post',
        data,
    });

dataService.getPublishedProduct = (id: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/published/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getProductByDetailsId = (id: number): Promise<any> =>
    service({
        url: `ProductDetails/product/details/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getReviewsByProductDetailsID = (id: number): Promise<any> =>
    service({
        url: `v1/Review/get-review-product-details-by-id/${id}`,
        method: 'get',
    });

dataService.deleteReviewsByReviewId = (id: number): Promise<any> =>
    service({
        url: `v1/Review/delete-review-by-id/${id}`,
        method: 'delete',
    });

dataService.replyReviewsByReviewId = (data: any): Promise<any> =>
    service({
        url: `v1/Review/reply-review`,
        method: 'post',
        data,
    });

dataService.getVerifiedProducts = (id: number): Promise<any> =>
    service({
        url: `ProductDetails/product/details/verified?ProductId=${id}&PageNumber=1&PageSize=1000`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getPromotionVisibility = (): Promise<any> =>
    service({
        url: `v1/Advert/GetServiceSpotlightPricing`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getBankDetails = (id: number): Promise<any> =>
    service({
        url: `v1/HostVendorBankAccount/GetByHostVendorIdHostVendorBankAccount?HostVendorId=${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getOngoingBookingByHostVendorID = (
    vendorId: number,
    productID: number,
    search: any,
    pageNumber: number,
    pageSize: number
): Promise<any> =>
    service({
        url: `v1/Booking/get-all-ongoing-booking-host-vendor-id/${vendorId}?ProductID=${productID}&searchKey=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getAllRepliesByReviewID = (id: number): Promise<any> =>
    service({
        url: `v1/Review/get-review-by-id/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getAllPromotionByID = (PageNumber: number, PageSize: number, search: any, HostVendorId: number, id: number): Promise<any> =>
    service({
        url: `v1/Advert/GetServiceSportlightByHostListProduct?PageNumber=${PageNumber}&PageSize=${PageSize}&${search ? `SearchVal=${search}` : '' }&HostVendorId=${HostVendorId}&productId=${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

 dataService.addHostVendorBankAccount = (data: any): Promise<any> =>
    service({
        url: `v1/HostVendorBankAccount/CreateHostVendorBankAccount`,
        method: 'post',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.updateHostVendorBankAccount = (data: any): Promise<any> =>
    service({
        url: `v1/HostVendorBankAccount/UpdateHostVendorBankAccount`,
        method: 'put',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.getVisibilityPrice = (duration: any, visibility: any): Promise<any> =>
    service({
        url: `v1/Advert/GetServiceSpotlightPricingByDuration?Duration=${duration}&VisibilityId=${visibility}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.createDiscount = (data: any): Promise<any> =>
    service({
        url: `/Discount/discounts/discount`,
        method: 'post',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.getAllDealByID = (id: number, PageNumber: number, PageSize: number, search: any): Promise<any> =>
    service({
        url: `/Discount/discounts/get-all-discounts-by-host/${id}?PageNumber=${PageNumber}&PageSize=${PageSize}&SearchVal=${search}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getSingleDealOfDay = (id: number): Promise<any> =>
    service({
        url: `/Discount/discounts/discount/${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.updateDealOfDay = (data: any): Promise<any> =>
    service({
        url: `/Discount/discounts/discount/update/`,
        method: 'put',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.updateBookingType = (data: any): Promise<any> =>
    service({
        url: `/ProductDetails/change-booking-type/`,
        method: 'patch',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.updateLiveListing = (data: any): Promise<any> =>
    service({
        url: `/ProductDetails/change-live-listing/`,
        method: 'patch',
        headers: { 'public-request': 'true' },
        data,
    });

dataService.switchLoyalty= (): Promise<any> =>
    service({
        url: '/v1/Referral/SwitchLoyalty',
        method: 'post',
    });

  dataService.getLiveListingStatus = (id: number): Promise<any> =>
  service({
    url: `/ProductDetails/get-product-details-listing/${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  dataService.getPromotionDuration = (): Promise<any> =>
  service({
    url: `v1/Advert/GetDurations`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  dataService.getAllBanks = (): Promise<any> =>
  service({
    url: `/Payment/get-banks`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  dataService.getPromotedByHostVendorId = (id: number): Promise<any> =>
  service({
    url: `v1/Advert/GetServiceSportlightByHost?HostVendorId=${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });


  dataService.resendEmailValidation = (email: string): Promise<any> =>
  service({
    url: `v1/Account/ResendEmailValidationCode?emailAddress=${email}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });


  dataService.getAllQueriedComment = (id: number): Promise<any> =>
  service({
    url: `v1/Admin/GetProductDetailsQueryComment?ProductDetailsId=${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  dataService.resendPhoneValidation = (data: any): Promise<any> =>
   service({
    url: `v1/Account/ResendPhoneNumberValidationCode`,
    method: 'post',
    data,
    headers: { 'public-request': 'true' },
  });

  dataService.getAllDeclinedComment = (id: number): Promise<any> =>
  service({
    url: `v1/Admin/GetDeclinedProductDetails?ProductDetailsId=${id}`,
    method: 'get',
    headers: { 'public-request': 'true' },
  });

  dataService.PhoneValidation = (data: any): Promise<any> =>
  service({
    url: `v1/Account/ValidateUserPhoneNumber`,
    method: 'post',
    data,
    headers: { 'public-request': 'true' },
  });

dataService.getHotelStatusCount = (status: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=29&PageNumber=1&PageSize=10&ProcessingStatus=${status}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getHotelsPendingResubmittedCount = (status: any, resubmitted: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=29&PageNumber=1&PageSize=10&ProcessingStatus=${status}&status=${resubmitted}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getApartmentStatusCount = (status: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=30&PageNumber=1&PageSize=10&ProcessingStatus=${status}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });
    
dataService.getApartmentPendingResubmittedCount = (status: any, resubmitted: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=30&PageNumber=1&PageSize=10&ProcessingStatus=${status}&status=${resubmitted}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getExperienceStatusCount = (status: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=33&PageNumber=1&PageSize=10&ProcessingStatus=${status}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });
    
dataService.getExperiencePendingResubmittedCount = (status: any, resubmitted: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=30&PageNumber=1&PageSize=10&ProcessingStatus=${status}&status=${resubmitted}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getWorkspaceStatusCount = (status: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=30&PageNumber=1&PageSize=10&ProcessingStatus=${status}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });
    
dataService.getWorkspacePendingResubmittedCount = (status: any, resubmitted: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=30&PageNumber=1&PageSize=10&ProcessingStatus=${status}&status=${resubmitted}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getPhotographyStatusCount = (status: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=32&PageNumber=1&PageSize=10&ProcessingStatus=${status}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });
    
dataService.getPhotographyPendingResubmittedCount = (status: any, resubmitted: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=32&PageNumber=1&PageSize=10&ProcessingStatus=${status}&status=${resubmitted}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getRestaurantStatusCount = (status: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=31&PageNumber=1&PageSize=10&ProcessingStatus=${status}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });
    
dataService.getRestaurantPendingResubmittedCount = (status: any, resubmitted: any): Promise<any> =>
    service({
        url: `ProductDetails/product/details/unverified?ProductId=31&PageNumber=1&PageSize=10&ProcessingStatus=${status}&status=${resubmitted}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.uploadArtworkImage = (file: any): Promise<any> => {
    const url = `v1/Message/UploadImage`;
    return service.post(url, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };
dataService.getSavedPhotographyServicesByID = (productDetailsID: number, serviceType: string): Promise<any> =>
    service({
        url: `/Service/services/productdetails/photography/${productDetailsID}/${serviceType}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.getSavedArtworkServicesByID = (productDetailsID: number, serviceType: string): Promise<any> =>
    service({
        url: `/Service/services/productdetails/Artwork/${productDetailsID}/${serviceType}`,
        method: 'get',
        headers: { 'public-request': 'true' },
    });

dataService.createChatBotSession = (): Promise<any> =>
    service({
        url: `v1/ChatBot/CreateChatBotSession`,
        method: 'post',
        headers: { 'public-request': 'true' },
    });

dataService.sendChatWeb = (data: any): Promise<any> =>
    service({
        url: `v1/ChatBot/SendChatWeb`,
        method: 'post',
        headers: { 'public-request': 'true' },
        data
    });
dataService.getChatBotBySession = (id: any): Promise<any> =>
    service({
        url: `v1/ChatBot/GetChatBotBySession?SessionId=${id}`,
        method: 'get',
        headers: { 'public-request': 'true' },
        
    });

dataService.workspaceServiceUpdate = (data: any): Promise<any> =>
    service({
      url: `/Service/services/update/Single`,
      method: 'put',
      data,
      headers: { 'public-request': 'true' },
    });

dataService.photographyServiceUpdate = (data: any): Promise<any> =>
    service({
      url: `/Service/services/update/Single/photography`,
      method: 'put',
      data,
      headers: { 'public-request': 'true' },
    });

dataService.artworkServiceUpdate = (data: any): Promise<any> =>
    service({
      url: `/Service/services/update/Single/artwork`,
      method: 'put',
      data,
      headers: { 'public-request': 'true' },
    });

dataService.getAllOrder = (
        hostVendorId : any,
        productID: number,
        status: string,
        pageNumber: number,
        pageSize: number,
        search: string,
    ): Promise<any> =>
     service({
         url: `/v1/Order/get-order-history-by-host-vendorId/${hostVendorId}/${productID}/${status}?PageNumber=${pageNumber}&PageSize=${pageSize}&SearchVal=${search}`,
         method: 'get',
         headers: { 'public-request': 'true' },
     });

dataService.orderStatusDelivered = (data: any): Promise<any> =>
    service({
      url: `v1/Order/change-order-status-delivered`,
      method: 'put',
      data,
      headers: { 'public-request': 'true' },
    });

dataService.orderStatusApproved = (id: number): Promise<any> =>
    service({
      url: `v1/Order/approve-order?orderId=${id}`,
      method: 'put',
      headers: { 'public-request': 'true' },
    });

dataService.orderStatusReject = (data: any): Promise<any> =>
    service({
      url: `/v1/Order/reject-order`,
      method: 'put',
      data,
      headers: { 'public-request': 'true' },
    });

dataService.getOrderById = (id: any): Promise<any> =>
    service({
      url: `/v1/Order/get-orders-by-id/${id}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.getDeliveryDetailsById = (id: number, productId: number,): Promise<any> =>
    service({
      url: `/v1/DeliveryDetails/delivery/detailByVendorID/${id}/${productId}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });
    
dataService.getOrderHistoryById = (id: number): Promise<any> =>
    service({
      url: `/v1/Order/get-orders-complete-history-by-order-id/${id}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.createDeliveryCoverage = (data: any): Promise<any> =>
    service({
      url: `/v1/DeliveryDetails/delivery/details/single`,
      method: 'post',
      data,
      headers: { 'public-request': 'true' },
    });

dataService.deleteDeliveryCoverage = (id: number): Promise<any> =>
    service({
      url: `/v1/DeliveryDetails/delete/delivery/details/${id}`,
      method: 'delete',
      headers: { 'public-request': 'true' },
    });

dataService.headerFilterProductsRestaurant = (data: NewSearchFilterProducts, pageNumber: number, pageSize: number): Promise<any> =>
    service({
        url: `Homepage/product/details/query/filter/restaurant?PageNumber=${pageNumber ? pageNumber : 1}&PageSize=${pageSize || 12}`,
        method: 'post',
        data,
        headers: { 'public-request': 'true' },
    });

dataService.getUserDetails = (): Promise<any> =>
    service({
      url: `/v1/Account/GetUser`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });
    
dataService.getHostComments = (id: number): Promise<any> =>
    service({
      url: `/v1/Review/GetReviewListByHostVendorId?HostVendorId=${id}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.allowInstantBookingForAll = (id: number, data: boolean): Promise<any> =>
    service({
      url: `/v1/HostVendor/AllowInstantBookingForAllProduct?HostVendorId=${id}&BookingTypeStatus=${data}`,
      method: 'put',
      headers: { 'public-request': 'true' },
    });

dataService.availabilityToggle = (id: number, data: boolean): Promise<any> =>
    service({
      url: `/v1/HostVendor/AvailabilityToggle?HostVendorId=${id}&status=${data}`,
      method: 'put',
      headers: { 'public-request': 'true' },
    });

dataService.getAvailabilityStatus = (id: number): Promise<any> =>
    service({
      url: `/v1/HostVendor/GetHostAvailabilityStatus?HostVendorId=${id}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.getAllowInstantBookingForAll = (id: number): Promise<any> =>
    service({
      url: `/v1/HostVendor/GetHostInstantBookingStatus?HostVendorId=${id}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });
    
dataService.getAllCustomers = (search?: string): Promise<any> =>
    service({
      url: `/v1/HostVendor/GetAllVendorHosts?PageSize=1&SearchVal=${search}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.transferReferralBonus = (receiver?: string, amount?: number): Promise<any> =>
    service({
      url: `/v1/Referral/TransferReferralBonus?RecieveByUserEmail=${receiver}&AmountToTransafer=${amount}`,
      method: 'post',
      headers: { 'public-request': 'true' },
    });

dataService.getHomepagePartnerProducts = (): Promise<any> =>
    service({
      url: `/v1/PartnerProduct/GetAllPartnerProducts?productId=29&PartnerId=8&PageNumber=1&PageSize=12`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.fetchTransferBonus = (pageNumber: number, pageSize: number): Promise<any> =>
    service({
      url: `/v1/Referral/GetAllReferralBonusTransfered?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.fetchReceivedBonus = (pageNumber: number, pageSize: number): Promise<any> =>
    service({
      url: `/v1/Referral/GetAllReferralBonusRecieved?PageNumber=${pageNumber}&PageSize=${pageSize}`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });

dataService.endChatSession = (sessionId: string): Promise<any> =>
    service({
      url: `/v1/ChatBot/CloseChat?SessionId=${sessionId}`,
      method: 'post',
      headers: { 'public-request': 'true' },
    });

dataService.sendSurvey = (sessionId: string, surveyValue: number): Promise<any> =>
    service({
      url: `/v1/ChatBot/CreateChatBotSurvey?SessionId=${sessionId}&SurveyResponse=${surveyValue}`,
      method: 'post',
      headers: { 'public-request': 'true' },
    });
dataService.getAllPartnerClient = (): Promise<any> =>
    service({
      url: `/v1/PartnerClient/GetAllPartnerClientConfiguration`,
      method: 'get',
      headers: { 'public-request': 'true' },
    });
dataService.postMTNToken = (data: string): Promise<any> =>
    service({
      url: `/v1/Account/GetMtnAuthToken/ReceiveMtnAuthToken`,
      method: 'post',
      data,
      headers: { 'public-request': 'true' },
    });
export default dataService;
