import { Dispatch, Action } from '@reduxjs/toolkit';
import {
  loadingStart,
  loadingStopped,
  paymentInitialization,
  paymentVerification,
  paymentConfirmation,
} from './reducers';
import paymentService from 'services/paymentService';
import { InitializePaymentData } from 'services/paymentService/types';

export const initializePayment =
  (data: InitializePaymentData) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await paymentService.initializePayment(data);
      dispatch(paymentInitialization(res.data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const verifyPayment =
  (transactionID: number | string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await paymentService.verifyPayment(transactionID);
      dispatch(paymentVerification(res.data));
      // console.log(res.data);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };

export const confirmPayment =(data: {
    transactionID: number | string;
    bookingID: number | string;
  }) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(loadingStart());
      const res = await paymentService.confirmPayment(data);
      dispatch(paymentConfirmation(res));
      // console.log(res.data);
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };