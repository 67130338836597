import ReservationModal from 'components/common/Modal/ReservationModal';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
import homeIcon from 'assets/icon/home.svg';
import { IArtData } from 'pages/Photography/interface';
import { ICartCheckout } from 'services/auxService/interface';
import { formatPrice } from 'helpers';

interface stateprops {
    productDetails: any;
    artdata: IArtData;
    checkoutdetails: ICartCheckout;
    deliveryData: {
        id: number;
        type: string;
        address: string;
    }
}

export default function RightPhotoSection({ productDetails, artdata, checkoutdetails, deliveryData }: stateprops) {
    const [modal, setModal] = useState(false);
    const { detailsID } = useParams();

    const [searchParams] = useSearchParams();
    const detailID = searchParams.get('detailID');

    function computeTotalAmount() {
        const value = checkoutdetails?.cartViewModels?.reduce((acc, item) => acc + (item.amount), 0);
        // setTotalamount(value);
        return value;
    }

console.log('checkoutdetails', checkoutdetails)
    return (
        <div className="bg-white checkout-box space-y-6 p-6 border rounded-3xl">
            {modal && <ReservationModal modal={modal} setModal={setModal} />}

            <div className=" h-auto checkout-box space-y-4">

                <div className="flex items-start space-x-6">
                    {/* <div className="grid grid-cols-2 gap-4"> */}
                    <div className="w-max h-full">
                        <img
                            src={
                                productDetails?.productPicture || productDetails?.servicePicture
                                    ? productDetails?.productPicture || productDetails?.servicePicture
                                    : productDetails?.pictures?.length > 0
                                        ? productDetails?.pictures[0]
                                        : ''
                            }
                            className="rounded-2xl w-32 h-36 object-cover"
                            alt="product"
                        />
                    </div>
                    <div className="w-60 h-36">
                        <div className="h-full flex flex-col justify-between space-y-4">
                            <div className="pb-4 border-b space-y-3">
                                <h6 className=" text-base font-medium">
                                    {productDetails?.productDetails?.productName || productDetails?.productName
                                        ? productDetails?.productDetails?.productName || productDetails?.productName
                                        : productDetails?.activityCalendar?.title
                                    }

                                    {/* bookingRouteFrom.toLowerCase().includes('profile')
                                    ? bookingDetailsById?.productName */}
                                </h6>

                                <p className="text-neutral108 font-normal text-base">
                                    <span className="pr-2">Hosted by</span>
                                    <span className="text-black font-normal">{productDetails?.parentCompany}</span>
                                </p>
                                <div className="flex items-center gap-2 text-neutral108 capitalize text-xs font-light">

                                    <>
                                        <img src={homeIcon} alt="" />
                                        <p>{productDetails?.productName}</p>
                                    </>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <img src="../../img/icon/rate.svg" alt="" />
                                <p className="ml-1 font-semibold tx-sm">
                                    {productDetails?.rating > 0
                                        ? `${productDetails?.rating} ratings`
                                        : 'No rating'}
                                    <span className="ml-2 font-light text-neutral">
                                        (
                                        {productDetails?.noOfReviews > 0
                                            ? `${productDetails?.noOfReviews} reviews`
                                            : 'No review'}
                                        )
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-neutral107 rounded-2xl p-4 space-y-8 lg:w-full">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3 ">
                            <div><img src="../../img/icon/useer.svg" alt="" /></div>
                            <div>
                                <p className="text-xs font-light text-neutral ">Guest</p>
                                <p className="text-black text-base font-normal">
                                    {artdata.details.firstName + " " + artdata.details.lastName}
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center  space-x-3 ">
                            <div><img src="../../img/Combined-Shape.svg" alt="" /></div>
                            <div>
                                <p className="text-xs font-light text-neutral ">Call Host</p>
                                <p className="text-black text-base font-normal">{artdata.details.phoneNumber} </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        {deliveryData.address && <div className="flex items-center space-x-3 ">
                            <div><img src="/img/Group 13388.svg" alt="" /></div>
                            <div>
                                <p className="text-xs font-light text-neutral ">Pickup Location</p>
                                <p className="text-black text-base font-normal">
                                    {deliveryData.address}
                                </p>
                            </div>
                        </div>}

                        {/* <div className="flex items-center space-x-3 ">
                            <div><img src="../../img/icon/calendar.svg" alt="" /></div>
                            <div>
                                <p className="text-xs font-light text-neutral ">Check-in</p>
                                <p className="text-black text-base font-normal">Mar 25, 2022</p>
                            </div>
                        </div> */}
                    </div>

                </div>
                <div className="space-y-4">

                    <div className=" flex items-center text-xs text-gray-500 font-normal border-b  justify-between p-3">
                        <p className="uppercase">{productDetails.productID === 31 ? "Food Menu" : "Artwork" }  details </p>
                        <p className="text-end">Amount</p>
                    </div>

                    {
                        checkoutdetails?.cartViewModels?.length > 0 && checkoutdetails?.cartViewModels?.map(item => {
                            return <>
                                <div className=" grid grid-cols-2 items-center text-xs font-normal border-b  justify-center p-3">
                                    <p className="uppercase 4">{item.services.serviceName} - {artdata.type === "food" || item.quantity ? item.quantity+" qty" : ""}</p>
                                    <p className="text-end">N{formatPrice(item.services.price)}</p>
                                </div>
                            </>
                        })
                    }

                    <div className="bg-gray-50 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                        <p className="uppercase">Sub Total</p>
                        <p className="text-end">N{formatPrice(checkoutdetails?.subAmount || computeTotalAmount() || 0)}</p>
                    </div>

                    {
                        checkoutdetails?.deliveryFee > -1 && <>
                            <div className="bg-gray-50 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                <p className="uppercase">delivery fee</p>
                                <p className="text-end">
                                    N{formatPrice(checkoutdetails?.deliveryFee)}
                                </p>
                            </div>
                        </>
                    }

                    {
                        checkoutdetails?.commission && checkoutdetails?.commission > 0 && <>
                            <div className="bg-gray-50 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                <p className="uppercase">Service charge</p>
                                <p className="text-end">
                                    N{formatPrice(checkoutdetails?.commission)}
                                </p>
                            </div>
                        </>
                    }

                    {
                        checkoutdetails?.vatAmount && <>
                            <div className="bg-gray-50 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                <p className="uppercase">vat</p>
                                <p className="text-end">N{formatPrice(checkoutdetails?.vatAmount ? checkoutdetails?.vatAmount : 0)}</p>
                            </div>
                        </>
                    }

                    {checkoutdetails?.totalAmount && <div className="bg-gray-100 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                        <p className="uppercase">total</p>
                        <p className="text-end">N{formatPrice(checkoutdetails?.totalAmount ? checkoutdetails?.totalAmount : 0)}</p>
                    </div>}
                </div>

                <div className=" text-xs text-center flex justify-center items-center text-neutral">
                    <img src="../../img/icon/free.svg" className='h-4 mr-1' alt="" />
                    <a href="#dssd">Availability Status: <span className="text-[#16B94F]">Available</span></a>
                </div>

            </div>

        </div>
    )
}
