import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  useToast,
} from 'components/common/Toast/ToastProvider';
import { Modal, Button, Input } from 'components/common';
import Dot from 'components/common/Loader/Dot';
import authService from 'services/authService';



interface ChangePasswordProps {
  openModal?: boolean;
  setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

const ChangePassword: FC<ChangePasswordProps> = ({
  openModal = true,
  setOpenModal,
}) => {

  const [isLoading, setLoading ] = useState(false)
  const toast = useToast();

  const navigate = useNavigate();

  const changePasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .trim()
      .required('Email is required'),
  });

  const handleSubmit =async  (values : any) => {
    setLoading(true)
    try{
      const res = await authService.forgetPassword(values)
      setLoading(false)
      if(res.responseCode == '00'){
        toast?.success('Reset mail sent successfully.');
        if (setOpenModal){
          setOpenModal(false)
        }
        else navigate('/reset-password')
      }
      else throw Error;
    }
    catch(error: any ){
      toast?.error(error?.response?.data?.responseMessage)
      setLoading(false)
    }
  }

  return (
    <Modal
      openModal={openModal as boolean}
      setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
      className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px]"
      onOutsideClick={() => {}}
      onModalClose={
        setOpenModal ? () => setOpenModal(false) : () => navigate('/')
      }
    >
      <h3 className="text-2xl font-semibold leading-10 text-center">
        {/* Continue the <span className="text-auraPrimary">aura</span> experience */}
        Forgot Password
      </h3>
      <p className="text-center font-normal text-sm text-gray-4 mt-4">
        Enter your email address to request password reset.
      </p>

      <Formik
        initialValues={{ email: '',  }}
        validationSchema={changePasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { email } = values;
          handleSubmit(email)
        }}
      >
        {({ values, errors, touched }) => (
          <Form className="w-full">
            <Input
              className="w-full mt-6"
              name="email"
              type="text"
              label="Email Address"
              error={errors.email}
              touched={touched.email}
            />

            <div className="w-full mt-6">
              <Button
                type="submit"
                variant="primary"
                className="w-full h-14 text-base"
              >
                {isLoading ? <Dot /> : <span>Send Reset Mail</span>}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
