import { FC, InputHTMLAttributes, useState } from 'react';
import { ErrorMessage, Field } from 'formik';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  disabled?: boolean;
  value?: any;
  touched?: boolean;
  name?: string;
  placeholder?: string;
  error?: any;
  label?: string;
  inputProps?: any;
  pattern?: string;
  onInput?: any;
}

const Textbox: FC<InputProps> = ({
  className,
  disabled,
  value,
  touched,
  name,
  placeholder,
  error,
  label,
  pattern,
  onInput,

  ...rest
}) => {
  return (
    <>
      <div
        className={`${
          error && touched
            ? 'border-red-500'
            : 'border-[rgba(222, 226, 230, 0.6)]'
        } border rounded-[10px] px-4 pt-1 }`}
        // className={`${
        //   error && touched
        //     ? 'border-red-500'
        //     : 'border-[rgba(222, 226, 230, 0.6)]'
        // } w-full border rounded-[10px] outline-0 px-4 pt-1  flex items-start flex-col ${className} relative`}
      >
        {label && (
          <label
            htmlFor={name}
            className={`${
              error && touched ? 'text-red-500' : 'text-[#ADB5BD]'
            } text-start`}
          >
            {label}
          </label>
        )}
        <Field
          className="outline-0 h-full w-full placeholder:!text-sm placeholder:!text-black p-0 border-0 focus:outline-none focus:border-none focus:shadow-none focus:ring-0"
          disabled={disabled}
          value={value}
          name={name}
          pattern={pattern}
          placeholder={placeholder}
          error={error}
          onInput={onInput}
          as="textarea"
          // component
          {...rest}
        />
      </div>
      {name && (
        <ErrorMessage
          render={(msg) => (
            <div className="text-start text-sm font-light text-red-500">
              {msg}
            </div>
          )}
          name={name as string}
        />
      )}
    </>
  );
};

export default Textbox;
