import { FC, InputHTMLAttributes, useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import { TbEyeOff, TbEye } from 'react-icons/tb';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  type?: string;
  disabled?: boolean;
  value?: any;
  touched?: boolean;
  name?: string;
  placeholder?: string;
  error?: any;
  label?: string;
  inputProps?: any;
  pattern?: string;
  onInput?: any;
  rows?: number;
  modalUseCse?:string
}

const Input: FC<InputProps> = ({
  className,
  type,
  disabled,
  value,
  touched,
  name,
  placeholder,
  error,
  label,
  pattern,
  onInput,
  modalUseCse,

  ...rest
}) => {
  const [togglePassword, setTogglePassword] = useState('password');

  return (
    <div>
      <div
        className={`${
          error && touched
            ? 'border-red-500'
            : 'border-[rgba(222, 226, 230, 0.6)]'
        } w-full border rounded-[10px] outline-0 px-4 pt-1 pb-2 flex items-start flex-col ${className} relative`}
      >
        {label && (
          <label
            htmlFor={name}
            className={`${
              error && touched ? 'text-red-500' : 'text-[#ADB5BD]'
            } text-start`}
          >
            {label}
          </label>
        )}
        <Field
          type={type === 'password' ? togglePassword : type}
          className={`outline-0 h-full w-full p-0 border-0 focus:outline-none focus:border-none focus:shadow-none focus:ring-0 ${(modalUseCse == 'signup' || modalUseCse == 'signin') && 'bg-[#E8EAEE]'}`}
          disabled={disabled}
          value={value}
          name={name}
          pattern={pattern}
          placeholder={placeholder}
          error={error}
          onInput={onInput}
          autocomplete="off"
          {...rest}
        />
        {type !== 'password' ? null : (
          <button
            type="button"
            onClick={() =>
              setTogglePassword(togglePassword === 'text' ? 'password' : 'text')
            }
            className="inline-flex absolute top-[40%] right-4"
          >
            {togglePassword === 'password' ? (
              <TbEyeOff className="#6C757D text-base" />
            ) : (
              <TbEye className="#6C757D text-base" />
            )}
          </button>
        )}
      </div>
      {name && (
        <ErrorMessage
          render={(msg) => (
            <div className="text-start text-sm mt-3 font-light text-red-500">
              {msg}
            </div>
          )}
          name={name as string}
        />
      )}
    </div>
  );
};

export default Input;
