import { FC, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import googlePlayIcon from 'assets/icon/playstore.svg';
import appStoreIcon from 'assets/icon/apple.svg';
import smallArrow from 'assets/icon/small-arrow.svg';
import FacebookIcon from 'assets/icon/FacebookIcon.svg';
import TwitterIcon from 'assets/icon/TwitterIcon.svg';
import InstagramIcon from 'assets/icon/InstagramIcon.svg';
import LinkedinIcon from 'assets/icon/LinkedinIcon.svg';
import Newlogo from 'assets/img/newlogos/1.png';
import { openInNewTab } from 'utils/openInNewTab';
import { useToast } from 'components/common/Toast/ToastProvider';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import Message1 from 'assets/img/Message1.svg';
import Call1 from 'assets/img/call1.svg';

const Footer: FC = () => {
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const validateEmail = (email : string) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const url = `https://transcorphotels.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
    const [email, setEmail] = useState('');

    const toast:any = useToast();

    const handleSubmit= (e: any, subscribe: any, status: any, )  =>{
        e.preventDefault();
        if(!validateEmail(email)) return toast.error("Invalid email.")

        subscribe({
            EMAIL: email
        })

        status === "error" && toast.error("Error occurred, please try again.")

        status === "success" && toast.success('You have successfully subscribed to our newsletter.')

        status == null && toast.info('Email already exist or an error occurred. Please try again.')
    }

    const LogoandContact = () => (
        <>
            <div className="w-[100px] md:w-full mb-2">
                <Link to="/" className="inline-flex">
                    <img src={Newlogo} className="w-100 md:w-[200px]" />
                </Link>
            </div>
            <div>
                <div className='flex items-center gap-2 my-2'>
                <img
                        src={Message1}
                        className="Social_media_icons w-4 h-4 cursor-pointer"
                    />
                <p className="text-neutral">auracs@transcorphotelsplc.com</p>
                </div>
                <div className='flex items-center gap-2'>
                <img
                        src={Call1}
                        className="Social_media_icons w-4 h-4 cursor-pointer"
                    />
                <p className="text-neutral">0 201 343 4499</p>
                </div>
                <div className="flex space-x-2 my-4">
                    <img
                        src={FacebookIcon}
                        onClick={() => openInNewTab('https://web.facebook.com/aurabytranscorp/')}
                        className="Social_media_icons w-6 h-6 cursor-pointer"
                    />
                    <img
                        src={TwitterIcon}
                        onClick={() => openInNewTab('https://twitter.com/aurabytranscorp')}
                        className="Social_media_icons w-6 h-6 cursor-pointer"
                    />
                    <img
                        src={InstagramIcon}
                        onClick={() => openInNewTab('https://www.instagram.com/aurabytranscorp/')}
                        className="Social_media_icons w-6 h-6 cursor-pointer"
                    />
                    <img
                        src={LinkedinIcon}
                        onClick={() => openInNewTab('https://www.linkedin.com/company/aura-by-transcorp-hotels/mycompany/')}
                        className="Social_media_icons w-6 h-6 cursor-pointer"
                    />
                </div>
            </div>
        </>
    );

    return (
        <footer className="p-5 sm:p-6 lg:pb-12 fit">
            <div className="px-6 md:px-14 2xl:mt-24 mt-0 3xl:mt-[200px] 4xl:mt-[400px]">
                <div className="md:pt-12 py-8">
                    <div className="mb-6 md:mb-0 md:hidden">
                        <LogoandContact />
                    </div>
                    <div className="sm:grid md:flex sm:grid-cols-2 md:justify-between md:gap-4 gap-8">
                        <div className="mb-6 md:mb-0 hidden md:block mr-8">
                            <LogoandContact />
                        </div>
                        <div>
                            <ul className="text-neutral font-medium dark:text-gray-400 space-y-4">
                                <li>
                                    <Link to="/onboarding" className="hover:underline">
                                        Become a host
                                    </Link>
                                </li>
                                <li>
                                    <Link to={isAuthenticated ? '/onboarding' : '/' } className="hover:underline">
                                        List your property
                                    </Link>
                                </li>
                                <li>
                                    <div className="dropdown">
                                        <button className="dropbtn text-sm font-medium hover:text-auraPrimary text-gray-4 items-center sm:flex whitespace-nowrap">Partners &nbsp;
                                        <i className="fa fa-caret-down"></i>
                                        </button>
                                    <div className="dropdown-content">
                                        <a href="#">NBA</a>
                                    </div>
                                    </div>
                                </li>
                           
                            </ul>
                        </div>
                        <div>
                            <ul className="text-neutral font-medium dark:text-gray-400">
                                <li className="mb-4">
                                    <Link to="/about_us" className="hover:underline ">
                                        What We Do
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className="hover:underline">
                                        Press
                                    </Link>
                                </li>
                            </ul>
                        </div>
                      
                        <div>
                        <ul className="text-neutral font-medium dark:text-gray-400 space-y-4">
                        <li>
                                    <Link className="hover:underline" to="/contact_us">
                                        Contact Us
                                    </Link>
                                </li>
                         
                                <li>
                                    <Link className="hover:underline" to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link className="hover:underline" to="/terms-of-service">
                                        Terms of Service
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/faqs" className="hover:underline">
                                        Help Center
                                    </Link>
                                </li>

                        </ul>
                        </div>
                        <div className='mt-4 md:mt-0'>
                            <h2 className="mb-3 text-xs md:text-sm  font-semibold text-gray-900 uppercase dark:text-white">
                                Join our community
                            </h2>
                            <MailchimpSubscribe
                                url={url}
                                render={({ subscribe, status, }: any) => (
                                <div>
                                    <form action="" onSubmit={(e) =>{
                                        e.preventDefault();
                                        handleSubmit(e, subscribe, status)
                                    }}>
                                        <div className="flex rounded-full shadow-sm border px-1.5 py-1">
                                            <input
                                                type="text"
                                                name=""
                                                id=""
                                                className="w-full border-0 focus:ring-0 no-show-input"
                                                placeholder="Enter your email"
                                                onChange={e=>setEmail(e.target.value)}
                                            />
                                            <button  className='Join_our_community_News_Letter' type='submit'>
                                                <img src={smallArrow} alt="" srcSet="" />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                )}
                            />
                            
                        </div>
                    </div>
                </div>

                <div className="sm:flex sm:items-center sm:justify-between md:mt-16 mt-8">
                    <span className="text-sm text-stone-700 sm:text-center dark:text-gray-400">
                        Copyright © {new Date().getFullYear()} AURA BY TRANSCORP LLC. All rights reserved
                    </span>
                    <div className="flex mt-4 space-x-3 sm:justify-center sm:mt-0">
                        <img
                            src={appStoreIcon}
                            alt=""
                            srcSet=""
                            className="download_app text-gray-500 hover:text-gray-900 dark:hover:text-white cursor-pointer"
                            onClick={() => openInNewTab('https://apps.apple.com/us/app/aura-transcorp/id1547429419')}
                        />
                        <img
                            src={googlePlayIcon}
                            alt=""
                            srcSet=""
                            className="download_app text-gray-500 hover:text-gray-900 dark:hover:text-white cursor-pointer"
                            onClick={() =>
                                openInNewTab('https://play.google.com/store/apps/details?id=com.aura.transcorphotels.app')
                            }
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
