import {
    Dispatch,
    FC,
    JSXElementConstructor,
    Key,
    ReactElement,
    ReactFragment,
    ReactPortal,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoLogoGoogle } from 'react-icons/io';
import { CgFacebook } from 'react-icons/cg';
import { TiSocialLinkedin } from 'react-icons/ti';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useToast } from 'components/common/Toast/ToastProvider';
import { googleProvider, facebookProvider } from 'utils/config';
import { Modal, Button, Input, Select } from 'components/common';
import { Dots } from 'components/common/Loader';
import { ClearError, signupUser, socialMediaAuth, GoogleSignup, FacebookSignup } from 'redux/authSlice/actions';
import firebase from 'firebase/compat';
import dataService from 'services/appData';
import Newlogo from 'assets/img/newlogos/1.png';
import { Option } from 'components/common/Select';
import successIcon from 'assets/icon/reserve-modal.svg';
import signupsidebarImg from 'assets/img/signupsidebarImg.svg';
import googleIcon from 'assets/img/googleIcon.svg';
import facebookIcon from 'assets/img/facebookIcon.svg';
import linkedInIcon from 'assets/img/linkedInIcon.svg';
import emailIcon from 'assets/img/emailIcon.svg';
import leftLine from 'assets/img/leftLine.svg';
import rightLine from 'assets/img/rightLine.svg';
import { RootState } from 'redux/rootReducer';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import 'react-phone-input-2/lib/style.css';
import './signup.css';
import { values } from 'lodash';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { linkedinClientId } from 'services/firebase-config';


interface SignupProps {
    openModal?: boolean;
    setOpenModal?: Dispatch<SetStateAction<boolean>>;
}

const Signup: FC<SignupProps> = ({ openModal = true, setOpenModal }) => {
    const { isLoading, error, message } = useSelector((state: RootState) => state.user);
    const [showForm, setShowForm] = useState(true);
    const [isSubmitClicked, setisSubmitClicked] = useState(false);
    const [success, setSuccess] = useState(false);
    // const [openModal, setOpenModal] = useState(true);
    const toast = useToast();
    const navigate = useNavigate();
    const [phonevalue, setPhoneValue] = useState('');
    const [contry_code, setcontry_code] = useState('');
    const [socialMethod, setSocialMethod] = useState('');
    const [ageError, setageError] = useState(false);
    const [signUpFirstSlide, setSignUpFirstSlide] =  useState(true)

    const [genderArr, setGenderArr] = useState<
        {
            genderID: number;
            genderCode: string;
            genderType: string;
        }[]
    >([]);
    const [selectedGender, setSelectedGender] = useState<Record<string, any>>();
    const [countryArr, setCountryArr] = useState<
        {
            countryID: number;
            countryName: string;
        }[]
    >([]);
    const [selectedCountry, setSelectedCountry] = useState<Record<string, any>>();
    const [stateArr, setStateArr] = useState<{ stateID: number; stateName: string }[]>([]);
    const [selectedState, setSelectedState] = useState<Record<string, any>>();
    const [phoneErr, setPhoneErr] = useState("");

    const dispatch: any = useDispatch();

    const fetchCountries = async () => {
        const response = await dataService.getCountries({
            pageNumber: 1,
            pageSize: 10,
        });
        setCountryArr(response.data);
    };

    const fetchStates = async (countryId: number) => {
        const res = await dataService.getStates({
            pageNumber: 1,
            pageSize: 10,
            countryId: countryId,
        });
        setStateArr(res.data);
    };

    const fetchGender = async () => {
        const res = await dataService.getGender({
            pageNumber: 1,
            pageSize: 10,
        });
        setGenderArr(res.data);
    };

    useEffect(() => {
        fetchCountries();
        fetchGender();
    }, []);

    useEffect(() => {
        console.log("isLoading", isLoading)
    }, [isLoading]);

    function setCharAt(str: string, index: number, chr: string) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }

    const validatePhone = (value: any, country: any) => {
        const { dialCode } = country;
        // console.log("dialCode", dialCode, value)
        setcontry_code(dialCode)
        let checker = value[dialCode.length];
        if (checker === '0') {
            let str = setCharAt(value, dialCode.length, '');
            return str;
        }

        return value;
    };

    const SignupSchema = Yup.object().shape({
        firstName: Yup.string().required('Please enter your First name'),
        lastName: Yup.string().required('Please enter your Last name'),
        emailAddress: Yup.string().email('Please enter a valid email').trim().required('Email is required'),
        password: Yup.string()
            .trim()
            .required('Password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\.\*])(?=.{10,})/,
                'Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
            ),
        over18: Yup.boolean().oneOf([true], "Please accept that you're over 18"),
        tandc: Yup.boolean().oneOf([true], 'Please accept the privacy policy'),
        // roomNumber: Yup.number().required('Room Number is required'),
    });

    const handleSocialAuth = async (provider: firebase.auth.AuthProvider, socialMethod: string) => {
        
        try {
            const res = await socialMediaAuth(provider);
            const user = res.multiFactor.user;

            // console.log("socialmethod", user)
            if(socialMethod === 'google') {
                await dispatch(
                    GoogleSignup({
                        id: user.uid,
                        firstName: user.displayName.split(" ")[1],
                        lastName: user.displayName.split(" ")[0],
                        emailAddress: user.email,
                        middleName: '',
                        phoneNumber: user.phoneNumber || "",
                        accessToken: user.accessToken,
                        setOpenModal,
                        navigate
                    }
                ));
                return;
            }

            if(socialMethod === 'facebook') {
                await dispatch(
                    FacebookSignup({
                        id: user.uid,
                        firstName: user.displayName.split(" ")[1],
                        lastName: user.displayName.split(" ")[0],
                        emailAddress: user.email,
                        middleName: '',
                        phoneNumber: user.phoneNumber || "",
                        accessToken: user.accessToken,
                        setOpenModal,
                        navigate
                    }
                ));
                return;
            }
 
            
        } catch (error:any) {
            console.log("social-error", error)
        }
        
    };

    const handleModalClose = () => {
        if (setOpenModal) {
            setOpenModal(false);
        } else {
            navigate('/');
        }
        dispatch(ClearError());
    };

    const { linkedInLogin } = useLinkedIn({
        clientId: linkedinClientId || '',
        redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        onSuccess: (code) => {
          console.log(code);
        },
        onError: (error) => {
          console.log(error);
        },
      });

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled && message?.toLowerCase().includes('success') && isSubmitClicked) {
            setSuccess(true);
            setShowForm(false);
        }
        console.log({ isCancelled, message, isSubmitClicked });
        return () => {
            isCancelled = true;
        };
    }, [message, isSubmitClicked]);

    return (
        <>
            {showForm ? (
                <Modal
                    openModal={openModal as boolean}
                    setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
                    className="bg-white w-full !max-w-2xl lg:!max-w-5xl Home-sign-up !rounded-none !min-h-full"
                    onOutsideClick={() => {}}
                    onModalClose={handleModalClose}
                >
                    <div className='lg:flex w-full lg:justify-between h-full'>
                    <div className='lg:w-[55%] w-[100%] px-4 sm:px-10'>
                    <div className='lg:w-[90%] w-[100%]'>
                        <p className={`lg:pt-28 pt-16 pb-6 ${signUpFirstSlide && 'mt-8'}`}>
                        <img src={Newlogo} className="w-[15%]" />
                        </p>
                    <h3 className="lg:text-4xl text-2xl font-semibold leading-10 text-left ">
                        Start your journey
                    </h3>
                    <p className="text-left font-normal text-sm text-gray-4 mt-2">
                        Choose your preferred signup method to continue
                    </p>
                {signUpFirstSlide ?
                    <>
                        <div className='mt-10'>
                            <div className='flex item-center py-2 px-4 cursor-pointer'
                                onClick={() => {
                                    handleSocialAuth(googleProvider, "google")
                                }}
                            >
                                <p className='w-[30%]'>
                                    <img src={googleIcon} alt=''/> 
                                </p>
                                <p>Sign up with Google </p>
                            </div>

                            <div className='flex item-center mt-4 py-2 px-4 cursor-pointer'
                                onClick={() => {
                                    handleSocialAuth(facebookProvider, "facebook")
                                }}
                            >
                                <p className='w-[30%]'>
                                    <img src={facebookIcon} alt=''/> 
                                </p>
                                <p>Sign up with Facebook </p>
                            </div>

                            <div className='flex item-center mt-4 py-2 px-4 cursor-pointer'
                                onClick={linkedInLogin}
                            >
                                <p className='w-[30%]'>
                                    <img src={linkedInIcon} alt=''/> 
                                </p>
                                <p>Sign up with LinkedIn </p>
                            </div>

                            <div className='flex item-center mt-4 border py-2 px-4 border-black rounded-md bg-[#E8EAEE] cursor-pointer'
                                onClick={()=>setSignUpFirstSlide(false)}
                            >
                                <p className='w-[30%]'>
                                    <img src={emailIcon} alt=''/> 
                                </p>
                                <p>Continue with Email </p>
                            </div>
                        </div>
                        <div className='flex !w-[90%] justify-evenly items-center mt-10 mb-10 lg:mb-0'>
                            <div>
                                <img src={leftLine} alt='sideLine' />
                            </div>
                            <div className="text-xs space-x-1 text-center col-span-2 ">
                                <span> Already have an account?</span>
                                <Link to="/login" className="text-auraPrimary hover:underline">
                                    LOGIN
                                </Link>
                            </div>
                            <div>
                            <img src={rightLine} alt='sideLine' />
                            </div>
                        </div>
                    </>
                    :
                    <>
                     <div className="flex w-full justify-evenly gap-2 mt-10">
                        <img src={googleIcon} alt='googleIcon' className='cursor-pointer'
                            onClick={() => {
                                handleSocialAuth(googleProvider, "google")
                            }}
                        />
                        <img src={facebookIcon} alt='facebookIcon' className='cursor-pointer'
                            onClick={() => {
                                handleSocialAuth(facebookProvider, "facebook")
                            }}
                        />
                        <img src={linkedInIcon} alt='linkedInIcon' className='cursor-pointer'
                            onClick={linkedInLogin}
                        />
                    </div>
                    
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            emailAddress: '',
                            phoneNumber: '',
                            genderCode: '',
                            countryId: '',
                            stateId: '',
                            age: 21,
                            password: '',
                            roomNumber: 0,
                            over18: false,
                            tandc: false,
                        }}
                        validationSchema={SignupSchema}
                        onSubmit={async (values, actions) => {
                            // console.log('submitting...', contry_code );
                            setisSubmitClicked(true);
                            const value = phonevalue.slice(contry_code.length, phonevalue.length)
                            if(value.toString().length < 10 ) {
                              setPhoneErr("Please provide a valid phone number")
                              setTimeout(()=>{
                                setPhoneErr("")
                              }, 4000)
                              return;
                            }
                            await dispatch(
                                signupUser({
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    emailAddress: values.emailAddress,
                                    middleName: ' ',
                                    phoneNumber: `+${phonevalue}`,
                                    genderId: '1',
                                    countryId: '1',
                                    stateId: '2',
                                    age: '21',
                                    password: values.password,
                                })
                            );
                            // setShowForm(false);
                            // setSuccess(true);
                        }}
                    >
                        {({ values, errors, touched, setFieldValue }) => (
                            <Form className="w-full grid grid-cols-2 gap-x-4 gap-y-2 mt-1" autoComplete='off' >
                                <div className="col-span-2">
                                    {error && (
                                        <div
                                            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full"
                                            role="alert"
                                        >
                                            {error && (error as unknown as string)}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Input
                                        className="w-full mt-4 !bg-[#E8EAEE] !rounded-md"
                                        name="firstName"
                                        label="First Name"
                                        modalUseCse={'signup'}
                                        error={errors.firstName}
                                        touched={touched.firstName}
                                        autoComplete='off'
                                    />
                                </div>
                                <div>
                                    <Input
                                        className="w-full mt-4 !bg-[#E8EAEE] !rounded-md"
                                        name="lastName"
                                        label="Last Name"
                                        error={errors.lastName}
                                        touched={touched.lastName}
                                        modalUseCse={'signup'}
                                        autoComplete='off'
                                    />
                                </div>
                                <div className="col-span-2">
                                    <Input
                                        className="w-full mt-4 !bg-[#E8EAEE] !rounded-md"
                                        name="emailAddress"
                                        label="Email Address"
                                        error={errors.emailAddress}
                                        touched={touched.emailAddress}
                                        modalUseCse={'signup'}
                                        autoComplete='off'
                                    />
                                </div>

                                <div>
                                    <Input
                                        className="w-full mt-6 !bg-[#E8EAEE] !rounded-md"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        error={errors.password}
                                        touched={touched.password}
                                        modalUseCse={'signup'}
                                        autoComplete="new-password"
                                    />
                             
                                </div>
                                <div>
                                    <PhoneInput
                                        country={'ng'}
                                        value={phonevalue}
                                        autoFormat={false}
                                        placeholder="+234"
                                        inputProps={{
                                            name: 'phoneNumber',
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        onChange={(value: any, country: any) => {
                                            setPhoneValue(validatePhone(value, country));
                                        }}
                                        inputStyle={{ height: '54px' }}
                                        containerStyle={{ height: '54px' }}
                                        containerClass="w-full mt-6 w-full border-0 rounded-[10px] h-13 !bg-[#E8EAEE]"
                                        inputClass="outline-0 h-full w-full p-0 border-0 focus:outline-none h-14 focus:border-none focus:shadow-none focus:ring-0 !bg-[#E8EAEE] !rounded-md"
                                    />
                                    {phoneErr && <div className="text-small text-red-500">
                                      {phoneErr}
                                    </div>}
                                </div>
                            
                                <div className="col-span-2">
                                    <div className=" flex items-center mt-2">
                                        <label>
                                            <Field type="checkbox" name="over18" />I am 18+
                                        </label>
                                    </div>
                                    <ErrorMessage
                                        render={(msg) => (
                                            <div className="text-start text-sm mt-3 font-light text-red-500">{msg}</div>
                                        )}
                                        name={'over18'}
                                    />
                                </div>

                                <div className="col-span-2">
                                    <div className=" flex items-center mt-2">
                                        <label>
                                            <Field type="checkbox" name="tandc" />
                                            You agree to our friendly &nbsp;
                                            <Link
                                                to="/privacy-policy"
                                                className="text-end text-auraPrimary text-sm font-normal underline"
                                            >
                                                Privacy policy
                                            </Link>
                                        </label>
                                    </div>
                                    <ErrorMessage
                                        render={(msg) => (
                                            <div className="text-start text-sm mt-3 font-light text-red-500">{msg}</div>
                                        )}
                                        name={'tandc'}
                                    />
                                </div>

                                <div className="w-full mt-6 col-span-2">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="w-full h-12 text-base !rounded-md"
                                    >
                                        {isLoading ? <Dots /> : <span>Sign Up</span>}
                                    </Button>
                                
                                <div className='flex !w-[90%] justify-evenly items-center mt-10 mb-10 lg:mb-0'>
                                    <div>
                                        <img src={leftLine} alt='sideLine' />
                                    </div>
                                    <div className="text-xs space-x-1 text-center col-span-2 ">
                                        <span> Already have an account?</span>

                                        <Link to="/login" className="text-auraPrimary hover:underline">
                                            LOGIN
                                        </Link>
                                    </div>
                                    <div>
                                    <img src={rightLine} alt='sideLine' />
                                     </div>
                                </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    </>
                }
                    </div>
                    </div>
                    <div className='w-[45%] hidden lg:block !h-full'>
                        <img src={signupsidebarImg} alt="signup img" className={`w-full ${signUpFirstSlide ? '!h-[750px]' : '!h-[850px]'} object-cover`}/>
                    </div>
                    </div>
                </Modal>
            ) : (
                <div>
                    {
                        success && (
                            <Modal
                                openModal={openModal as boolean}
                                setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
                                className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px]"
                                onOutsideClick={() => navigate('/login')}
                                onModalClose={() => navigate('/login')}
                            >
                                <div className="p-6 md:py-12 flex flex-col items-center justify-center space-y-6">
                                    <img src={successIcon} className="w-40 " alt="" />
                                    <h3 className="mb-5 text-3xl font-semibold">Successful!</h3>
                                    <p className="text-base text-neutral font-normal text-center">
                                        Sign Up successful, kindly check your email and phone for a code to confirm your account
                                    </p>
                                    <div className="pb-8 w-full flex">
                                        <Link
                                            to="/login"
                                            className="py-3 rounded-xl text-base text-center font-normal mt-8 mx-auto px-6 sm:w-full bg-auraPrimary text-white capitalize hover:text-white"
                                        >
                                            Log In
                                        </Link>
                                    </div>
                                </div>
                            </Modal>
                        )
                        // : (
                        //   <Modal
                        //     openModal={openModal as boolean}
                        //     setOpenModal={setOpenModal as Dispatch<SetStateAction<boolean>>}
                        //     className="pt-16 pb-12 bg-white px-4 sm:px-10 w-full max-w-[542px]"
                        //     onOutsideClick={() => navigate('/login')}
                        //     onModalClose={() => navigate('/login')}
                        //   >
                        //     <div className="p-6 md:py-12 flex flex-col items-center justify-center space-y-6">
                        //       <img src={failureIcon} className="w-40 " alt="" />
                        //       <h3 className="mb-5 text-3xl font-semibold">Error!</h3>
                        //       <p className="text-base text-neutral font-normal text-center">
                        //         {error && (error as unknown as string)}
                        //       </p>
                        //       <div className="pb-8 w-full flex">
                        //         <button
                        //           type="button"
                        //           onClick={() => setShowForm(true)}
                        //           className=" py-3 rounded-xl text-base text-center font-normal mt-8 mx-auto px-6 bg-auraPrimary text-white capitalize"
                        //         >
                        //           Try again
                        //         </button>
                        //       </div>
                        //     </div>
                        //   </Modal>
                        // )
                    }
                </div>
            )}
        </>
    );
};

export default Signup;
