import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setreloadCount } from 'redux/authSlice/actions';
import { saveSelectedBooking } from 'redux/reservation/actions';
import Modal from '.';
import lovedimage from '../../../assets/img/success.svg';

interface ApprovedModalProp {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  singleBookingData?:Record<string,any>;
}
export default function ApprovedModal(props: ApprovedModalProp) {
  const dispatch: any = useDispatch();
  return (
    <>
      <Modal
        children={
          <div className="relative w-full max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:max-w-xl">
            <div className="p-3 text-center md:p-10 xl:px-24">
              <div className="flex justify-center">
                <img
                  src={lovedimage}
                  className="text-center"
                  alt=""
                  srcSet=""
                />
              </div>
              <h2 className="text-3xl font-bold">Booking Approved!</h2>
              <p className="py-3 text-lg text-neutral">
                Your booking has been Approved
              </p>
              <div className="my-10 ">
                <Link
                  to="/confirm-pay"
                  onClick={() => {
                    dispatch(setreloadCount(5));
                    props.setOpenModal(false);
                    dispatch(saveSelectedBooking(props.singleBookingData));
                  }}
                  className="block w-full px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-auraPrimary hover:text-white my-btn"
                >
                  Make Payment
                </Link>
              </div>
            </div>
          </div>
        }
        openModal={props.openModal}
        setOpenModal={props.setOpenModal}
      />
    </>
  );
}
