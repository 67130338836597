import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import Map from 'assets/icon/mapicon.svg';
import calendarIcon from 'assets/icon/calendar.svg';
import useeer from 'assets/icon/useer.svg';
import { getSession, storage } from 'utils';
import dataService from 'services/appData';
import bookingService from 'services/bookingService';
import ReservationModal from 'components/common/Modal/ReservationModal';
import { useToast } from 'components/common/Toast/ToastProvider';
import { Rate as Rating } from 'antd';

export default function RightReservationSection({ formData, productDetails, quotes, locationStates}: any) {
    const { selectedProductDetail, currentProductType } = useSelector(
        (state: RootState) => state.product
    );
    const { createdQuote } = useSelector(
        (state: RootState) => state.reservation
    );
    console.log('createdQuote', createdQuote)

    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);

    // const { bookingRouteFrom, bookingDetailsById } = useSelector((state: RootState) => state.booking);
    const [modal, setModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const { detailsID } = useParams();

    const [searchParams] = useSearchParams();
    const detailID = searchParams.get('detailID');
    const paramGuest = searchParams.get('guest');
    const paramQty = searchParams.get('qty');

    const { filter }: any = useSelector((state: RootState) => state.filter);
    const [statefilter, setFilter] = useState({
        checkIn: '',
        checkOut: '',
        formatted_address: '',
        guest: filter?.guest || 1,
        latitude: filter.latitude,
        longitude: filter.longitude,
        nights: 0,
    });
    const [guest, setGuest] = useState<number>(statefilter?.guest);

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const isAuxservice = [31, 32].includes(productDetails?.productID);
    
    // useEffect(() => {
        // fetchproductDetails();
        // console.log("detailsID", detailsID)
    // }, [detailsID, filter?.guest]);

    const handleEdit = () => {
        navigate(`/${currentProductType.productName}/${productDetails?.productDetailsID}`, {
            state: {
                checkIn: productDetails?.checkInDate ? moment(productDetails?.checkInDate).format('DD-MM-YYYY') : filter.checkIn,
                checkOut: productDetails?.checkOutDate
                    ? moment(productDetails?.checkOutDate).format('DD-MM-YYYY')
                    : filter.checkOut,
                formatted_address: '',
                guest: productDetails?.totalGuests ? productDetails?.totalGuests : filter?.guest,
                latitude: filter.latitude,
                longitude: filter.longitude,
                nights: calculateTotalNights(productDetails?.bookingDetails || quotes?.serviceModelRequests || []),
            },
        });
    };

    const formatAmount = (data: any) => {
        if (data)
            return Number(data).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        return 0;
    };

    const calculateTotalNights = (param: any) => {
        if (param[0]) {
            return param[0]?.totalNight;
        }
    };

    const calculateDiscountAmount = (param: any) => {
        return (param || []).reduce((a: any, b: any) => a + Number(b?.discountAmount), 0);
    };

    const path = window.location.pathname;
    const ticketQuantity = paramQty ? paramQty : '1';
    const experienceGuest = paramGuest ? paramGuest : 1;
    const activityTotalAmount = ticketQuantity
        ? parseInt(productDetails?.activityCalendar?.amount) * parseInt(ticketQuantity)
        : 0;
    const experienceFee: number =
        selectedProductDetail?.experienceSevices?.length > 0 ? selectedProductDetail?.experienceSevices[0]?.regularPrice : 0;
    const experienceServiceFee: number = productDetails?.serviceFee ? productDetails?.serviceFee : 0;
    const experienceTotal: number = experienceFee + experienceServiceFee;

    const location = useLocation();
    const toast= useToast();

    const resentPhonevalidation = async () => {
        await dataService.resendPhoneValidation({
            phoneNumber : `${user?.phoneNumber}`
        })
        .then(()=>  navigate('/otp'))
        .catch((err: any )=> navigate('/otp') )
    }

    const handleReserveExperienceBooking = async () => {
        if(!isAuthenticated || !getSession(storage.getToken())){
            return navigate(`/login?redirect=${location.pathname}${location.search}`)
        }

        if(!user.isPhoneNumberConfirmed) {
            resentPhonevalidation();
            return;
        } 

        setisLoading(true);

        if (formData.firstName && formData.lastName && formData.email && formData.phone) {
            try {
                await bookingService.createExperienceBooking({
                    productDetailsID: detailsID,
                    userID: user.userID,
                    // bookingAmount: experienceTotal,
                    // vat: 0,
                    // discountAmount: 0,
                    // other_Charges: 0,
                    // insuranceAmount: 0,
                    // payableAmount: experienceTotal,
                    referralCode: '',
                    payInstrumentTypeID: 1,
                    paymentPlanID: 1,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    emailAddress: formData.email,
                    phoneNumber: formData.phone,
                    totalGuests: filter.guest,
                });
                setModal(true);
                setisLoading(false);
            }
            catch(err:any){
                toast?.error(err?.response?.data?.responseMessage);
                setisLoading(false);
            }
            
        }

        setisLoading(false);
    };

    return (
        <div className="bg-white checkout-box space-y-6 p-5 border rounded-xl">
            {modal && <ReservationModal modal={modal} setModal={setModal} />}

            <div className="flex flex-col items-start space-x-6">
                {/* <div className="grid grid-cols-2 gap-4"> */}
                <div className="w-full h-full relative">
                    <img
                        src={
                            productDetails?.productPicture || productDetails?.servicePicture
                                ? productDetails?.productPicture || productDetails?.servicePicture
                                : productDetails?.pictures?.length > 0
                                ? productDetails?.pictures[0]
                                : selectedProductDetail?.productPictures?.length > 0
                                ? selectedProductDetail?.productPictures[0]?.pictureURL
                                : ''
                        }
                        className="rounded-md w-full h-36 object-cover"
                        alt="product"
                    />
                     <h6 className=" text-base font-medium absolute bottom-2 left-2 text-white bg-gray-700 py-1 px-2">
                                {productDetails?.productDetails?.productName || productDetails?.productName
                                    ? productDetails?.productDetails?.productName || productDetails?.productName
                                    : productDetails?.activityCalendar?.title
                                    ? productDetails?.activityCalendar?.title
                                    : selectedProductDetail?.productDetails?.productName}
                            </h6>
                </div>
                <div className="w-full py-1">
                        <div className=" flex flex-col md:flex-row md:items-center md:gap-[4%] gap-2">
                        <span className='pt-4 md:pt-0'>{productDetails?.stateName || selectedProductDetail?.productDetails?.state}</span>
                        <img src={Map} alt="map" className="w-[25px] hidden md:block" />
                            
                             <Rating
                                defaultValue={+selectedProductDetail?.productDetails?.rating || 0}
                                allowHalf
                                className="text-[12px]"
                                disabled={true}
                            />
                            
                            <p className="flex items-center ml-1 font-semibold tx-sm">
                                <span className='text-[30px] pb-4 hidden md:block'>.</span>
                                <span className="md:ml-2 font-light text-black">
                                    {selectedProductDetail?.productDetails?.noOfReviews > 0
                                        ? `${selectedProductDetail?.productDetails?.noOfReviews} reviews`
                                        : 'No review'}
                                </span>
                            </p>

                        {!path.includes('activit') && productDetails?.parentCompany && (
                                <p className="text-black font-normal">
                                    <span className="pr-2">Hosted by</span>
                                    <span className="text-black font-normal">{productDetails?.parentCompany}</span>
                                </p>
                            )}
                    </div>
                </div>
            </div>
            {path.includes('experience') ? (
                <>
                    <div className="bg-gray-100 rounded-2xl p-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3 ">
                                {/* <div>
                                    <img src={useeer} alt="" />
                                </div> */}
                                <div>
                                    <p className="text-xs font-light text-neutral ">Guests</p>
                                    {path.includes('confirm-pay') ? (
                                        <p className="text-black text-base font-normal text-center pt-2">{experienceGuest}</p>
                                    ) : (
                                       <p className='mt-2'>{filter?.guest || guest}</p>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center space-x-3 ">
                                {/* <div>
                                <img src={calendarIcon} alt="" />
                              </div> */}
                                <div>
                                    <p className="text-xs font-light text-neutral ">Duration From</p>
                                    <p className="text-black text-base font-normal text-center pt-2">
                                        {selectedProductDetail?.experienceSevices?.length > 0
                                            ? moment(selectedProductDetail?.experienceSevices[0]?.durationFrom).format(
                                                  'DD-MM-YYYY'
                                              )
                                            : productDetails?.experienceSevices?.length > 0
                                            ? moment(productDetails?.experienceSevices[0]?.durationFrom).format('DD-MM-YYYY')
                                            : ''}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-3 ">
                                {/* <div>
                                <img src={calendarIcon} alt="" />
                              </div> */}
                                <div>
                                    <p className="text-xs font-light text-neutral ">Duration To</p>
                                    <p className="text-black text-base font-normal text-center pt-2">
                                        {selectedProductDetail?.experienceSevices?.length > 0
                                            ? moment(selectedProductDetail?.experienceSevices[0]?.durationTo).format('DD-MM-YYYY')
                                            : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex items-center space-x-4">
                        <button
                            type="button"
                            className="Wishlist_button flex items-center space-x-2.5 w-full px-4 py-3 font-semibold text-black border rounded-lg xl:w-40 border-neutral"
                            onClick={() => handleAddToWishlist(null, null, guest)}
                        >
                            <span>Wishlist</span>
                            <img src={loveOnly} alt="" className="ml-1 w-4 text-neutral" />
                        </button>
                        <button
                            className="Reserve_Button flex items-center justify-center w-full px-3 py-3 font-medium text-white hover:text-white capitalize rounded-lg bg-auraPrimary disabled:bg-orange-200 disabled:cursor-not-allowed "
                            onClick={handleReserveExperienceBooking}
                        >
                            {isLoading ? (
                                <Dots />
                            ) : (
                                <>
                                    <span>Reserve</span>
                                    <img src={bag} alt="" className="ml-3" />
                                </>
                            )}
                        </button>
                    </div> */}
                    <div className="space-y-4 pt-4">
                        <div className="w-full flex items-center justify-between">
                            <p className=" text-xl capitalize font-medium">Price details</p>
                            {path.includes('confirm-pay') && (
                                <button
                                    className="bg-auraPrimary text-white px-3 py-1.5 rounded flex"
                                    onClick={() => navigate(`/experience/${detailID}`)}
                                >
                                    Edit
                                </button>
                            )}
                        </div>

                        <div className="space-y-4 py-3  border-t">
                            <div className="flex items-center justify-between">
                                <p className="px-3 space-y-4 ">Experience Fee</p>
                                <p className="px-3 space-y-4  font-medium">
                                    ₦
                                    {createdQuote?.experienceResponse?.totalAmount?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </p>
                            </div>
                            {createdQuote?.experienceResponse?.discountAmount !== 0 && <div className="flex items-center text-sm text-black font-medium justify-between rounded-lg px-3">
                                            <p className="space-y-4 font-light text-neutral">Discount</p>
                                            <p className="text-end">
                                                - ₦{quotes?.experienceResponse?.discountAmount}
                                            </p>
                                        </div>}
                                {(createdQuote?.costBreakDowns || [])
                                .filter(
                                    (data: any) =>
                                        !(data.name || '').toLowerCase().includes('host') &&
                                        !(data.name || '').toLowerCase().includes('paystack')
                                )
                                .map((data: any, index: number) =>
                                    (data.name || '').toLowerCase().includes('total') ? (
                                        <div className="bg-gray-100 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                            <p className="capitalize">{data.name}</p>
                                            <p className="text-end">
                                                ₦
                                                {data.value?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-between mt-4" key={index}>
                                            <p className="px-3 space-y-4 font-light text-neutral">{data.name}</p>
                                            <p className="px-3 space-y-4  font-medium">
                                                ₦
                                                {data.value?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </div>
                                    )
                                )}
                            {/* 

                            <div className="flex items-center justify-between">
                                <p className="px-3 space-y-4 ">Service fee</p>
                                <p className="px-3 space-y-4  font-medium">
                                    ₦
                                    {experienceServiceFee?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </p>
                            </div>
                            <div className="bg-gray-100 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                <p className="capitalize">Total</p>
                                <p className="text-end">
                                    ₦{' '}
                                    {experienceTotal?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </p>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-3 pt-6">
                        <img src="../../img/icon/free.svg" alt="" />
                        <p className="text-sm text-neutral108  font-medium ">
                            Free cancellation until 12:00 PM{' '}
                            {selectedProductDetail?.experienceSevices?.length > 0
                                ? moment(selectedProductDetail?.experienceSevices[0]?.durationFrom).format(
                                        'DD-MM-YYYY'
                                    )
                                : productDetails?.experienceSevices?.length > 0
                                ? moment(productDetails?.experienceSevices[0]?.durationFrom).format('DD-MM-YYYY')
                                : ''}
                        </p>
                    </div>
                </>
            ) : path.includes('activit') ? (
                <>
                    <div className="bg-gray-100 rounded-2xl p-4 space-y-8">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3 ">
                                <div>
                                    <img src={useeer} alt="" />
                                </div>
                                <div>
                                    <p className="text-xs font-light text-neutral ">Quantity</p>
                                    <p className="text-black text-base font-normal text-center pt-2">{ticketQuantity}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-3 ">
                                <div>
                                    <img src={calendarIcon} alt="" />
                                </div>
                                <div>
                                    <p className="text-xs font-light text-neutral ">Date</p>
                                    <p className="text-black text-base font-normal text-center pt-2">
                                        {productDetails?.activityCalendar?.date
                                            ? moment(productDetails?.activityCalendar?.date).format('DD-MM-YYYY')
                                            : ''}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-3 ">
                                <div>
                                    <img src={calendarIcon} alt="" />
                                </div>
                                <div>
                                    <p className="text-xs font-light text-neutral ">Time</p>
                                    <p className="text-black text-base font-normal text-center pt-2">
                                        {productDetails?.activityCalendar?.time ? productDetails?.activityCalendar?.time : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-4 pt-4">
                        <div className="w-full flex items-center justify-between">
                            <p className=" text-2xl capitalize font-medium">Price details</p>
                            {path.includes('confirm-pay') && (
                                <button
                                    className="bg-auraPrimary text-white px-3 py-1.5 rounded flex"
                                    onClick={() => navigate(`/activities/${detailID}`)}
                                >
                                    Edit
                                </button>
                            )}
                        </div>

                        <div className="space-y-4 py-3  border-t">
                            <div className="flex items-center justify-between">
                                <p className="px-3 space-y-4 ">Amount</p>
                                <p className="px-3 space-y-4  font-medium">
                                    ₦
                                    {quotes?.bookingAmount
                                        ? parseInt(quotes?.bookingAmount ).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                          }) 
                                        : '0'}
                                </p>
                            </div>
                            
                            {(quotes?.costBreakDowns || [])
                                .filter(
                                    (data: any) =>
                                        !(data.name || '').toLowerCase().includes('host') &&
                                        !(data.name || '').toLowerCase().includes('paystack')
                                )
                                .map((data: any, index: number) =>
                                    (data.name || '').toLowerCase().includes('total') ? (
                                        <div className="bg-gray-100 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                            <p className="capitalize">{data.name}</p>
                                            <p className="text-end">
                                                ₦
                                                {data.value?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-between mt-4" key={index}>
                                            <p className="px-3 space-y-4 font-light text-neutral">{data.name}</p>
                                            <p className="px-3 space-y-4  font-medium">
                                                ₦
                                                {data.value?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </div>
                                    )
                                )}

                            {/* <div className="flex items-center justify-between">
                                <p className="px-3 space-y-4 ">Service fee</p>
                                <p className="px-3 space-y-4  font-medium">₦0.00</p>
                            </div> */}
                            {/* <div className="bg-gray-100 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                <p className="capitalize">Total</p>
                                <p className="text-end">
                                    ₦
                                    {activityTotalAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </p>
                            </div> */}
                        </div>
                    </div>

                    <div className="flex items-center justify-center gap-3 pt-6">
                        <img src="../../img/icon/free.svg" alt="" />
                        <p className="text-sm text-neutral108 capitalize font-medium ">
                            {productDetails?.guestDetails} cancellation until 12:00 PM{' '}
                            {moment(productDetails?.checkInDate).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </>
            ) : (
                <>
                    <div className="px-4 space-y-8">
                        <div className="flex flex-col justify-between gap-2">
                           {selectedProductDetail?.experienceSevices?.length === 0 && createdQuote?.serviceModelRequests?.map((item: any)=>(
                           <div className="flex items-center space-x-3">
                                    <p className="text-[18px] text-black font-semibold">{item.totalRoom} {item.totalRoom > 1 ? 'Room' : 'Rooms'}</p>
                                    <p className="text-black text-base font-normal text-center">
                                        Deluxe Double Room
                                    </p>
                            </div>
                             ))}
                            <div className="flex items-center space-x-3 mt-4">
                                    <p className="text-[18px] text-black font-semibold">Guests</p>
                                    <p className="text-black text-base font-normal text-center">
                                        {productDetails?.totalGuests ? productDetails?.totalGuests : filter?.guest}
                                    </p>
                            </div>
                            {
                                productDetails?.serviceID == 0 && productDetails?.activityCalenderId == 0 ? (
                                    <>
                                    <div className="flex items-center space-x-3 ">
                                        <p className="text-[18px] text-black font-semibold">Duration From</p>
                                        <p className="text-black text-base font-normal text-center">
                                            {selectedProductDetail?.experienceSevices?.length > 0
                                                ? moment(selectedProductDetail?.experienceSevices[0]?.durationFrom, 'DD-MM-YYYY').format(
                                                    'ddd, MMM DD'
                                                )
                                                : productDetails?.checkInDate
                                                ? moment(productDetails?.checkInDate, 'DD-MM-YYYY').format('ddd, MMM DD')
                                                : ''}
                                        </p>
                                </div>
                                <div className="flex items-center space-x-3 ">
                                        <p className="text-[18px] text-black font-semibold ">Duration To</p>
                                        <p className="text-black text-base font-normal text-center">
                                            {productDetails?.checkOutDate 
                                                ? moment(productDetails?.checkOutDate, 'DD-MM-YYYY').format('ddd, MMM DD')
                                                : ''}
                                        </p>
                                </div>
                                </>
                                )
                                : (
                                    <>
                                    <div className="flex items-center space-x-3 ">
                                            <p className="text-[18px] text-black font-semibold ">{isAuxservice ? "Date" : "Check-in Date"}</p>
                                            <p className="text-black text-base font-normal text-center">
                                                {productDetails?.checkInDate
                                                    ? moment(productDetails?.checkInDate, 'DD-MM-YYYY').format('ddd, MMM DD')
                                                    : moment(filter.checkIn, 'DD-MM-YYYY').format('ddd, MMM DD')}
                                            </p>
                                    </div>
                                    {!isAuxservice && <div className="flex items-center space-x-3 ">
                                            <p className="text-[18px] text-black font-semibold ">Check-out Date</p>
                                            <p className="text-black text-base font-normal text-center">
                                                {productDetails?.checkOutDate
                                                    ? moment(productDetails?.checkOutDate, 'DD-MM-YYYY').format('ddd, MMM DD')
                                                    : moment(filter.checkOut, 'DD-MM-YYYY').format('ddd, MMM DD')}
                                            </p>
                                    </div>}
                                    
                                    {!isAuxservice && <div className="flex items-center space-x-3 ">
                                            <p className="text-[18px] text-black font-semibold">
                                                {
                                                    path.includes('workspace')
                                                    ? 'Days'
                                                    : 'Nights'
                                                }
                                            </p>
                                            <p className="text-black text-base font-normal text-center">
                                                {calculateTotalNights(
                                                    productDetails?.bookingDetails || quotes?.serviceModelRequests || []
                                                )}
                                            </p>
                                    </div>}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="space-y-4 pt-4">
                        <div className="w-full flex items-center justify-between">
                            <p className=" text-2xl capitalize font-medium">
                                {
                                    locationStates?.bookingId ? 'Next Installment' : 'Price details'
                                }
                            </p>
                        </div>

                        <div className="space-y-4 py-3">
                            {locationStates?.bookingId ? '' : (
                                productDetails?.bookingDetails?.length > 0 || quotes?.serviceModelRequests?.length > 0 )
                            ? (productDetails?.bookingDetails || quotes?.serviceModelRequests || []).map(
                                (data: any, index: number) => (
                                    <div className="flex items-center justify-between" key={index}>
                                        <div className="px-3 space-y-4 text-sm font-light text-black">
                                            <div className="space-x-1">
                                                <span>₦</span>
                                                <span>{data.amountPerNight ? data.amountPerNight.toLocaleString() : 0}</span>
                                                {!isAuxservice && <>
                                                <span>x</span>
                                                <span>{data?.totalRoom}</span>
                                                <span>room{data?.totalRoom > 1 && 's'}</span>
                                                <span>x</span>
                                                <span>{data?.totalNight}</span>
                                                <span>
                                                    {
                                                        path.includes('workspace')
                                                        ? 'day'
                                                        : 'night'
                                                    }
                                                    {data?.totalNight > 1 && 's'}
                                                </span>
                                                </>}
                                            </div>
                                        </div>
                                        <div className="px-3 space-y-4 text-sm text-black text-end">
                                            <p>₦{formatAmount((data.amountPerNight || data?.bookingAmount) * (data?.totalRoom || 1) * (data?.totalNight || 1) )}</p>
                                        </div>
                                    </div>
                                )
                            )
                        : (
                            <div className="flex items-center justify-between mt-4">
                                <p className="px-3 space-y-4 font-light text-black">Booking Amount</p>
                                <p className="px-3 space-y-4  font-medium">
                                    ₦
                                    {productDetails?.bookingAmount ??
                                    formatAmount(
                                        productDetails?.bookingAmount || 0
                                    )}
                                </p>
                            </div>
                        )}

                            {
                                locationStates?.bookingId ? '' : (
                                    <div className="flex items-center justify-between mt-4">
                                        <p className="px-3 space-y-4 font-light text-black">Host Discount</p>
                                        <p className="px-3 space-y-4  font-medium">
                                            - ₦
                                            {productDetails?.discountAmount ??
                                            formatAmount(
                                                calculateDiscountAmount(
                                                    productDetails?.bookingDetails || quotes?.serviceModelRequests || []
                                                )
                                            )}
                                        </p>
                                    </div>
                                )
                            }

                            {(quotes?.costBreakDowns || [])
                                .filter(
                                    (data: any) =>
                                        !(data.name || '').toLowerCase().includes('host') &&
                                        !(data.name || '').toLowerCase().includes('paystack')
                                )
                                .map((data: any, index: number) =>
                                    (data.name || '').toLowerCase().includes('total') ? (
                                        <>
                                            {!path.includes('confirm-pay') && (
                                                <div className='flex justify-end py-2 mr-2'>
                                                    <button className="bg-auraPrimary text-white px-3 py-1.5 rounded flex" onClick={handleEdit}>
                                                        Edit
                                                    </button>
                                                </div>
                                            )}
                                        <div className="bg-gray-100 flex items-center text-sm text-black font-medium justify-between rounded-lg p-3">
                                            <p className="capitalize">{data.name}</p>
                                            <p className="text-end">
                                                ₦
                                                {data.value?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </div>
                                        </>
                                    ) : (
                                        <div className="flex items-center justify-between mt-4" key={index}>
                                            <p className="px-3 space-y-4 font-light text-black">{data.name}</p>
                                            <p className="px-3 space-y-4  font-medium">
                                                ₦
                                                {data.value?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>

                    <div className="flex items-center justify-center gap-3 pt-6">
                        <img src="../../img/icon/free.svg" alt="" />
                        <p className="text-sm text-neutral108 capitalize font-medium ">
                            {productDetails?.guestDetails} cancellation until 12:00 PM{' '}
                            {productDetails?.checkInDate
                                            ? moment(productDetails?.checkInDate).format('DD-MM-YYYY')
                                            : filter.checkIn}
                        </p>
                    </div>
                </>
            )}
        </div>
    );
}
