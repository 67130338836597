import { Dispatch, Action } from '@reduxjs/toolkit';
import { loadingStart, loadingStopped, reviewSuccess,createReviewSuccess } from './reducers';
import reviewService from 'services/reviewService';
import { CreateReviewData } from 'services/reviewService/types';

  export const createReview = (data: CreateReviewData) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(loadingStart());
      const res = await reviewService.createReview(data);
      dispatch(createReviewSuccess(res.data));
    } catch (err: any) {
      dispatch(loadingStopped(err.response.data.responseMessage));
    }
  };


