import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Footer, Header, PlacesCard } from 'components/common';
import { RootState } from 'redux/rootReducer';

export const Privacy = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user)

  useEffect(() => {
        window.scrollTo(0,0)
    }, []);
    
  return (
    <>
      <Header isAuthenticated={isAuthenticated} />

      <div className="max-w-4xl mr-auto ml-auto text-base pr-4 pl-4 pt-5 lg:py-12">
        <h2 className="mb-5 text-3xl font-medium title ">
          Privacy Policy
        </h2>
        <h5 className="mb-3">
          We at Aura treat your privacy with the highest importance. Our Data
          Privacy Policy detail the measures we take to preserving and safely
          guarding your information when you visit our website or generally
          communicate with our personnel. These Data Privacy Policy has been
          approved and provided by our team of legal advisors.
        </h5>
        <h4 className="pb-2">Table of Contents</h4>
        <ol className="text-auraPrimary mb-4 cursor-pointer hover:text-black">
          <a className="hover:text-black" href="#definitions">
            {' '}
            1. Definitions{' '}
          </a>
        </ol>
        <ol className="text-auraPrimary mb-4 cursor-pointer  hover:text-black">
          <a className="hover:text-black" href="#data-privacy-policy">
            {' '}
            2. Data Privacy Policy
          </a>
        </ol>
        <ol className="text-auraPrimary mb-4 cursor-pointer  hover:text-black ">
          <a className="hover:text-black" href="#cookie-policy">
            {' '}
            3. Cookie Policy
          </a>
        </ol>
        <ol className="text-auraPrimary mb-4 cursor-pointer  hover:text-black">
          <a className="hover:text-black" href="#subject-access">
            {' '}
            4. Subject Access Request Response Procedure
          </a>
        </ol>
        <ol className="text-auraPrimary mb-4 cursor-pointer  hover:text-black">
          <a className="hover:text-black" href="#contacting-us">
            {' '}
            5. Contacting Us
          </a>
        </ol>
        <ol className="text-auraPrimary mb-4 cursor-pointer  hover:text-black">
          <a className="hover:text-black" href="#liability-disclaimer">
            {' '}
            6. Liability Disclaimer
          </a>
        </ol>
        <ol className="text-auraPrimary mb-4 cursor-pointer  hover:text-black">
          <a className="hover:text-black" href="#guest-guide">
            {' '}
            7. Aura Guest Guide
          </a>
        </ol>
        <ol className="text-auraPrimary mb-4 cursor-pointer  hover:text-black">
          <a className="hover:text-black" href="#cancellation-policy">
            {' '}
            8. Cancellation Policy
          </a>
        </ol>
        <h5 id="definitions" className="text-lg pt-12 mb-5 title">
          Definitions:
        </h5>
        <p className="mb-2">
          <strong className="text-lg mr-2">• Affiliated Third Parties</strong>
          includes companies with which we have common ownership or management
          or other contractual strategic support or partnership relationships
          with, our advisers, consultants, bankers, vendors or sub-contractors.
        </p>
        <p className="mb-2">
          <strong className="text-lg mr-2">• Data</strong>is information, which
          is stored electronically, on a computer, or in certain paper-based
          filing systems.
        </p>
        <p className="mb-2">
          <strong className="text-lg mr-2">• Data Controller</strong>is a person
          responsible for determining the manner in which Personal Data would be
          processed.
        </p>
        <p className="mb-2">
          <strong className="text-lg mr-2">• NDPR</strong>means the Nigerian
          Data Protection Regulations
        </p>
        <p className="mb-2">
          <strong className="text-lg mr-2">• NITDA</strong>means the National
          Information Technology Development Agency
        </p>
        <p className="mb-2">
          <strong className="text-lg mr-2">• Personal Data</strong>is the
          information relating to an identified or identifiable natural person.
          These include a name, gender, a photo, an email address, bank details,
          medical information, computer internet protocol address and any other
          information specific to the physical, physiological, genetic, mental,
          economic, cultural or social identity of that natural person.
        </p>
        <p className="mb-2">
          <strong className="text-lg mr-2">• Processing</strong>is any activity
          that involves use of Personal Data. It includes obtaining, recording
          or holding the data, or carrying out any operation or set of
          operations on the data including organising, amending, recording,
          retrieving, using, disclosing, erasing or destroying it. Processing
          also includes transferring personal data to third parties.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Sensitive Personal Data</strong>
          includes information about a person’s racial origin, political
          opinions, religious or similar beliefs, trade union membership,
          physical or mental health or condition or sexual life.
        </p>
        <h5 id="data-privacy-policy" className="text-lg pt-12 mb-5 title">
          Data Privacy Policy
        </h5>
        <h6 className="text-base">Data Collection:</h6>
        <p className="mb-2">
          We may collect, use, store and transfer different kinds of Personal
          Data about you which we have grouped together as follows:
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Identity Data</strong>includes
          first name, last name, username or similar identifier, title, date of
          birth and gender.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Contact Data</strong>includes
          residential address, email address and telephone numbers.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Human Resource Data </strong>
          includes information on your employment history, professional and
          educational information submitted upon applying for employment with
          us.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Payment Data </strong>includes
          your payment information such as your card details (name on card, card
          number, billing address, expiration date, security code).
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Technical Data </strong>includes
          internet protocol (IP) address, domain name, your login data, browser
          type and version, time zone setting and location, browser plug-in
          types and versions, operating system and platform, and other
          technology on the devices you use to access this website.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Profile Data </strong>includes
          your username and password, purchases or orders made by you, your
          interests, preferences, feedback and survey responses.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">• Usage Data</strong>includes
          information about how you use our website and services.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">
            • Marketing and Communications
          </strong>
          Data includes your preferences in receiving marketing communications
          from us and our Affiliated Third Parties and your communication
          preferences.
        </p>
        <p className="mb-2">
          You provide this information through direct interaction when you visit
          our website, Aura Platform, sign up to our newsletters or
          publications, request marketing materials to be sent to you, create an
          account on our website, respond to surveys, complete our feedback or
          comment form, provide your business card to any of our staff, sign our
          visitor management form, complete other forms, apply for employment
          through our careers page, or contact us to request for any information
          or other correspondence by post, email, our website or otherwise.
        </p>
        <p className="mb-2">
          As you interact with our website, we may collect technical data about
          your equipment, browsing actions and patterns. We collect this data by
          using cookies, and other similar technologies.
        </p>
        <p className="mb-2">
          We do not intentionally collect any Sensitive Personal Data. We ask
          that you do not send to us nor disclose such Sensitive Personal Data
          save where required for a specific purpose.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">
            Your Personal Data and how it is Used
          </strong>
        </p>
        <p className="mb-2">
          We may collect, process and store your Personal Data to help us better
          connect with you. The following are the purposes for which we collect
          your Personal Data:
        </p>
        <p className="mb-2">
          a. To monitor, review, evaluate and improve your experience when you
          visit our website
        </p>
        <p className="mb-2">
          b. To analyse the traffic on our website, including determining the
          number of visitors to the website and analyse how they navigate the
          website.
        </p>
        <p className="mb-2">
          c. To invite you to complete a survey or provide feedback to us on
          specific matters.
        </p>
        <p className="mb-2">
          d. At any time, you request information from us via a form or other
          electronic transmission we may use your Personal Data to fulfil that
          request and keep a record of such request and how it was handled, for
          quality assurance and service improvement purposes.
        </p>
        <p className="mb-2">
          e. To keep you updated on our activities, programmes and events where
          your explicit consent has been given.
        </p>
        <p className="mb-2">
          f. To notify you of changes to our websites or relevant processes.
        </p>
        <p className="mb-2">
          g. We may also use your information or allow Affiliated Third Parties
          such as our affiliate companies or partners use of this Personal Data,
          to offer you information about unrelated products or services you may
          be interested in. We or such Affiliated Third Parties can only
          communicate with you if you have expressly consented to such
          communication and data use.
        </p>
        <p className="mb-2">
          h. We may share your personal data with Affiliated Third Parties such
          as service providers who we have engaged to assist with providing
          certain services on our behalf, for which they require your personal
          data.
        </p>
        <p className="mb-2">
          i. Where we have any contracts with you which create a commitment, we
          may require contact or use of your information to perform the
          contract.
        </p>
        <p className="mb-2">
          j. To process or manage your appointments with any of our staff.
        </p>
        <p className="mb-2">
          k. To fulfil legal/ regulatory obligations or to report any criminal
          or unethical activity.
        </p>
        <p className="mb-2">
          l. To store either on our central computer system or a third-party
          Computer’s central computer system for archiving and back up purposes.
        </p>
        <p className="mb-2">
          Be aware that we do not reveal identifiable information about you to
          our advertisers, though we may at times share statistical visitor
          information with our advertisers.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">Change of Purpose</strong>
        </p>
        <p className="mb-2">
          We will only use your Personal Data for the aforementioned purposes,
          unless we reasonably consider that we need to use it for another
          reason and that reason is compatible with the original purpose. If you
          wish to get an explanation as to how the processing for the new
          purpose is compatible with the original purpose, please contact us.
        </p>
        <p className="mb-2">
          If we need to use your Personal Data for an unrelated purpose, we will
          notify you and request for your express consent.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">Your Personal Data Rights</strong>
        </p>
        <p className="mb-2">
          Data Protection Laws provides you with certain rights in relation to
          the information that we collect about you
        </p>
        <p className="mb-2">
          We may collect, process and store your Personal Data to help us better
          connect with you. The following are the purposes for which we collect
          your Personal Data:
        </p>
        <p className="mb-2">
          a. The right to withdraw consent previously given to us or our
          Affiliated Third Parties. In order to make use of your personal data,
          we would have obtained your consent. For consent to be valid, it must
          be given voluntarily. In line with regulatory requirements, consent
          cannot be implied, and we ensure that you have the opportunity to read
          our data protection privacy policy before you provide your consent.
          Consent in respect of Sensitive Personal Data must be explicit and
          will be given by you in writing to us. The consent of minors (under
          the age of 18) will always be protected and obtained from the minor’s
          representatives in accordance with applicable regulatory requirements.
        </p>
        <p className="mb-2">
          You can ask us or Affiliated Third Parties to stop sending you
          marketing messages at any time by logging onto the website and
          checking or unchecking relevant boxes to adjust your marketing
          preferences or by following the opt-out links on any marketing message
          sent to you.
        </p>
        <p className="mb-2">
          b. The right to request that we delete your Personal Data that is in
          our possession, subject however to retention required for legal
          purposes and the time required technically to delete such information.
        </p>
        <p className="mb-2">
          c. The right to request for access to your Personal Data or object to
          us processing the same. Where personal data is held electronically in
          a structured form, such you have a right to receive that data in a
          common electronic format.
        </p>
        <p className="mb-2">
          d.The right to update your Personal Data that is kept with us. You may
          do this at anytime your personal data changes and you wish to update
          us
        </p>
        <p className="mb-2">
          e. The right to receive your Personal Data and have it transferred to
          another Data Controller, as applicable.
        </p>
        <p className="mb-2">f. The right to lodge a complaint.</p>
        <p className="mb-2">
          <strong className="titleHead mr-2">
            Persons who have access to your Personal Data
          </strong>
        </p>
        <p className="mb-2">
          In addition to our staff who have a business need to know, the
          following trusted third parties may have access to your information:
        </p>
        <p className="mb-2">
          a. Any customer relationship management tool we use to help manage our
          contact database and send out email communications to you.
        </p>
        <p className="mb-2">
          b. Our Affiliate Third Parties who require your information for the
          same purposes described in this Policy and who have adopted similar
          privacy policy standards further to contractual obligations to us
          under a third party data processing agreement we have entered with
          them.
        </p>
        <p>
          c. Professional service providers such as IT service providers and
          website hosts.
        </p>
        <p className="mb-2">
          We will transfer your Personal Data to only those Affiliated Third
          Parties who we are sure can offer the required level of protection to
          your privacy and information and who are also contractually obligated
          to us to do so. We do not and will not at any point in time sell your
          Personal Data. We require all Affiliated Third Parties to respect the
          security of your personal data and to treat it in accordance with the
          law. We do not allow our professional service providers to use your
          Personal Data for their own purposes and only permit them to process
          your Personal Data for specified purposes and in accordance with our
          instructions.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">
            Security &amp; Confidentiality
          </strong>
        </p>
        <p className="mb-2">
          Information submitted by you is stored on secure servers we have which
          are encrypted and access is restricted to only authorized persons in
          charge of maintaining the servers. We have put in place physical,
          electronic and procedural processes that safeguard and protect your
          information against unauthorized access, modification or erasure.
          However, we cannot guarantee 100% security as no security programme is
          completely fool proof. In the unlikely event that we experience any
          breach to your personal data, such breach shall be handled in
          accordance with our Personal Data Breach Management Procedures. All
          such breaches shall be notified to the NITDA within 72 hours of
          occurrence and where deemed necessary, based on the severity and
          potential risks, we shall notify you of such occurrence, steps taken
          and remedies employed to prevent a reoccurrence.
        </p>
        <p className="mb-2">
          Our staff also have an obligation to maintain the confidentiality of
          any Personal Data held by us. As you know, transmission of data on the
          internet is never guaranteed regarding safety. It is impossible to
          completely guarantee your safety with electronic data and
          transmission. You are therefore at your own risk if you elect to
          transmit any data electronically.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">
            Transfer of Personal Data outside Nigeria
          </strong>
        </p>
        <p className="mb-2">
          The Personal Data we collect may be transferred to and processed in
          another country other than your country of residence for the purposes
          stated above. The data protection laws in those countries may be
          different from, and less stringent than the laws applicable in your
          country of residence.
        </p>
        <p className="mb-2">
          By accepting this Policy or by providing your Personal Data to us, you
          expressly consent to such transfer and Processing. We will however
          take all reasonable steps to ensure that your data is treated securely
          and transfer of your Personal Data will only be done in accordance
          with the requirements of applicable laws and to parties who have put
          in place adequate controls to secure and protect your Personal Data.
        </p>
        <p className="mb-2">
          <strong className="titleHead mr-2">Retention of Personal Data</strong>
        </p>
        <p className="mb-2">
          We retain your Personal Data for no longer than reasonably necessary
          for the purposes set out in this Policy and in accordance with legal,
          regulatory, tax, accounting or reporting requirements.
        </p>
        <p className="mb-2">
          We may retain your Personal Data for a longer period in the event of a
          complaint or if we reasonably believe there is a prospect of
          litigation in respect to our relationship with you.
        </p>
        <p className="mb-2">
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the Personal Data, the
          potential risk of harm from unauthorised use or disclosure of your
          Personal Data, the purposes for which we process your Personal Data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.
        </p>
        <p className="mb-2">
          Where your Personal Data is contained within a document, the retention
          period applicable to such type of document in our document retention
          policy shall apply.
        </p>
        <h5 id="cookie-policy" className="text-lg pt-12 mb-5 title">
          Cookie Policy
        </h5>
        <p className="mb-2">
          Our advertisers and organization may have the occasion to collect
          information in regard to your computer for our services. The
          information is gained in a statistical manner for our use or
          advertisers on our site.
        </p>
        <p className="mb-2">
          Data gathered will not identify you personally. It is strictly
          aggregate statistical data about our visitors and how they used our
          resources on the site. No identifying Personal Data will be shared at
          any time via cookies.
        </p>
        <p className="mb-2">
          Close to the above, data gathering can be about general online use
          through a cookie file. When used, cookies are automatically placed in
          your hard drive where information transferred to your computer can be
          found. These cookies are designed to help us correct and improve our
          site’s services for you.
        </p>
        <p className="mb-2">
          You may elect to decline all cookies via your computer or set up
          alerts to prompt you when websites set or access cookies. Every
          computer has the ability to decline file downloads like cookies. Your
          browser has an option to enable the declining of cookies. If you do
          decline cookie downloads you may be limited to certain areas of our
          site, as there are parts of our site that require cookies.
        </p>
        <p className="mb-2">
          Any of our advertisers may also have a use for cookies. We are not
          responsible, nor do we have control of the cookies downloaded from
          advertisements. They are downloaded only if you click on the
          advertisement.
        </p>
        <h5 id="subject-access" className="text-lg pt-12 mb-5 title">
          Subject Access Request Response Procedure
        </h5>
        <ol className="mb-4 list-decimal list-inside">
          <li>
            Where you wish to exercise any of your data privacy rights you shall
            make a formal request by completing and submitting the{' '}
            <strong style={{ color: 'rgb(0, 0, 0)' }}>
              Subject Access Request Form (SAR Form)
            </strong>
          </li>
          <li>
            We shall contact you within 5 working days of the receipt of the SAR
            Form to confirm receipt of the subject access request and may
            request additional information to verify and confirm the identity of
            the individual making the request.
          </li>
          <li>
            On receiving any request from you, we shall record the request and
            carry out verification of the identity of the individual making the
            request using the details provided in the SAR Form and a valid means
            of identification such as international passport, driver’s license,
            national identification card or any other acceptable means of
            identification.
          </li>
          <li>
            Where the request is from a third party (such as relative or your
            representative), we will verify their authority to act for you and
            may contact you to confirm their identity and request your consent
            to disclose the information.
          </li>
          <li>
            When your identity is verified, we shall coordinate the gathering of
            all information collected with respect to you in a concise,
            transparent, intelligible and easily accessible form, using clear
            and plain language with a view to responding to the specific
            request. The information may be provided in writing, or by other
            means, including, where appropriate, by electronic means or orally
            provided that your identity is proven by other means. We may also
            contact you to ask you for further information in relation to your
            request to speed up our response.
          </li>
          <li>
            Where the information requested relates directly or indirectly to
            another person, we will seek the consent of that person before
            processing the request. However, where disclosure would adversely
            affect the rights and freedoms of others and we are unable to
            disclose the information, we will inform you promptly, with reasons
            for that decision.
          </li>
        </ol>
        <p className="mb-2">
          <strong className="titleHead mr-2">Fees and Timeframe</strong>
        </p>
        <ol className="mb-4 list-decimal list-inside">
          <li>
            We shall ensure that we provide the information required by you
            within a period of one month from the receipt of the request.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated. However, where we are
            unable to act on your request, we shall inform you promptly at least
            within one month of receipt of the request of the reasons for not
            acting and give you the option of lodging a complaint with the
            NITDA, in line with the NDPR.{' '}
          </li>
          <li>
            Where the request relates to any perceived violation of your rights,
            we shall take appropriate steps to remedy such violations, once
            confirmed. Remedies shall include but not be limited to the
            investigation and reporting to appropriate authorities, recovering
            the personal data, correcting it and/ or enhancing controls around
            it. You shall be appropriately informed of the remedies employed.{' '}
          </li>
          <li>
            Any information provided to you by us shall be provided free of
            charge. However, where requests are manifestly unfounded or
            excessive in particular because of their repetitive or cumbersome
            nature, we may:
          </li>
          <ul style={{ listStyleType: 'circle' }}>
            <li>
              charge a reasonable fee considering the administrative costs of
              providing the information or communication, taking the action
              required or making a decision to refuse to act on the request; or
            </li>
            <li>
              write a letter to you stating refusal to act on the request and
              copying the National Information Technology Development Agency
              (NITDA).
            </li>
          </ul>
        </ol>
        <p className="mb-2">
          <strong className="titleHead mr-2">
            Exceptions to Data Subjects Access Rights
          </strong>
        </p>
        <p>
          To the extent permitted by applicable laws, we may refuse to act on
          your request, if at least one of the following applies:{' '}
        </p>
        <ul className="mb-4 list-inside list-disc">
          <li>
            in compliance with a legal obligation to which we are subject;
          </li>
          <li>
            protecting your vital interests or of another natural person; and
          </li>
          <li>
            for public interest or in exercise of official public mandate vested
            in us.
          </li>
        </ul>
        <h5 id="contacting-us" className="text-lg pt-12 mb-5 title">
          Contacting Us
        </h5>
        <p className="mb-2">
          We welcome any queries, requests you may have regarding our Data
          Protection Privacy Policies, or our privacy practices. Please feel
          free to contact us at
          <span className="text-auraPrimary px-1">
            {' '}
            aurapolicy@transcorphotels.com
          </span>
          or by completing the{' '}
          <span className="text-auraPrimary">
            Subject Access Request Form (SAR Form)
          </span>
        </p>

        <div id="liability-disclaimer">
          <div className=" w-72">
            <h2 className=" text-dark text-lg pt-12 mb-1 title">
              LIABILITY DISCLAIMER
            </h2>
          </div>
          <p className="text-dark text-md mt-6 font-medium mb-1 ">
            Please read this section carefully.
          </p>
          <p className="text-gray-600 font-light text-base mb-3">
            To the full extent permitted by law, Aura and its officers,
            directors, employees, agents, licensors, affiliates, and successors
            in interest (
            <b className="text-dark text-md mt-6 font-medium mb-1">
              “Aura entities”
            </b>
            ):
          </p>
          <ul className="list-decimal list-inside">
            <li className="text-gray-600 font-light text-base mb-3">
              Make no promises, warranties, representations as to the
              information, software, products and services published on or
              provided via the Aura platform, including its completeness,
              accuracy, availability, timeliness, propriety, security,
              reservation availability, pricing errors or reliability. Aura
              entities do not guarantee the accuracy of, and disclaims all
              liabilities for any errors or other inaccuracies relating to the
              information and description of the accommodation, experiences,
              restaurants and any other product or service (“the Services”)
              displayed on the Aura platform (including without limitation, the
              pricing, availability, photographs, list of accommodation,
              experience, restaurant, general service description, reviews and
              ratings, etc). Notwithstanding, Aura reserves the right to correct
              any availability or pricing errors on the services and/or pending
              reservations made under an incorrect price.
            </li>
            <li className="text-gray-600 font-light text-base mb-3">
              Provide Aura on an “As Is” and “As Available” basis without
              warranty of any kind, and any risk of using Aura is assumed by
              you.
            </li>
            <li className="text-gray-600 font-light text-base mb-3">
              Disclaim all warranties, conditions, or other terms of any kind
              that the services, its servers or any data, including emails sent
              by Aura are free of viruses or other harmful components. Aura
              entities hereby disclaim all warranties, express or implied,
              including as to accuracy, merchantability, fitness for a
              particular purpose, and non-infringement, and all warranties
              arising from course of dealing, usage, or trade practice.
            </li>
            <li className="text-gray-600 font-light text-base mb-3">
              Disclaim any liability or responsibility for acts, omissions, or
              conduct of you or any party in connection with Aura. The
              third-party suppliers providing Services on Aura are independent
              contractors and are not agents or employees of Aura and its
              affiliates. Aura entities are NOT liable for the acts, omissions,
              negligence, representations, warranties, breaches, or negligence
              of any such suppliers or for any personal injuries, death,
              property damage, or other damages or expenses resulting therefrom.
            </li>
            <li className="text-gray-600 font-light text-base mb-3">
              Has no liability and will make no refund in the event of any
              delay, strike, overbooking, force majeure, or any other causes,
              beyond its control and has no responsibility for any additional
              expense, actions, omissions, delay or acts of any Government or
              authority. Notwithstanding, Aura reserves the right to make
              refunds in certain cases, in line with the Aura Refund Policy.
            </li>
            <li className="text-gray-600 font-light text-base mb-3">
              Shall not be liable for any direct, indirect, consequential,
              incidental, special, punitive, or other losses, including lost
              profits, revenues, data, goodwill, etc., arising from access to,
              display of, or use of the Services, inability to access, display
              or use the Services (including but not limited to your reliance on
              reviews and ratings posted on the Aura platform, any viruses,
              bugs, information software, linked sites, products and services
              obtained through the Services; personal injury or property damage
              resulting from your use of the Services and any and all personal
              information, financial information stored, errors or omissions in
              any content or for any losses or damage arising from use or
              reliance on any content on the Aura platform.
            </li>
            <li className="text-gray-600 font-medium text-base mb-3">
              You further acknowledge that by displaying information or property
              listings in particular destinations, we do not represent or
              warrant that travel to such destinations is without risk and we
              are not liable for damages with respect to travel to such
              destinations.
            </li>
          </ul>
        </div>

        <div id="guest-guide">
          <div className="border-auraPrimary w-72">
            <h2 className=" text-dark text-lg pt-12 mb-1 title">
              AURA GUEST GUIDE
            </h2>
          </div>

          <h2 className=" text-dark text-md mt-6 font-medium mb-1">
            Safe Guest MODE
          </h2>
          <h2 className=" text-dark text-md mt-4 font-medium mb-1">
            Your Safety is our Priority
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            We want you to feel at home whenever you visit an Aura
            accommodation, which is why we have put checks in place to ensure
            that all our hosts adhere to safety protocols.
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            Our guests are also expected to adhere to our safety standards
            because safety is a shared responsibility.
          </p>
          <h2 className="text-dark text-md mt-4 font-medium mb-1">Mask up</h2>
          <p className="text-gray-600 font-light text-base mb-3">
            Both guests and hosts must wear masks during physical interactions.
          </p>
          <h2 className="text-dark text-md mt-4 font-medium mb-1">
            Observe Social Distancing
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            We expect guests and hosts to maintain a social distance of six feet
            (two meters) or more, during physical interactions as recommended by
            the World Health Organization.
          </p>
          <h2 className="text-dark text-md mt-4 font-medium mb-1">
            Deep Cleaning
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            Hosts are expected to perform deep cleaning of the Listed
            Accommodation and its surroundings prior to every check-in to ensure
            accommodations are clean and safe for guests. Guests are likewise
            expected to observe sanitary hygiene practices.
          </p>
          <h2 className="text-dark text-md mt-4 font-medium mb-1">
            Entertain Responsibly
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            Guests are not expected to entertain crowds within the accommodation
            unless pre- agreed with the host. When pre-agreed with the host, all
            entertainment should be limited to the numbers recommended by the
            World Health Organization or your Local Health Authority. Please
            note that where there is a conflict between the recommended numbers
            prescribed by the World Health Organization and the Local Health
            Authority numbers, the lower number supersedes.
          </p>
          <h2 className="text-dark text-md mt-4 font-medium mb-1">
            Host Confirmation
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            As part of the hosting process, we require hosts to submit their
            Identification cards. Please confirm that the host physically in
            front of you is the same person on the ID card before accessing the
            property.
          </p>
          <h2 className="text-dark text-md mt-4 font-medium mb-1">
            Be Security conscious
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            You may be on vacation, but criminals are not. While Aura carries
            out verifications to keep you safe, guests also need to observe
            additional security diligence to safeguard yourself. Some of these
            diligences are as follows:
          </p>

          <div className="">
            <ul className="list-disc list-inside">
              <li className="text-black  font-light text-base mb-3">
                Get to the property on time. Avoid late hours and observe dark
                areas around the property.
              </li>
              <li className="text-black  font-light text-base mb-3">
                Do not collect keys from questionable sources. Only the host or
                pre-confirmed designate should give you the keys.
              </li>
              <li className="text-black  font-light text-base mb-3">
                Keep your doors locked at all times, especially your bedroom
                doors.
              </li>
              <li className="text-black  font-light text-base mb-3">
                Do not expose large sums of money in the vehicle while going to
                any property, as it could make you a target.
              </li>
              <li className="text-black  font-light text-base mb-3">
                Have someone you periodically check in with and an emergency
                contact you can call if you sense danger.
              </li>
            </ul>
          </div>
        </div>

        <div id="cancellation-policy">
          <div className="border-auraPrimary w-72">
            <h2 className=" text-dark text-lg pt-12 mb-1 title">
            CANCELLATION NOTICE
            </h2>
          </div>

          <p className="text-gray-600 font-light text-base mb-3 mt-6">
            Guests are entitled to free cancellation and a 
            full refund less transfer charges incurred by Aura, 
            if the booking is cancelled not more than 48 hours after booking, 
            and at least 14 days before check-in (time shown on the confirmation email).
          </p>

          <h2 className=" text-dark text-md mt-6 font-medium mb-1">
            CANCELLATION POLICY
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            Guests are entitled to free cancellation and a full refund less 
            transfer charges incurred by Aura, if the booking is 
           <span className='font-medium'> cancelled not more than 48 hours after booking, 
            and at least 14 days before check-in(time shown on the confirmation email).</span>
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            Where guests cancel their booking within 48 hours after booking and 
            at least 14 full days prior to Listing’s local check-in time 
            (shown in the confirmation email), they are entitled to a full refund of 
            the nightly rate, less transfer charges incurred by Aura.
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            Where guests cancel their booking <span className='font-medium'> less than 14 days </span>
            but at <span className='font-medium'> least 7 days before </span> the Listing's local check in 
            time (shown in the confirmation email), they are entitled 
            to a 50% refund of the nightly rate, less transfer charges.
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            Where guests cancel their booking <span className='font-medium'>less than 5 days</span> before 
            the Listing's local check in time (shown in the confirmation email),
            they are entitled to a 25% refund of the nightly rate, less transfer charges.
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            However, where the bookings were made through a Booking Agent or 
            with a referral code, all refunds with respect to the above, shall be less transfer 
            charges AND any commission paid to the Booking Agent or owner of the Referral Code.
          </p>

          <h2 className="text-dark text-md mt-4 font-medium mb-1">
            Check- In time may vary (as provided by property).</h2>
          <p className="text-gray-600 font-light text-base mb-3">
            Where guests choose to check-out early after check-in, the unspent 
            nights are not refunded unless the check-out is due to proven issues with the 
            property in which case we will relocate the person to a suitable property.
          </p>
          <h2 className="text-dark text-md mt-8 font-medium mb-1">
            CANCELLATION POLICY FOR HOSTS
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            <span className='font-medium'>Apartment Hosts:</span> shall be paid a <span className='font-medium'>50%</span> 
            advance deposit at least <span className='font-medium'>7 days</span> prior to the check-in of the guest 
            and the remainder within 24 hours after the Guest’s scheduled check-in time.
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            Hosts are entitled to free cancellation, if the booking is 
            cancelled prior to the advance deposit made by Aura.
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            Where the Host cancels a booking within 48 hours of the advance 
            payment but at <span className='font-medium'>least 5 days</span> before the Guest’s check-in time 
            (shown in the confirmation email), the Host shall 
            make payment of 100% of the advance deposit 
            paid by Aura with no cancellation charge.
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            <span className='font-medium'>Hotel Hosts</span> shall be paid by 2pm on the day 
            of the Guest’s scheduled check-in time
          </p>

          <p className="text-gray-600 font-light text-base mb-3">
            Hosts are entitled to free cancellation up to 5 days ahead of the bookings. 
            However, two cancellations within the month would earn the hosts certain sanctions.
          </p>

          <h2 className="text-dark text-md mt-8 font-medium mb-1">
          SANCTIONS
          </h2>
          <p className="text-gray-600 font-light text-base mb-3">
            Where the Host cancels confirmed bookings after receipt of advance 
            payments up to two times within the same month, 
            such host will be issued a warning and blacklisted on the Aura platform.
          </p>
          <p className="text-gray-600 font-light text-base mb-3">
            Where the Host cancels confirmed bookings after receipt of 
            advance payments up to three times within the same quarter 
            (Except for operational reasons), such host will be blacklisted on the Aura platform, 
            and shall be removed and banned from the platform, at Aura’s discretion.
          </p>
          <p className="text-gray-600 font-light text-base mb-3">
            Hosts shall however not be held accountable for cancellations done on the day of the 
            booking as a result of misleading/incorrect information from guests.
          </p>
          
        </div>
      </div>

      {/* <NewsLetterSection /> */}
      <Footer />
    </>
  );
};
